<section id="account-header">
  <div class="container boxed-md">

    <div>
    <app-breadcrumbs [breadcrumbItems]="breadCrumbItems" class="account-breadcrumbs text-light d-none d-sm-none d-md-block"></app-breadcrumbs>
  </div>

    <div class="d-flex align-items-center justify-content-between pb-4 mb-5 mb-md-2">
      <div class="col-md-8 d-none d-sm-none d-md-block">
      <div class="d-flex align-items-center">
        <div class="position-relative flex-shrink-0"><img class="user-account-avatar rounded-circle border border-white" [src]="usuario.photo?.images['200'] ? usuario.photo.images['200'] : generateavatar" width="100" alt="{{usuario.fullname}}">
          <button class="btn btn-icon btn-light btn-xs rounded-circle shadow-sm position-absolute end-0 bottom-0" type="button" placement="top" ngbTooltip="Change image" (click)="EditProfliePicModal(editProfilePic)"><i class="fi-pencil fs-xs"></i></button>
        </div>
        <div class="ps-3 ps-sm-4">
          <p class="fs-base fw-bold mb-0 text-light">¡Bienvenido!</p>
          <h2 class="h4 mb-2 fw-bold text-light">{{usuario.fullname}}</h2>
        </div>
      </div>
    </div>

    <!--Version movil-->


    <div class="col-md-8 d-block d-sm-block d-md-none ps-5">
      <div class="d-flex align-items-center" style="margin-top: 30px; margin-bottom: -20px; margin-left: -40px;">
        <div class="position-relative flex-shrink-0"><img class="user-account-avatar rounded-circle border border-white" [src]="usuario.photo?.images['200'] ? usuario.photo.images['200'] : generateavatar" width="80" alt="{{usuario.fullname}}">
          <button class="btn btn-icon btn-light btn-xs rounded-circle shadow-sm position-absolute end-0 bottom-0" type="button" placement="top" ngbTooltip="Change image" (click)="EditProfliePicModal(editProfilePic)"><i class="fi-pencil fs-xs"></i></button>
        </div>
        <div class="ps-3 ps-sm-4">
          <p class="fs-base fw-bold mb-0 text-light">¡Bienvenido!</p>
          <h2 class="h4 mb-2 fw-bold text-light">{{usuario.fullname}}</h2>
        </div>
      </div>
    </div>



    <div class="col-md-2 d-none d-sm-none d-md-block" style="width: 40px;">
      <a *ngIf="mostrarBotonReservas" class="btn btn-outline-primary" style="margin-top: 75px;  border-radius: 15px;" [routerLink]="['../../']"><i class="fi-arrow-left me-2"></i> Salir</a>
    </div>
    </div>
  </div>
  <div class="rounded-border-separator">
  </div>
</section>

<ng-template #editProfilePic let-offcanvas>
<div class="offcanvas-header" *transloco="let t; scope: 'account'">
  <button type="button" class="btn" aria-label="Close" (click)="offcanvas.dismiss('Cross click')" style="position: absolute;right: 0;"><i class="fi-x"></i></button>
</div>
<div class="offcanvas-body" *transloco="let t; scope: 'account'">
  <div class="row">
    <div class="col-4">
      <ng-container *ngIf="croppedImage === ''">
        <img *ngIf="usuario.photo?.images['200']" [src]="usuario.photo?.images['200']" class="rounded-circle" width="200" height="200">
      </ng-container>
      <ng-container *ngIf="croppedImage !== ''">
        <img *ngIf="croppedImage" [src]="croppedImage" class="rounded-circle" width="200" height="200" style="width: 200px;
        height: 200px;
        object-fit: cover;">
      </ng-container>
      <image-cropper style="opacity: 0;position: absolute; z-index: -1;"
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="4 / 3"
    format="png"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded(croppedImage)"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"
  ></image-cropper>
    </div>
    <div class="col-8">
      <h5 *transloco="let t; scope">{{t('account.foto_de_perfil')}}</h5>
      <p *transloco="let t; scope">{{t('account.tu_foto_de_perfil_es')}}</p>
      <label for="avatar-upload" class="btn rounded-pill btn-outline-primary">
        {{t('account.subir_foto')}}
    </label>
    <input type="file" id="avatar-upload" class="form-control" (change)="fileChangeEvent($event)" value="Editar" />

  </div>
  </div>
</div>
<div class="offcanvas-footer d-flex justify-content-end">

  <button *ngIf="croppedImage" type="button" class="btn btn-sm btn-outline-primary" (click)="saveAvatar()">Guardar Imagen del Perfil</button>
</div>
</ng-template>

<!-- Footer-->
<footer class="footer pt-lg-5 pt-4 bg-dark text-light">
  <div class="container mb-4 py-4 pb-lg-5">
    <div class="row gy-4 ps-2 ps-md-0">
      <div class="col-lg-3 col-md-6 col-sm-4">
        <div class="mb-4 pb-sm-3"><a class="d-inline-block" routerLink=""><img src="assets/img/masmesawhite.svg" width="135" alt="Logo"></a></div>
        <ul class="nav nav-light flex-column">
          <li class="nav-item mb-2"><a class="nav-link p-0 fw-normal text-light text-nowrap" href="mailto:info@masmesa.com"><i class="fi-mail mt-n1 me-1 align-middle text-primary"></i>info&#64;masmesa.com</a></li>
          <li class="nav-item mb-2"><a class="nav-link p-0 fw-normal text-light text-nowrap" href="tel:+5555550120"><i class="fi-device-mobile mt-n1 me-1 align-middle text-primary"></i>(55) 5555-0120</a></li>
        </ul>
      </div>
      <!-- Links-->
      <div class="col-6 col-lg-2 col-md-3">
        <h3 class="fs-base text-light" *transloco="let t;">{{t('enlaces_rapidos')}}</h3>
        <ul class="list-unstyled fs-sm" *transloco="let t;">
          <li><a class="nav-link-light" href="javascript:void(0);">{{t('ciudades_tendencia')}}</a></li>
          <li><a class="nav-link-light" href="javascript:void(0);">{{t('top_10_restaurantes')}}</a></li>
          <li><a class="nav-link-light" href="javascript:void(0);">{{t('nuevos_en_masmesa')}}</a></li>
          <li><a class="nav-link-light" href="javascript:void(0);">{{t('proximos_eventos')}}</a></li>
        </ul>
      </div>
      <!-- Links-->
      <div class="col-6 col-lg-2 col-md-3">
        <h3 class="fs-base text-light" *transloco="let t;">{{t('perfil')}}</h3>
        <ul class="list-unstyled fs-sm" *transloco="let t;" >
          <li><a class="nav-link-light" href="javascript:void(0);">{{t('mi_cuenta')}}</a></li>
          <li><a class="nav-link-light" href="javascript:void(0);">{{t('favoritos')}}</a></li>
          <li><a class="nav-link-light" href="javascript:void(0);">{{t('reservaciones')}}</a></li>
          <li><a class="nav-link-light" href="javascript:void(0);">{{t('platillos')}}</a></li>
        </ul>
      </div>
      <!-- Subscription form-->
      <div class="col-lg-4 offset-lg-1">
        <h3 class="h4 text-light" *transloco="let t;">{{t('suscribete_a_nuestro_boletin')}}</h3>
        <p class="fs-sm mb-4 opacity-60" *transloco="let t;">{{t('inauguraciones, noticias, interés, sabores y más...')}}</p>
        <form class="form-group form-group-light rounded-pill  me-2 me-md-0" style="max-width: 500px;" [formGroup]="newsletterForm" (ngSubmit)="SignupNewsletter()">
          <div class="input-group input-group-sm"><span class="input-group-text text-muted"><i class="fi-mail"></i></span>
            <input class="form-control" type="email" placeholder="Your email" formControlName="email">
          </div>
          <button class="btn btn-primary btn-sm rounded-pill" type="submit" *transloco="let t;">{{t('suscribirse')}}</button>
        </form>
      </div>
    </div>
  </div>
  <div class="py-4">
    <div class="container d-flex flex-column flex-lg-row align-items-center justify-content-between py-2">
      <!-- Copyright-->

      <ul class="nav ">
        <li class="nav-item"><a class="nav-link" routerLink="/terminos">Términos y Condiciones</a></li>
        <li class="nav-item"><a class="nav-link" routerLink="/privacidad">Política de Privacidad</a></li>
      </ul>

      <div class="text-center text-lg-start mb-3 mb-lg-0">
        <p class="fs-sm mb-0">© 2023, Masmesa Tech SA de CV. All rights reserved.</p>
       </div>
      <div class="d-flex flex-lg-row flex-column align-items-center order-lg-2 order-1 ms-lg-4 mb-lg-0 mb-4">

        <div class="d-flex align-items-center">
          <!-- Language switcher-->
          <div class="dropdown" ngbDropdown>
            <a class="nav-link nav-link-light dropdown-toggle fs-sm align-items-center p-0 fw-normal" href="javascript:void(0);" id="langSwitcher" data-bs-toggle="dropdown" role="button" aria-expanded="false" ngbDropdownToggle>
              <i class="fi-globe mt-n1 me-2 align-middle"></i>{{ActiveLang}}
            </a>
            <ul class="dropdown-menu dropdown-menu-dark my-1" aria-labelledby="langSwitcher" ngbDropdownMenu>
              <li><a class="dropdown-item text-nowrap py-1" href="javascript:void(0);" (click)="ChangeLanguage('en')"><img class="me-2" src="assets/img/flags/en.png" width="20" alt="English">English</a></li>
              <li><a class="dropdown-item text-nowrap py-1" href="javascript:void(0);" (click)="ChangeLanguage('es')"><img class="me-2" src="assets/img/flags/es.png" width="20" alt="Español">Español</a></li>
            </ul>
          </div>
          <!-- Socials-->
          <div class="ms-4 ps-lg-2 text-nowrap"><a class="btn btn-icon btn-translucent-light btn-xs rounded-circle ms-2" href="javascript:void(0);"><i class="fi-facebook"></i></a><a class="btn btn-icon btn-translucent-light btn-xs rounded-circle ms-2" href="javascript:void(0);"><i class="fi-twitter"></i></a><a class="btn btn-icon btn-translucent-light btn-xs rounded-circle ms-2" href="javascript:void(0);"><i class="fi-telegram"></i></a><a class="btn btn-icon btn-translucent-light btn-xs rounded-circle ms-2" href="javascript:void(0);"><i class="fi-messenger"></i></a></div>
        </div>
      </div>
    </div>
  </div>
</footer>

<!-- Back to top button-->
<a class="btn-scroll-top show" href="javascript:void(0);" [ngxScrollTo]="'#top'" data-scroll><span class="btn-scroll-top-tooltip text-muted fs-sm me-2">Top</span><i class="btn-scroll-top-icon fi-chevron-up">   </i></a>




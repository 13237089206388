import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, of, map } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class MediauploadService {
  private AvatarAPI = environment.ApiUsuarios;
  private MediaAPI = environment.ApiUpload;
  constructor(private http : HttpClient) { }

  UploadUserAvatar(avatar : string){
    const url = `${this.AvatarAPI}/user/photo`;

   let UserAvatar = {
      "photo" : avatar
    }

   return this.http.post<any>(url, UserAvatar).pipe(
      map(resp => resp.success),
      catchError(err => {
        return of(false);
      })
      )
 }

 RemoveImage(images : any[]){

  const url = `${this.MediaAPI}/publications/file`;
  const options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    body: {
      images
    },
  };
  return this.http.delete<any>(url, options)
}


UploadChatAttachment(data : FormData){
  const url = `${this.MediaAPI}/chat/temp/chatfilemessage`;
  return this.http.post<any>(url, data);

}


}

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {

  transform(time : string): string | null {
   let formatTime = moment(time, 'HH:mm:ss').format('hh:mm A');

  return formatTime;

  }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) },
  { path: 'publications', loadChildren: () => import('./publications/publications.module').then(m => m.PublicationsModule) },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)  },
  { path : 'error', loadChildren: () => import('./errorpages/errorpages.module').then(m => m.ErrorpagesModule)},
  { path: 'external', loadChildren: () => import('./external/external.module').then(m => m.ExternalModule)},
  { path: '**', redirectTo: '/error/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled'

})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div class="d-none d-md-flex justify-content-center border-bottom">
  <div class="collapse d-md-block" id="account-nav">
    <ul class="nav nav-pills flex-column flex-md-row">
      <li class="nav-item mb-md-0 me-md-2 pe-md-1" *transloco="let t; scope: 'account'"><a class="nav-link" routerLink="/account/configuration" routerLinkActive="active"><i class="fi-user mt-n1 me-2 fs-base"></i>{{t('account.mi_perfil')}}</a></li>
      <li class="nav-item mb-md-0 me-md-2 pe-md-1" *transloco="let t; scope: 'account'"><a class="nav-link" routerLink="/account/reservations"  routerLinkActive="active" aria-current="page"><i class="fi-calendar mt-n1 me-2 fs-base"></i>{{t('account.reservaciones')}}</a></li>
      <li class="nav-item mb-md-0 me-md-2 pe-md-1" *transloco="let t; scope: 'account'"><a class="nav-link" routerLink="/account/favorites" routerLinkActive="active"><i class="fi-heart mt-n1 me-2 fs-base"></i>{{t('account.favoritos')}}</a></li>
      <li class="nav-item mb-md-0 me-md-2 pe-md-1" *transloco="let t; scope: 'account'"><a class="nav-link" routerLink="/account/reviews" routerLinkActive="active"><i class="fi-star mt-n1 me-2 fs-base"></i>{{t('account.reseñas')}}</a></li>
      <li class="nav-item mb-md-0 me-md-2 pe-md-1" *transloco="let t; scope: 'account'"><a class="nav-link" routerLink="/account/notifications" routerLinkActive="active"><i class="fi-bell mt-n1 me-2 fs-base"></i>{{t('account.notificaciones')}}</a></li>
      <li class="nav-item d-md-none">
    </ul>
  </div>
</div>

<div class="account-menu-mobile fixed-bottom d-block d-md-none">
  <div class="bg-menu-mobile">
 <div class="container">
  <ul class="nav justify-content-between ms-2">
    <li class="nav-item mb-md-0 text-center" *transloco="let t; scope: 'account'"><a class="nav-link" routerLink="/account/configuration" routerLinkActive="active"><i class="fi-user mt-n1 fs-base"></i><br><span>{{t('account.mi_cuenta')}}</span></a></li>
    <li class="nav-item mb-md-0 me-md-2 pe-md-1 text-center" *transloco="let t; scope: 'account'"><a class="nav-link" routerLink="/account/reservations"  routerLinkActive="active" aria-current="page"><i class="fi-calendar mt-n1 fs-base"></i><br><span>{{t('account.reservas')}}</span></a></li>
    <li class="nav-item mb-md-0 me-md-2 pe-md-1 text-center" *transloco="let t; scope: 'account'"><a class="nav-link" routerLink="/account/favorites" routerLinkActive="active"><i class="fi-heart mt-n1 fs-base"></i><br><span>{{t('account.favoritos')}}</span></a></li>
    <li class="nav-item mb-md-0 me-md-2 pe-md-1 text-center" *transloco="let t; scope: 'account'"><a class="nav-link" routerLink="/account/reviews" routerLinkActive="active"><i class="fi-star mt-n1 fs-base"></i><br><span>{{t('account.reseñas')}}</span></a></li>
    <li class="nav-item mb-md-0 me-md-2 pe-md-1 text-center" *transloco="let t; scope: 'account'"><a class="nav-link" routerLink="/account/notifications" routerLinkActive="active"><i class="fi-bell mt-n1 fs-base"></i><br><span>{{t('account.alertas')}}</span></a></li>
    <li class="nav-item d-md-none">
  </ul>
 </div>
  </div>
</div>

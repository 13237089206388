import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BrandService  {
private apiURL = environment.ApiPublicaciones;
private brand: any = [];
private brand$ = new BehaviorSubject<any>(this.brand);
public brandObs = this.brand$.asObservable();
private user : any;
  constructor(private http : HttpClient, private _auth : AuthService) {

    this._auth.isLoggedin$.subscribe((resp : any) => {
      if(resp){
        this.user = this._auth.usuario;
        if(this.user.brand_id != null){
        this.setBrand(this.user);
        }
      }

    });

  }



 public getBrand() {
    return this.brand;
  }

  getPublicationsByBrand(brandID: number, QueryParams?: any) {
    return this.http.get(`${this.apiURL}/admins/brand/${brandID}/publications`, {params: QueryParams});
  }

  setBrand(user : any) {

 this.http.get(`${this.apiURL}/admins/brand/${user.brand_id}`).subscribe(
      (resp : any) => {
        this.brand = resp.data;
        this.brand$.next(this.brand);
      });

  }

  getBrandbyID(brandID: number) {
    return this.http.get(`${this.apiURL}/admins/brand/${brandID}`)
  }

  UpdateBrand(brandID: number, data: any) {
    return this.http.patch(`${this.apiURL}/admins/brand/${brandID}`, data);
  }






}

import { AfterViewInit, Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { clear } from 'console';
import { SimplebarAngularComponent } from 'simplebar-angular';
import { ChatSocket } from 'src/app/core/services/chatsocket.service';
import { MediauploadService } from 'src/app/core/services/mediaupload.service';

@Component({
  selector: 'app-chat-basic',
  templateUrl: './basic.component.html'
})
export class BasicChatComponent implements OnInit, AfterViewInit{
@Input() reservation: any;
@Input() usuario: any;
chatMessages: any[] = [];
chatForm! : UntypedFormGroup;
is_loading : boolean = true;
timeoutLoading : any;
@ViewChild('ChatSimplebar') simplebar!: SimplebarAngularComponent;
@ViewChildren('msj') msj!: QueryList<any>;
constructor(private _chatsocket : ChatSocket, private _formBuilder : FormBuilder, private _upload : MediauploadService) { }

SendMessage() {
  this.chatForm.controls['publicationId'].setValue(Number(this.reservation.publication.id));
  this.chatForm.controls['reservationId'].setValue(Number(this.reservation.id));

this._chatsocket.emit('chat-message-customer', this.chatForm.value);

this.chatForm.controls['message'].setValue('');

}

onFileSelect(input: HTMLInputElement){
  let file : File = input.files![0];
  let formdata = new FormData();
  formdata.append('file', file);

  this._upload.UploadChatAttachment(formdata).subscribe((resp : any) => {
    if(resp.success){

      let message = {
        "publicationId" : Number(this.reservation.publication.id),
        "reservationId" : Number(this.reservation.id),
        "message" : "",
        "files" : [
          {"name" : resp.data.name}
        ]
      }
      this._chatsocket.emit('chat-message-customer', message);
    }
  }
  );



}

ngOnInit(): void {
  this.chatForm = this._formBuilder.group({
    publicationId: this.reservation?.publication?.id,
    reservationId: this.reservation.id,
    files : this._formBuilder.array([]),
    message: ['', {disabled: true}]
  });

 this.timeoutLoading = setTimeout(() => {
    this.is_loading = false;
    this.chatForm.controls['message'].enable();
  }, 2000);


  this._chatsocket.emit('join-room-comensal', {publicationId: Number(this.reservation.publication.id), reservationId: Number(this.reservation.id)});
  this._chatsocket.emit('create-contact', {publicationId: Number(this.reservation.publication.id), reservationId: Number(this.reservation.id)});
  this._chatsocket.on('conversation', (payload : any) => {
    this.chatMessages = payload.users;
    this.chatMessages.reverse();
    clearTimeout(this.timeoutLoading);
    this.is_loading = false;
    this.chatForm.controls['message'].enable();

  });
  this._chatsocket.on('update-chat', (payload : any) => {
    this.chatMessages.push(payload);
  });

}

ngAfterViewInit(): void {
  this.msj.changes.subscribe(t => {
    let containerHeight = this.simplebar.SimpleBar.getScrollElement().scrollHeight;
    this.simplebar.SimpleBar.getScrollElement().scrollTop = containerHeight;
  });
}

}

import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject, catchError, map, of } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

import { SocketService } from './socket.service';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userAPI = environment.ApiUsuarios;
  private notificationAPI = environment.ApiNotificaciones;
  private publicationAPI = environment.ApiPublicaciones;
  private baseURL = environment.baseUrl;
  private IPGeolocation = environment.IPGeolocation;
  private isBrowser : boolean;
  private UserLocation : Subject<any> = new Subject<any>();
  hasNotifications : boolean = false;
  userNotifications : any[] = [];
  UserLocation$ = this.UserLocation.asObservable();
  constructor(private http : HttpClient, @Inject(PLATFORM_ID) platformId: Object, private NotificationsSocket : SocketService) {
    this.isBrowser = isPlatformBrowser(platformId);
    if(this.isBrowser) {
    this.UserLocation.next(JSON.parse(localStorage.getItem('userLocation') || '{}'));
    }

    this.getUserNotifications({page : 1, limit : 10});
    let emit =  this.NotificationsSocket.emit('join-logged-user', {});


  this.NotificationsSocket.on('user-notifications', (notification : any) => {


    this.userNotifications.push(notification);
    this.CheckNotReadNotifications();
  });

  }

  /**
   * Update user data
   * @param user
   * @returns  Boolean
   */

  Update(user : any) {
    const url = `${this.userAPI}/user`;


    return this.http.put(url, user).pipe(
      map((resp: any) => {
        return resp.success;
      }),
      catchError(err => {
        return of(false);
      })
    );
  }

  /**
   * Generate avatar from username
   * @param username
   * @param rounded
   * @param size
   * @returns image url
   */
  GenerateAvatar (username : string, rounded: boolean = false, size : number = 64) {
    const url = 'https://ui-avatars.com/api/';
    return `${url}?name=${username}&background=feeae5&color=1f1b2d&size=${size}&rounded=${rounded}`;
  }


  /**
   * Get user Favorites
   * @param user_id
   * @returns  Observable
   */
  GetFavorites() {
    const url = `${this.userAPI}/favorites`;
    return this.http.get(url);
  }

/**
 * Remove favorite
 */
RemoveFavorite(publication_id : number) {
  const body = {
    "state": 0
  }
  const url = `${this.userAPI}/publications/${publication_id}/favorites`;
  return this.http.post(url, body);
}

/**
 * Set Favorite
 */

SetFavoriteStatus(publication_id : number, state : number) {
  const url = `${this.userAPI}/publications/${publication_id}/favorites`;
  return this.http.post(url, {'state': state});


}

public GeoLocationbyIP() {
  const url =  `${this.IPGeolocation}`;
  return this.http.get(url);

}

public ReverseGeoCode(latlng : string) {
  const url =  `${this.publicationAPI}/geocode`;
  return this.http.get(url, {params: {latlng: latlng, result_type : 'political'}});

}

public GetDistrictList() {
  const url =  `${this.baseURL}/assets/json/districts.json`;
  return this.http.get(url);

}

public GetCitiesList() {
  const url =  `${this.baseURL}/assets/json/citys.json`;
  return this.http.get(url);

}

public SetLocation(userLocality : any, userMunicipality : any, userState : any) {
  let userLocation = {
    'locality' : userLocality,
    'state' : userState,
    'municipality' : userMunicipality
  }
localStorage.setItem('userLocation', JSON.stringify(userLocation));
this.UserLocation.next(userLocation);
}


 /**
   * Get Reviews Likes for publication
   * @param id publication id
   * @returns Observable
   */

 getReviewsLikesForPublication(id: number) {
  return this.http.get(`${this.userAPI}/publications/${id}/likes`, {observe: 'response'});
}


/**
 * Get managers for publication
 * @param publication_id
 *
 */

getManagers(publication_id : number) {
  const url = `${this.userAPI}/admins/publications/${publication_id}/managers`;
  return this.http.get(url);
}


/**
 * Get Roles
 */

getRoles() {
  const url = `${this.userAPI}/admins/descriptions`;
  return this.http.get(url);
}

/**
 *Search User for manager
 * @param publication_id
 * @param queryParams
 */

searchUserForManager(publication_id : number, queryParams : any) {
  const url = `${this.userAPI}/admins/publications/${publication_id}/managers/users`;
  return this.http.get(url, {params: queryParams});
}

/**
 * Add manager
 * @param publication_id
 * @param bs_user_id
 * @param bs_role_id
 */

addManager(publication_id : number, bs_user_id : number, bs_role_id : number) {
  const url = `${this.userAPI}/admins/publications/${publication_id}/managers`;
  const body = {
    bs_user_id : bs_user_id,
    bs_role_id : bs_role_id
  }
  return this.http.post(url, body);
}

/**
 * Update Manager
 * @param publication_id
 * @param user_id
 * @param bs_role_id
 */

updateManager(publication_id : number, user_id : number, bs_role_id : number) {
  const url = `${this.userAPI}/admins/publications/${publication_id}/managers/${user_id}`;
  const body = {
    bs_role_id : bs_role_id
  }
  return this.http.patch(url, body);

}

/**
 * Delete Manager
 * @param publication_id
 * @param user_id
 */

deleteManager(publication_id : number, user_id : number) {
  const url = `${this.userAPI}/admins/publications/${publication_id}/managers/${user_id}`;
  return this.http.delete(url);

}


/**
 * Get Notifications
 */

getNotifications() {
  const url = `${this.userAPI}/notifications`;
  return this.http.get(url);
}


/**
 * Get User notifications
 @param queryParams
 */

getUserNotifications(queryParams : any) {
  const url = `${this.notificationAPI}/admin/publications/1/notifications/user`;
  return this.http.get(url, {params: queryParams}).subscribe((resp : any) => {
    this.userNotifications = resp.data.notifications;
  });
}



CheckNotReadNotifications() {
  let notifications = this.userNotifications.filter((notification : any) => {
    return notification.view == 0;
  });

  if(notifications.length > 0) {
    this.hasNotifications = true;
  } else {
    this.hasNotifications = false;
  }
}




}


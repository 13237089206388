import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BrandService } from 'src/app/core/services/brand.service';
import { MessagesService } from 'src/app/core/services/messages.service';
import { environment } from 'src/environments/environment';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';

@Component({
  selector: 'app-members-header',
  templateUrl: './members-header.component.html',
  styleUrls: ['./members-header.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        opacity: 1,
        height: '230px',
        padding: '2rem 0'
      })),
      state('closed', style({
        opacity: 0,
        height: 0,
        padding: '0.2rem 0',
        background: 'transparent',

      })),
      transition('open => closed', [
        animate('1s ease-out',)
      ]),
      transition('closed => open', [
        animate('1s ease-out')
      ]),
    ]),
  ],
})



export class MembersHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  usuario! : any;
  isHidden : boolean = true;
  dropzoneconfig!: any;
  storage = environment.Storage_brand;
  editBrandForm! : UntypedFormGroup;
  apiUpload = environment.ApiUpload;
  breadCrumbItems!: Array<{}>;
  private brand: any = this._brand.getBrand();
  constructor(private _brand : BrandService, private ngbmodal : NgbModal,
    private formBuilder : FormBuilder, private message : MessagesService,
     private router : Router, private _message : MessagesService, private _route : Router) {

   }

   openPanel() {
    this.isHidden = false;
   }

    closePanel() {
      this.isHidden = true;
    }
  get Brand() {
    return this.brand;
  }

  get EditBrandForm() {
    return this.editBrandForm;
  }

  openModalEditBrand(editBrand: any) {
    this.ngbmodal.open(editBrand, { size: 'md', centered: true });

  }

  onUploadSuccess(event: any) {
    const image = event[1].data.name;
    this.editBrandForm.controls['image'].setValue(image);
  }

  submitEditBrand() {
  if(this.editBrandForm.invalid) {
    return;
  }
  this._brand.UpdateBrand(this.usuario.brand_id, this.editBrandForm.value).subscribe((resp:any) => {
    if(resp.success) {
      this.brand = [];
      this._brand.setBrand(this.usuario);
      this.ngbmodal.dismissAll();
      this._brand.getBrandbyID(this.usuario.brand_id).subscribe((resp:any) => {
        this.brand = resp.data;
        this.editBrandForm.patchValue(this.brand);

        let image = this.storage+this.brand.image;
        const dropzone_template = `<div class="dz-preview dz-file-preview image-preview">
        <img class="dropzone-preview" data-dz-thumbnail  width="300"/>
        <a href="javascript.void(0)" class="btn btn-icon shadow-sm dz-remove-image" data-dz-remove><i class="fi-trash"></i></a>
        <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
        <div class="dz-success-mark"><span><svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <title>Check</title> <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <path d="M23.5,31.8431458 L17.5852419,25.9283877 C16.0248253,24.3679711 13.4910294,24.366835 11.9289322,25.9289322 C10.3700136,27.4878508 10.3665912,30.0234455 11.9283877,31.5852419 L20.4147581,40.0716123 C20.5133999,40.1702541 20.6159315,40.2626649 20.7218615,40.3488435 C22.2835669,41.8725651 24.794234,41.8626202 26.3461564,40.3106978 L43.3106978,23.3461564 C44.8771021,21.7797521 44.8758057,19.2483887 43.3137085,17.6862915 C41.7547899,16.1273729 39.2176035,16.1255422 37.6538436,17.6893022 L23.5,31.8431458 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z" stroke-opacity="0.198794158" stroke="#747474" fill-opacity="0.816519475" fill="#FFFFFF"></path> </g> </svg></span></div>
        <div class="dz-error-mark"><span><svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <title>Error</title> <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g stroke="#747474" stroke-opacity="0.198794158" fill="#FFFFFF" fill-opacity="0.816519475"> <path d="M32.6568542,29 L38.3106978,23.3461564 C39.8771021,21.7797521 39.8758057,19.2483887 38.3137085,17.6862915 C36.7547899,16.1273729 34.2176035,16.1255422 32.6538436,17.6893022 L27,23.3431458 L21.3461564,17.6893022 C19.7823965,16.1255422 17.2452101,16.1273729 15.6862915,17.6862915 C14.1241943,19.2483887 14.1228979,21.7797521 15.6893022,23.3461564 L21.3431458,29 L15.6893022,34.6538436 C14.1228979,36.2202479 14.1241943,38.7516113 15.6862915,40.3137085 C17.2452101,41.8726271 19.7823965,41.8744578 21.3461564,40.3106978 L27,34.6568542 L32.6538436,40.3106978 C34.2176035,41.8744578 36.7547899,41.8726271 38.3137085,40.3137085 C39.8758057,38.7516113 39.8771021,36.2202479 38.3106978,34.6538436 L32.6568542,29 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z"></path> </g> </g> </svg></span></div>
        </div>`;

       this.dropzoneconfig = {
         url: this.apiUpload + '/publications/temp/brandimglogo',
         acceptedFiles: 'image/*',
         createImageThumbnails: true,
         thumbnailWidth: 270,
         thumbnailHeight: 270,
         maxFiles: 1,
         previewTemplate: dropzone_template,
         init() {
           let myDropzone = this;
           let mockFile = { name: "Filename 2", size: 12345 };

           let callback = null; // Optional callback when it's done
           let crossOrigin = null; // Added to the `img` tag for crossOrigin handling
           let resizeThumbnail = false; // Tells Dropzone whether it should resize the image first
           myDropzone.displayExistingFile(mockFile, image, callback, crossOrigin, resizeThumbnail);

         }

       };
      });
      this.message.show('¡Exito!', 'Se ha actualizado la información de la marca', 'success');
    }
  });
  }

  ngOnInit(): void {
    let currentlabel = ' ';

  if(this._route.url.includes('messages')){
    currentlabel = 'Mensajes';

    }

    if(this._route.url.includes('reservations')){
      currentlabel = 'Reservaciones';
    }

    if(this._route.url.includes('experiences')){
      currentlabel = 'Experiencias';
    }

    if(this._route.url.includes('notifications')){
      currentlabel = 'Notificaciones';
    }

    if(this._route.url.includes('reviews')){
      currentlabel = 'Reseñas';
    }

    if(this._route.url.includes('gallery')){
      currentlabel = 'Galeria';
    }

    if(this._route.url.includes('settings')){
      currentlabel = 'Configuraciones';
    }

    this.breadCrumbItems = [
      { label: 'Panel del anfitrion', link:'../' },
      { label: currentlabel, active: true},
    ];

    if(!this.usuario.brand_id){
      this._message.show('¡Error!', 'Aun no tienes un negocio registrado', 'danger');
      this.router.navigate(['/']);
    }

    this.editBrandForm = this.formBuilder.group({
      name: [''],
      image: [''],
    });



    this._brand.brandObs.subscribe((resp:any) => {
      this.brand = resp;

    });

      this.editBrandForm.patchValue(this.brand);

       let image = this.storage+this.brand.image;
       const dropzone_template = `<div class="dz-preview dz-file-preview image-preview">
       <img class="dropzone-preview" data-dz-thumbnail  width="300"/>
       <a href="javascript.void(0)" class="btn btn-icon shadow-sm dz-remove-image" data-dz-remove><i class="fi-trash"></i></a>
       <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
       <div class="dz-success-mark"><span><svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <title>Check</title> <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <path d="M23.5,31.8431458 L17.5852419,25.9283877 C16.0248253,24.3679711 13.4910294,24.366835 11.9289322,25.9289322 C10.3700136,27.4878508 10.3665912,30.0234455 11.9283877,31.5852419 L20.4147581,40.0716123 C20.5133999,40.1702541 20.6159315,40.2626649 20.7218615,40.3488435 C22.2835669,41.8725651 24.794234,41.8626202 26.3461564,40.3106978 L43.3106978,23.3461564 C44.8771021,21.7797521 44.8758057,19.2483887 43.3137085,17.6862915 C41.7547899,16.1273729 39.2176035,16.1255422 37.6538436,17.6893022 L23.5,31.8431458 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z" stroke-opacity="0.198794158" stroke="#747474" fill-opacity="0.816519475" fill="#FFFFFF"></path> </g> </svg></span></div>
       <div class="dz-error-mark"><span><svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <title>Error</title> <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g stroke="#747474" stroke-opacity="0.198794158" fill="#FFFFFF" fill-opacity="0.816519475"> <path d="M32.6568542,29 L38.3106978,23.3461564 C39.8771021,21.7797521 39.8758057,19.2483887 38.3137085,17.6862915 C36.7547899,16.1273729 34.2176035,16.1255422 32.6538436,17.6893022 L27,23.3431458 L21.3461564,17.6893022 C19.7823965,16.1255422 17.2452101,16.1273729 15.6862915,17.6862915 C14.1241943,19.2483887 14.1228979,21.7797521 15.6893022,23.3461564 L21.3431458,29 L15.6893022,34.6538436 C14.1228979,36.2202479 14.1241943,38.7516113 15.6862915,40.3137085 C17.2452101,41.8726271 19.7823965,41.8744578 21.3461564,40.3106978 L27,34.6568542 L32.6538436,40.3106978 C34.2176035,41.8744578 36.7547899,41.8726271 38.3137085,40.3137085 C39.8758057,38.7516113 39.8771021,36.2202479 38.3106978,34.6538436 L32.6568542,29 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z"></path> </g> </g> </svg></span></div>
       </div>`;

      this.dropzoneconfig = {
        url: this.apiUpload + '/publications/temp/brandimglogo',
        acceptedFiles: 'image/*',
        createImageThumbnails: true,
        thumbnailWidth: 270,
        thumbnailHeight: 270,
        maxFiles: 1,
        previewTemplate: dropzone_template,
        init() {
          let myDropzone = this;
          let mockFile = { name: "Filename 2", size: 12345 };

          let callback = null; // Optional callback when it's done
          let crossOrigin = null; // Added to the `img` tag for crossOrigin handling
          let resizeThumbnail = false; // Tells Dropzone whether it should resize the image first
          myDropzone.displayExistingFile(mockFile, image, callback, crossOrigin, resizeThumbnail);

        }


      };



  }
  ngAfterViewInit() {

  }



  ngOnDestroy() {
    this.brand = [];

  }

}

import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
@Input() message: string = "";
@Input() type: string = "danger";
@Input() show: boolean = false;
}

import { Component, Input, OnInit } from '@angular/core';
import { ExperiencesService } from 'src/app/core/services/experiences.service';


@Component({
  selector: 'app-reservationform-simple',
  templateUrl: './reservationform-simple.component.html',
  styleUrls: ['./reservationform-simple.component.scss']
})
export class ReservationformSimpleComponent implements OnInit {
  @Input() publicationId!: number;
  @Input() reservationData!: any;
  @Input() Publication!: any;
  Experiences: any = {};
  Reservation : any ;

  constructor(private _experiences : ExperiencesService) { }

  ngOnInit(): void {
    console.log(this.reservationData);
this._experiences.getExperienceByPublication(this.publicationId).subscribe((experiences:any)=>{
if(experiences.data.length > 0){
  this.Experiences = experiences.data;
} else {
  this.Experiences = null;
}
});
  }

}

<div class="mb-3" *ngIf="!calendarExpanded">
  <p class="form-label mb-3"> Fechas</p>
  <div class="available-dates">
    <div class="calendar-pagination">
    <button class="btn btn-secondary btn-sm prev-btn" (click)="prevDates()"><i class="fi-chevron-left"></i></button>
    <button class="btn btn-secondary btn-sm next-btn" (click)="nextDates()"><i class="fi-chevron-right"></i></button>
    </div>
    <ng-container  *ngFor="let date of PageDates[PageNumber]; let i = index;">
    <input type="radio" class="btn-check" id="date-{{date.id}}" [(ngModel)]="Reservation['date']" [ngClass]="Reservation['date'].length > 0  && Reservation['date'] !== date.date ? 'no-active' : ''" (change)="updateSelectDay(date.date)" name="miniCalendar" autocomplete="off" value="{{date.date}}" >
    <label class="btn btn-outline-primary reservation-date" for="date-{{date.id}}" [ngClass]=" i == 0 ? 'shadow' : ''"><strong>{{date.date | date:'EEE'}}<br>
      </strong> <span class="day">{{date.date | date:'dd'}}</span> <span class="month" [class.no-discount] = "date.discount <=0" >{{date.date | date:'MMM'}}</span> <br>
    <span class="discount" *ngIf="date.discount > 0"> {{date.discount}}%</span></label>
    </ng-container>
   </div>
  </div>
  <div class="datepicker-container"  [ngClass]=" calendarExpanded === true ? 'd-block' : 'd-none'">
    <div class="d-flex justify-content-end">
      <button class="btn text-link text-primary p-0" style="position: absolute;top: 100px;z-index: 2;right: 185px;font-size: 11px;color: #fff !important" (click)="calendarExpanded = false"><i class="fi-x"></i></button>
    </div>
    <div class="datepicker" #datepicker></div>
    <div class="bottom-datepicker bg-primary py-2 px-3 text-light d-flex justify-content-center align-items-center rounded" style="border-radius: 10px;margin-top: -20px;">
      <i class="fi-checkbox-checked-alt me-2 fs-4"></i>
      <p class="fs-xs mb-0">Haz una reserva para ganar  y disfruta de un descuento exclusivo por fidelidad.</p>
    </div>

  </div>
  <div class="border-bottom pb-3 mb-2 text-center" *ngIf="!calendarExpanded">
    <a class="text-link fw-bold fs-sm" href="javascript:void(0);" (click)="ViewAllMonth()">Ver todo el mes</a>
  </div>
  <div class="row mt-3 mb-4" *ngIf="!calendarExpanded">
    <div class="col-6">
      <div class="mb-0">
        <label for="select-input" class="form-label">Tamaño</label>
        <select class="form-select" id="select-input" [(ngModel)]="Reservation['num_guests']" (change)="changeHorandDate()">
          <ng-container *ngFor="let people of Peoples">
            <ng-container *ngIf="people.value == 1; else normal_option">
              <option [value]="people.value" selected="true">{{people.value}} Persona</option>
            </ng-container>
            <ng-template #normal_option>
          <option [value]="people.value">{{people.value}} Personas</option>
          </ng-template>
          </ng-container>
        </select>
      </div>
    </div>
    <div class="col-6">
      <div class="mb-0">
        <label for="select-input" class="form-label">Hora</label>
        <select class="form-select" id="select-input" [(ngModel)]="Reservation['hour']" (change)="changeHorandDate()">
          <option *ngFor="let hour of Hours" [value]="hour.option">{{hour.option_user}}</option>
        </select>
      </div>
    </div>
  </div>
  <div>
    <button *ngIf="!calendarExpanded && !hasReserved" class="btn btn-primary d-block w-100 mt-3" (click)="validatefirsstep()" ><span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" *ngIf="is_searching"></span> {{is_searching ? 'Buscando' :'Buscar un horario'}}</button>

    <ng-container *ngIf="!is_available && AvailableHours.length == 0">
      <div class="not_hours_available mt-4">
        <p class="mb-1" style="font-size: 15px">Lo sentimos no hay horarios disponibles</p>
        <p class="fw-bold text-center">Por favor selecciona otra fecha</p>

      </div>


    </ng-container>



    <div class="hourly-options my-3" *ngIf="step1 === true && step2 == false && !hasReserved" >
      <p class="text-center form-label mb-3">Selecciona tu horario</p>
      <div class="row row-cols-4 gx-2 gy-4">
        <div class="col" *ngFor="let hour of AvailableHours">
          <input type="radio" class="btn-check" id="btn-check" name="hora" autocomplete="off" [attr.disabled]="hour.availability == false ? 'disabled' : null">
          <label class="btn btn-outline-gray position-relative w-100 pb-3" for="btn-check" (click)="validatesecondstep(hour)">{{Moment(hour.hour, 'hh:mm:ss').format('hh:mm')}} <span class="hour-discount" *ngIf="selectedDate.discount > 0" >{{selectedDate.discount}}%</span></label>
        </div>
      </div>

    </div>
    <div class="location-options mt-3" *ngIf="step2 === true && !hasReserved">
      <p class="text-center form-label mb-3">Selecciona tu opción de mesa</p>
        <div class="row gy-3 row-cols-2 d-flex justify-content-center">
          <div class="col" *ngFor="let zone of selectedHour.form.zone_ids">
          <input type="radio" class="btn-check" id="zone-{{zone.id}}" [(ngModel)]="Reservation['zone']" [value]="zone" autocomplete="off">
          <label class="btn btn-sm btn-outline-gray d-flex align-items-center" for="zone-{{zone.id}}"><mat-icon  fontIcon="table_restaurant" class="me-1"></mat-icon>{{zone.name}}</label>
          </div>
        </div>
        <button *ngIf="step3 === false" class="btn btn-primary d-block w-100 mt-3"  [attr.disabled]="selectedHour.form && !Reservation['zone'] ? true : null"  (click)="validatethirdstep()">Continuar</button>

        <div class="experiences-options my-3" *ngIf="step3 === true && !hasReserved">
          <div class="mb-2" *ngFor="let experience of experiences">
            <input type="radio" class="btn-check" id="promo-{{experience.experienceId}}" name="promo" [(ngModel)]="Reservation['experience']" [value]="experience.experienceId">
            <label class="position-relative btn btn-sm btn-outline-secondary fw-normal text-start w-100" for="promo-{{experience.experienceId}}">
              <div class="position-absolute top-0 end-0 pt-1 pe-1">
                <mat-icon fontIcon="radio_button_checked" *ngIf="Reservation['experience'] == experience.experienceId"></mat-icon>
                <mat-icon fontIcon="radio_button_unchecked" *ngIf="Reservation['experience'] !== experience.experienceId"></mat-icon>
              </div>
              <strong>{{experience.title}}</strong><br/>
              <span>Obtendrás un {{experience.discountrate}}% de descuento</span><br/>
              <span>Disponible en el horario reservado</span>
            </label>
            </div>
            <div class="mb-2">
              <input type="radio" class="btn-check" id="sin_promo" name="promo" [(ngModel)]="Reservation['experience']" [value]="false">
              <label class="btn btn-sm btn-outline-secondary fw-normal text-start w-100" for="sin_promo">
                <div class="position-absolute top-0 end-0 pt-1 pe-1">
                  <mat-icon fontIcon="radio_button_checked" *ngIf="Reservation['experience'] === false"></mat-icon>
                  <mat-icon fontIcon="radio_button_unchecked" *ngIf="Reservation['experience'] !== false"></mat-icon>
                </div>
                <strong>Reservar en {{Publication.name}}</strong><br/>
                <span>Reservación con menú a la carta sin oferta</span><br/>
              </label>
            </div>
          <button *ngIf="step2 === true" class="btn btn-primary d-block w-100 mt-3" (click)="openModal(reservar)" >Reservar</button>
        </div>

    </div>
    <button *ngIf="hasReserved" class="btn btn-primary d-block w-100 mt-3" (click)="openModal(reservar)">Completar Reservación</button>
  </div>

  <ng-template #reservar  let-modal>
    <div class="reservation-modal modal-body">
      <button type="button" class="btn-close" aria-label="Close" (click)="CancelTemporaryReservation(confirmCancellation)"></button>

      <app-reservationform [reservationData]="reservationData" [publicationId]="PublicationID" [Publication]="Publication"></app-reservationform>
    </div>
  </ng-template>

  <ng-template  #confirmCancellation let-modal>
    <div class="modal-body b-rounded">
      <button class="btn-close" (click)="modal.dismiss('Cross Click')"></button>
      <h6>¿Deseas cancelar tu reservación?</h6>
      <p class="text-muted">Se perdera tu progreso y cualquier dato que ya hayas introducido</p>

      <div class="d-flex justify-content-end">
        <button class="btn btn-outline-primary me-2" (click)="modal.dismiss('regresar')">Regresar</button>
        <button class="btn btn-outline-danger" (click)="modal.close('cancelar')">Si, cancelar</button>
      </div>
    </div>
    </ng-template>

import { Component, OnInit } from '@angular/core';
import { NgProgress, NgProgressRef } from 'ngx-progressbar';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({
  selector: 'app-loader',
  template: `
    <ng-progress id="myProgress" color="#fd5631"></ng-progress>
  `

})
export class LoaderComponent implements OnInit {

  progressRef!: NgProgressRef;
  constructor(private progress: NgProgress, private loader : LoaderService) {}


  ngOnInit() {
    this.progressRef = this.progress.ref('myProgress');
    this.progressRef.setConfig({ color: 'orange' });

    this.loader.isLoading.subscribe((v) => {
      if (v) {
        this.progressRef.start();
      } else {
        this.progressRef.complete();
      }
    });
  }



}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';

import { MessagesService } from 'src/app/core/services/messages.service';
import { NewsletterService } from 'src/app/core/services/newsletter.service';
import { PublicationsService } from 'src/app/core/services/publications.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})


/**
 * Footer Component
 */

export class FooterComponent implements OnInit {
   newsletterForm! : UntypedFormGroup;

   ActiveLang : string ="";
  constructor(private service: TranslocoService , private publicationService : PublicationsService,
     private _formBuilder : FormBuilder, private _message : MessagesService, private _newsletter : NewsletterService) {
    this.ActiveLang = service.getActiveLang();
  }
  publications : any;

  ngOnInit(): void {


    this.newsletterForm = this._formBuilder.group({
      email: ['', Validators.required],

  });

  }

  get NewsLetterForm() {
    return this.newsletterForm.controls;
  }

  public SignupNewsletter() {
    if (this.newsletterForm.invalid) {
      this._message.show('','Debe ingresar un email', 'danger');
      return;
    }

    this._newsletter.suscribirse(this.newsletterForm.value.email).subscribe((response : any) => {
      if(response.success){
      this._message.show('','Se ha suscrito correctamente', 'success');
      this.newsletterForm.reset();
      }
    });


  }
  /**
  * SidebarHide modal
  * @param content modal content
  */
   sidebarShow() {
    document.getElementById('demo-switcher')?.classList.add('show');
    document.querySelector('.vertical-overlay')?.classList.add('show');
  }

  /**
  * SidebarHide modal
  * @param content modal content
  */
  SidebarHide() {
    document.getElementById('demo-switcher')?.classList.remove('show');
    document.querySelector('.vertical-overlay')?.classList.remove('show');
  }

  ChangeLanguage(language : string) {
    this.service.setActiveLang(language);
    this.ActiveLang = this.service.getActiveLang();

  }

}

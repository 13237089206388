import { Component, ElementRef, Input, ViewChild, OnInit } from '@angular/core';
import { DateTime, easepick, LockPlugin } from '@easepick/bundle';
import * as moment from 'moment';
import { Subject, pipe, distinctUntilChanged} from 'rxjs';
import { ReservationsService } from 'src/app/core/services/reservations.service';
import { environment } from 'src/environments/environment';
import * as _ from "lodash";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExperiencesService } from 'src/app/core/services/experiences.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { promises } from 'dns';
import { ThemeService } from 'src/app/core/services/theme.service';
@Component({
  selector: 'app-check-availability-vertical',
  templateUrl: './check-availability-vertical.component.html',
})
export class CheckAvailabilityVerticalComponent implements OnInit {

  @ViewChild('datepicker', { static: false }) datepicker!: ElementRef;
  @Input() PublicationID! : any;
  @Input() Publication! : any;
  BaseURl = environment.baseUrl;
  //public Vars
  calendar!: any;
  isLockedDay = true;
  calendarExpanded = false;
  reservationData : any = {};
  is_available : boolean = true;
  is_mobile : boolean = false;
  is_darkmode : boolean = false;
 Moment : any = moment;
  //Mini Calendar
  private pageNumber : number = 0;
  private visibleElements: number = 4;
  private pages: any[]  = [];
  public reservationDay : any;
  public selectedDay = new Subject<string>();
  public selectedDay$ = this.selectedDay.asObservable()
  public publicationId! : number;
  is_searching : boolean = false;
  //Calendar
  private CurrentDate : DateTime = new DateTime();
  Reservation : any = {
      date : "",
      hour : "",
      num_guests : 2,
      experience: false

};

private lockedDays : any = [];
private peoples : any = [];
private hours : any = [];
private discountdates : any = [];
isdaySelected  = false;
experiences : any = [];
//steps
step1 = false;
step2 = false;
step3 = false;
hasReserved : boolean = false;
selectedDate : any = [];
reserva : boolean = false;
  // Reservation Data
  AvailableDates : any[] = this.getDaysArrayByMonth(this.CurrentDate);
  AvailableHours : any[] = [];


 selectedHour : any = [];
  public selectDaySuscription = this.selectedDay$.subscribe(data => {
   this.reservationDay = data;
});

constructor(private _reservations : ReservationsService, private ngbmodal : NgbModal,
  private _experiences : ExperiencesService, private _ssrcookies : SsrCookieService,
  private _theme : ThemeService) {}

  CancelTemporaryReservation(modal : any) {
    let cancelModal = this.ngbmodal.open(modal, {size: 'modal-mediano', centered: true, backdrop: 'static' });
    cancelModal.result.then((result:any) => {
    if(result == 'cancelar') {
      this._ssrcookies.delete('ReservationData');
      this._ssrcookies.delete('ReservationExpires');
      this.step1 = this.step2 = this.step3 = false;
      this.ngbmodal.dismissAll();
    }
    });



   }

 RenderCalendar () {
  let dateswithDiscount = this.discountdates;

  let lockedDays = this.lockedDays;
  let resdate : string = new Date().toDateString();
  const today = new Date();
  const prices : any = {};
    let cssurl = "";
    let lockurl = "";
    if(this.is_darkmode) {
      cssurl = `${this.BaseURl}/assets/css/easepick-check-availability-dark.css`;
      lockurl = `${this.BaseURl}/assets/css/lockplugin-dark.css`;
    } else {
      cssurl = `${this.BaseURl}/assets/css/easepick-check-availability.css`;
      lockurl = `${this.BaseURl}/assets/css/lockplugin.css`;
    }
    this.calendar = new easepick.create({
    element: this.datepicker.nativeElement,
    inline: true,
    css: [
      cssurl,
      lockurl,
    ],
    setup(picker) {
      dateswithDiscount.forEach( (x : any) => {
        prices[x.date] = x.percent;
      });

      // add price to day element
      picker.on('view', (evt) => {
        const { view, date, target } = evt.detail;
        const d = date ? date.format('YYYY-MM-DD') : null;

        if (view === 'CalendarDay' && prices[d]) {
          const span = target.querySelector('.day-discount') || document.createElement('span');
          span.className = 'day-discount';
          span.innerHTML = `${prices[d]}%`;
          target.append(span);
        }
      });


    },
    plugins: [LockPlugin],
    LockPlugin: {
      minDate: new Date(),
      filter(date , picked) {
        const fechas =  new DateTime(date).format('YYYY-MM-DD');

        return lockedDays.includes(fechas);
      },
    },

  });
  this.calendar.on('view', (evt : any) => {
    const { view, date, target } = evt.detail
    let prices : any = {};
   this.discountdates = this.discountdates.filter((item : any) => !this.lockedDays.includes(item.date));
    this.discountdates.forEach( (x : any) => {
      prices[x.date] = x.percent;
    });



    const d = date ? date.format('YYYY-MM-DD') : null;

    if (view === 'CalendarDay' && prices[d]) {
      const span = target.querySelector('.day-discount') || document.createElement('span');
      span.className = 'day-discount';
      span.innerHTML = `${prices[d]}%`;
      target.append(span);
    }


  });
  this.calendar.on('select', (evt : any) => {
    let  {date} = evt.detail;


    let dateselected: moment.Moment = moment(date);
    dateselected.format('YYYY-MM-DD');

    let pagenumber = _.findIndex(this.PageDates, function(o) {
      return _.some(o, function(Date) {
      return Date.date == dateselected.format('YYYY-MM-DD');
      })
    });
    this.pageNumber = pagenumber;

    this.updateSelectDay(dateselected.format('YYYY-MM-DD'));


    this.calendarExpanded = false;  // hide calendar
  });
}


public updateSelectDay(selectDay: string) {
  this.isdaySelected = true;
  this.selectedDay.next(selectDay);
  this.calendar.setDate(selectDay);
  this.step1 = this.step2 = this.step3 = false;
}

public nextDates() {
  if (this.pageNumber < this.pages.length - 1) {
    this.pageNumber++;
  }
}

public prevDates() {
  if (this.pageNumber > 0) {
    this.pageNumber--;
  }
}


public paginateCalendar() {
  //Create the pagination for mini calendar
  this.pages = [];
  for (let i = 0; i < this.AvailableDates.length; i += this.visibleElements) {
   this.pages.push(this.AvailableDates.slice(i, i + this.visibleElements));
 }

}



getDaysArrayByMonth(date : any) {
  var monthDate = moment(date, 'YYYY-MM');

  var daysInMonth = monthDate.daysInMonth();

this.discountdates;
  var arrDays = [];
  while(daysInMonth) {

    var current = moment(date, 'YYYY-MM').date(daysInMonth);

    arrDays.push({ id: daysInMonth, date: current.format('YYYY-MM-DD'), discount: 0});

    daysInMonth--;

  }
  arrDays.reverse();
  return arrDays;


}

/*
* Display the Calendar
*/

ViewAllMonth() {
  this.calendarExpanded = true;
  this.calendar.show();

}

changeHorandDate() {
  this.step1 = false;
  this.step2 = false;
  this.step3 = false;
}

/*
* Setup Mini calendar date
*/
setReservationDate(date : any) {
this.updateSelectDay(date);
}


// Steps

public validatefirsstep() {
 let hour = this.hours.find((x : any) => x.option == this.Reservation['hour']);
this.selectedDate = this.PageDates[this.pageNumber].find((x : any) => x.date == this.Reservation['date']);

let reservation = {
    date : this.Reservation['date'],
    hour : hour.option,
    num_guests : this.Reservation['num_guests'],
    brand_id: this.Publication['brand_id']
  }

  this.is_searching = true;
  this._reservations.CheckAvailability(this.PublicationID, reservation).subscribe(
    (response : any) => {
      if(response.status == 200){

        if(response.body.success) {
          this.is_available = true;
          this.step1 = true;
          this.AvailableHours = response.body.data;
          this.reservationData['date'] = reservation['date'];
          this.reservationData['num_guests'] = reservation['num_guests'];
        } else {
          this.is_available = false;
          this.step1 = false;

        }

      }

      this.is_searching = false;
    }
  )

}



public validatesecondstep(hour : any) {
  this.step2 = true;
  this.selectedHour = hour;

  this.reservationData['hour'] = hour.hour;
  this.reservationData['index'] = hour.index;

  let ExperiencesQueryParams = {
    day: this.Reservation['date'],
    hour: this.Reservation['hour'],
    publicationId : this.PublicationID,
  }
  this._experiences.GetExperiencesForDateAndHour(ExperiencesQueryParams).subscribe((response : any) => {
    if(response.success) {
        this.experiences = response.data.data;
    }
  });

}

public validatethirdstep() {
this.step3 = true;
this.reservationData['zone_id'] = this.Reservation['zone']['id'];
this.reservationData['zone_name'] = this.Reservation['zone']['name'];

}

public setDateMiniCalendar(date : any) {
  this.reservationDay = date;
  this.calendar.setDate(date);
}


public openModal(content : any) {
let sizeModal = 'modal-mediano';

if(this.is_mobile) {
  sizeModal = 'fullscreen';
}

  this.reservationData['experience_id'] = this.Reservation['experience'];
  this.ngbmodal.open(content, {size: sizeModal, centered: true, backdrop : 'static', });

}

public getDiscountForMonth(date : any) {
  this._experiences.GetExperiencesForCalendarDiscountRate(this.PublicationID, {date:  date}).subscribe((response : any) => {
    this.discountdates = response.data;

    this.AvailableDates.forEach((date : any) => {
      let discount = this.discountdates.find((x : any) => x.date == date.date);
      if(discount){
        date.discount = discount.percent;
      }

    });

    });
  }

get PageNumber() {
  return this.pageNumber;
}

get Peoples() {
  return this.peoples;
}

get Hours() {
  return this.hours;
}

get PageDates() {
  return this.pages;
  }

  public CheckHasReservation() {
    if(this._ssrcookies.check('ReservationData')) {
      this.reservationData = JSON.parse(this._ssrcookies.get('ReservationData'));

      if(this._ssrcookies.check('ReservationExpires')){
        let expires = new Date(this._ssrcookies.get('ReservationExpires'));
        let ActualTime = new Date();
        let seconds = Math.abs(ActualTime.getTime() - expires.getTime())/1000 > 0 ? Math.abs(ActualTime.getTime() - expires.getTime())/1000 : 0;
        let Expiration = new Promise((resolve) => setTimeout(() => resolve(true), seconds));

        async () => {
          const result = await Expiration;
          if(result){
            this._ssrcookies.delete('ReservationData');
            this._ssrcookies.delete('ReservationExpires');
            this.hasReserved = false;
          }

        }

      }

    } else {

      this.hasReserved = false;
      this.step1 = this.step2 = this.step3 = false;

    }
  }

ngOnInit() {
  this.is_darkmode = this._theme.mode;

  this._theme.mode$.subscribe((mode : boolean) => {
    this.is_darkmode = mode;
    this.calendar.destroy();
    this.RenderCalendar();
  });
  if (typeof window !== 'undefined'){

    if (window.screen.width <= 678) {
      this.is_mobile = true;
    }
    }

    this.Reservation['date'] = moment().format('YYYY-MM-DD');
//Booking Form
if(this.PublicationID) {
let today = this.CurrentDate.format('YYYY-MM-DD');
this._reservations.GetBookingForm(this.PublicationID, today).subscribe((response : any) => {
 if(response.status == 200){
  this.getDiscountForMonth(this.CurrentDate.format('YYYY-MM'));
  this.peoples = response.body.data.people;
  this.hours = response.body.data.hours;
  this.Reservation['num_guests'] = 2;
  this.Reservation['hour'] = this.hours[0].option;
  const startOfMonth = moment(this.CurrentDate).startOf('month').format('YYYY-MM-DD');
const endOfMonth  = moment(this.CurrentDate).endOf('month').format('YYYY-MM-DD');

this._reservations.GetDaysLocked(this.PublicationID, startOfMonth, endOfMonth ).subscribe((response : any) => {
  this.lockedDays = response.body.data;

  let lockedDays = this.lockedDays;
  this.AvailableDates = this.AvailableDates.filter(function(adate) {
   return !lockedDays.find(function(ldate : any) {
     return adate.date === ldate
   })
 })


 if(this.AvailableDates.length == 0){
  let currentDate = this.CurrentDate.clone().add(1, 'month');

  this.AvailableDates = this.getDaysArrayByMonth(currentDate);


  const startOfMonth = moment(currentDate).startOf('month').format('YYYY-MM-DD');
const endOfMonth  = moment(currentDate).endOf('month').format('YYYY-MM-DD');

this._reservations.GetDaysLocked(this.PublicationID, startOfMonth, endOfMonth ).subscribe((response : any) => {
  this.lockedDays = response.body.data;
  let lockedDays = this.lockedDays;
  this.AvailableDates = this.AvailableDates.filter(function(adate) {
    return !lockedDays.find(function(ldate : any) {
      return adate.date === ldate
    })
  })

  this.paginateCalendar();
});
}

 this.paginateCalendar();

 if(this.PageDates) {
  this.Reservation['date'] = this.PageDates[0][0].date;

 }




});

let lockedDays = this.lockedDays;



 this.RenderCalendar();


 this.calendar.on('click', (evt : any) => {
  if(evt.target.className == "next-button unit"){

    this.CurrentDate = new DateTime(this.CurrentDate).clone().add(1, 'month');
    let startOfMonth = moment(this.CurrentDate).startOf('month').format('YYYY-MM-DD');
let endOfMonth  = moment(this.CurrentDate).endOf('month').format('YYYY-MM-DD');
this.getDiscountForMonth(this.CurrentDate.format('YYYY-MM'));
    this._reservations.GetDaysLocked(this.PublicationID, startOfMonth, endOfMonth ).subscribe((response : any) => {
      this.lockedDays = response.body.data;

  lockedDays = this.lockedDays;
  const LockPlugin = this.calendar.PluginManager.getInstance('LockPlugin');

  LockPlugin.options.filter =  function (date : any , picked : any) {
    const fechas =  new DateTime(date).format('YYYY-MM-DD');

    return lockedDays.includes(fechas);

  }

  this.AvailableDates  = this.getDaysArrayByMonth(this.CurrentDate);
  this.AvailableDates = this.AvailableDates.filter(function(adate) {
    return !lockedDays.find(function(ldate : any) {
      return adate.date === ldate
    })
  })

  this.getDiscountForMonth(this.CurrentDate.format('YYYY-MM'));

  this.paginateCalendar();
  this.calendar.renderAll();


    });


  }


  if(evt.target.className == "previous-button unit"){
    this.CurrentDate =  new DateTime(this.CurrentDate).clone().clone().subtract(1, 'month');
    const startOfMonth = moment(this.CurrentDate).startOf('month').format('YYYY-MM-DD');
const endOfMonth  = moment(this.CurrentDate).endOf('month').format('YYYY-MM-DD');
this.getDiscountForMonth(this.CurrentDate.format('YYYY-MM'));
this._reservations.GetDaysLocked(this.PublicationID, startOfMonth, endOfMonth ).subscribe((response : any) => {
  this.lockedDays = response.body.data;
  this.peoples = response.body.data.people;
  this.hours = response.body.data.hours;
  this.Reservation['num_guests'] = 2;
  lockedDays = this.lockedDays;

  const LockPlugin = this.calendar.PluginManager.getInstance('LockPlugin');

  LockPlugin.options.filter =  function (date : any , picked : any) {
    const fechas =  new DateTime(date).format('YYYY-MM-DD');

    return lockedDays.includes(fechas);

  }

  this.AvailableDates  = this.getDaysArrayByMonth(this.CurrentDate);
  this.AvailableDates = this.AvailableDates.filter(function(adate) {
    return !lockedDays.find(function(ldate : any) {
      return adate.date === ldate
    })
  })
  this.getDiscountForMonth(this.CurrentDate.format('YYYY-MM'));
  this.paginateCalendar();
  this.calendar.renderAll();

    });


  }

 });



 }
},
 (error : any) => {

 }

);
}
this.selectedDay.subscribe((date : any) => {
  this.Reservation['date'] = date;
  this._reservations.GetBookingForm(this.PublicationID, date).subscribe((response : any) => {
    this.lockedDays = response.body.data.daysDisabled;
    this.peoples = response.body.data.people;
    this.hours = response.body.data.hours;
    this.Reservation['num_guests'] = 2;
    this.Reservation['hour'] = this.hours[0].option;

    let lockedDays = this.lockedDays;
    this.AvailableDates = this.AvailableDates.filter(function(adate) {
     return !lockedDays.find(function(ldate : any) {
       return adate.date === ldate
     })
   })

  });
});


}



}

  <div class="modal-header px-3 py-3">
    <h6 class="text-center">{{usuario.fullname}} como fue tu experiencia en {{reservation.publication.name}} </h6>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="dismiss()"></button>
  </div>
<div class="modal-body">
    <form novalidate [formGroup]="ReviewsForm" (ngSubmit)="SendReview()">
    <mat-stepper [linear]="true" #stepper>
      <mat-step [stepControl]="step_1">
      <div class="review-experience mb-3">
        <div class="text-center my-3">
          <p class="fw-bold">¡Califica tu experiencia! <br> <span class="text-primary">Reservación {{reservation.datetime_reservation | date : 'shortDate'}}</span></p>
          <div class="mb-3">
          <span class="me-2">Comida</span>
        <ngb-rating  [formControl]="food" [max]="5" (rateChange)="ValidateStep1()">
          <ng-template let-fill="fill" let-index="index">
            <i [ngClass]="{'fi-star': fill === 0, 'fi-star-half' : fill === 50, 'fi-star-filled' : fill === 100}" [class.low]="index < 3"></i>
          </ng-template>
        </ngb-rating>
        </div>
        <div class="mb-3">
          <span class="me-2">Servicio</span>
        <ngb-rating [formControl]="service" [max]="5" (rateChange)="ValidateStep1()">
          <ng-template let-fill="fill" let-index="index">
            <i [ngClass]="{'fi-star': fill === 0, 'fi-star-half' : fill === 50, 'fi-star-filled' : fill === 100}" [class.low]="index < 3"></i>
          </ng-template>
        </ngb-rating>
        </div>
        <div class="mb-3">
          <span class="me-2">Ambiente</span>
        <ngb-rating  [formControl]="ambience" [max]="5" (rateChange)="ValidateStep1()">
          <ng-template let-fill="fill" let-index="index">
            <i [ngClass]="{'fi-star': fill === 0, 'fi-star-half' : fill === 50, 'fi-star-filled' : fill === 100}" [class.low]="index < 3"></i>
          </ng-template>
        </ngb-rating>
        </div>
        <div class="mb-3">
          <span class="me-2">General</span>
        <ngb-rating  [formControl]="overall" [max]="5" (rateChange)="ValidateStep1()">
          <ng-template let-fill="fill" let-index="index">
            <i [ngClass]="{'fi-star': fill === 0, 'fi-star-half' : fill === 50, 'fi-star-filled' : fill === 100}" [class.low]="index < 3"></i>
          </ng-template>
        </ngb-rating>
        </div>
        <div class="mb-3">
          <span class="me-2">Ruido</span>
        <ngb-rating  [formControl]="noise" [max]="5" (rateChange)="ValidateStep1()">
          <ng-template let-fill="fill" let-index="index">
            <svg width="28" height="5" viewBox="0 0 28 5" class="pe-1" [ngClass]="{'normal': fill === 0, 'filled' : fill === 100}" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="index == 0">
              <rect width="28" height="5" rx="1" />
            </svg>
            <svg width="28" height="10" viewBox="0 0 28 10" class="pe-1" [ngClass]="{'normal': fill === 0, 'filled' : fill === 100}" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="index == 1">
              <rect width="28" height="10" rx="1"/>
              </svg>
             <svg width="28" height="15" viewBox="0 0 28 15" class="pe-1" [ngClass]="{'normal': fill === 0, 'filled' : fill === 100}" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="index == 2">
                <rect width="28" height="15" rx="1" />
                </svg>
            <svg width="28" height="20" viewBox="0 0 28 20" class="pe-1" [ngClass]="{'normal': fill === 0, 'filled' : fill === 100}" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="index == 3">
                  <rect width="28" height="20" rx="1" />
           </svg>
           <svg width="28" height="25" viewBox="0 0 28 25" class="pe-1" [ngClass]="{'normal': fill === 0, 'filled' : fill === 100}" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="index == 4">
            <rect width="28" height="25" rx="1" />
            </svg>


          </ng-template>
        </ngb-rating>
        </div>
        </div>
      </div>
      <div class="text-center mt-3">
      <button type="button" class="btn btn-primary rounded-pill" mat-button matStepperNext>Siguiente</button>
      </div>
      </mat-step>
      <mat-step [stepControl]="step_2">
        <div class="text-center">
          <h6>Escribe tu reseña</h6>
        <hr>
          <p class="pt-3">Ayuda a los comensales a decidir dónde comer. Recuerde que debe ser breve, simple y específico.</p>
        </div>

          <div class="review-experience mb-3">
            <label for="textarea-input" class="form-label text-primary"><i class="fi-help me-2"></i>¿Necesitas Ayuda?</label>
            <textarea class="form-control" formControlName="review" id="textarea-input" rows="5" (change)="ValidateStep2()"></textarea>
            <div class="d-flex justify-content-between">
              <span class="text-muted">Minimo 50 Caracteres</span>
              <span class="text-muted">0 / 2000 Caracteres</span>
            </div>
            <div class="my-3">
              <p class="fw-bold mb-2">¿Recomendarías Harry's Cancun Prime Steak a tus amigos?</p>
              <div class="d-flex">
                <div class="mx-2">
                <input type="radio" class="btn-check" id="si-option" name="is_recommended" [value]="true" formControlName="is_recommended" autocomplete="off">
<label class="btn btn-outline-primary btn-sm" for="si-option"><i class="fi-check-circle pe-2"></i>Sí</label>
</div>
<div class="mx-2">
<input type="radio" class="btn-check" id="no-option" formControlName="is_recommended" [value]="false" autocomplete="off" name="is_recommended">
<label class="btn btn-outline-primary btn-sm" for="no-option"><i class="fi-x pe-2"></i>No</label>
</div>
</div>
            </div>
          </div>

          <div class="text-center">
          <button type="button" class="btn btn-secondary rounded-pill mx-2" mat-button matStepperPrevious>Atrás</button>
          <button type="button" class="btn btn-primary rounded-pill mx-2" mat-button matStepperNext>Siguiente</button>
          </div>
        </mat-step>
      <mat-step [stepControl]="step_3" >
        <div class="text-center">
          <h6>¿Qué recomendarías de este restaurante?</h6>
        <hr>
          <p class="pt-3 text-primary">Seleccione todas las que aplican.</p>
        </div>
        <ngx-simplebar class="mb-3" style="max-height: 380px; overflow-x: hidden;" >
          <div class="row row-cols-3 gx-3 gy-3">
            <div class="col" *ngFor="let recommendation of recommendations">
              <input type="checkbox" class="btn-check" id="recommedation-{{recommendation.id}}" [value]="recommendation.id" (change)="setRecommendation($event)">
              <label class="btn btn-outline-primary btn-sm" for="recommedation-{{recommendation.id}}">{{recommendation.name}}</label>
            </div>
          </div>
        </ngx-simplebar>
        <div class="text-center mt-3">
          <button  type="button" class="btn btn-secondary rounded-pill mx-2" mat-button matStepperPrevious>Atrás</button>
          <button type="button" class="btn btn-primary rounded-pill mx-2" mat-button matStepperNext>Siguiente</button>
          </div>
      </mat-step>
      <mat-step [stepControl]="step_4">
        <app-alert [message]="message.text" [type]="message.type" [show]="message.show"></app-alert>
        <div class="text-center">
          <h6>¿Cual es tu nombre de usuario para tus reseñas?</h6>
        <hr>
          <p class="pt-3">Tu nombre de usuario se publicara en +mesa con la reseña que creaste.
            Por razones de privacidad no uses tu nombre completo o correo electrónico. </p>
        </div>
        <div class="mb-3">
          <label for="username" class="form-label">Nombre de usuario</label>
          <input type="text" id="username" class="form-control" formControlName="nickname" placeholder="Nombre de usuario">
        </div>

        <div class="my-3">
          <p class="fw-bold mb-2">¿Quieres enviar un mensaje privado a Harry's Cancun Prime Steak?</p>
          <div class="d-flex">
            <div class="mx-2">
            <input type="radio" class="btn-check" id="si-message" [(ngModel)]="pmessage" [ngModelOptions]="{standalone: true}" [value]="true" name="pmessage" autocomplete="off">
<label class="btn btn-outline-primary btn-sm" for="si-message"><i class="fi-check-circle pe-2"></i>Sí</label>
</div>
<div class="mx-2">
<input type="radio" class="btn-check" id="no-message" [(ngModel)]="pmessage" [ngModelOptions]="{standalone: true}"[value]="false" autocomplete="off" name="pmessage">
<label class="btn btn-outline-primary btn-sm" for="no-message"><i class="fi-x pe-2"></i>No</label>
</div>
</div>
</div>
<div class="mb-3" *ngIf="pmessage">
  <label for="message" class="form-label">Mensaje</label>
  <textarea class="form-control" formControlName="privatemessage" id="message" rows="3"></textarea>
</div>

        <div class="text-center mt-3">
          <button type="button" class="btn btn-secondary rounded-pill mx-2" mat-button matStepperPrevious>Atrás</button>
          <button type="submit" class="btn btn-primary rounded-pill mx-2" mat-button matStepperNext>Envíar</button>
          </div>
      </mat-step>
    <mat-step>
      <div class="text-center">
        <h6><i class="fi-check-circle text-success me-2"></i>Gracias por tu reseña</h6>
      <hr>
        <p class="pt-3">Compartir tus reseñas puede ayudar a otros. </p>
      </div>
      <div class="text-center mt-3">
        <button type="button" class="btn btn-primary">ok</button>
        </div>

  </mat-step>
    </mat-stepper>
    </form>
  </div>

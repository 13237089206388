<section id="account-header" [@openClose]="!isHidden ? 'open' : 'closed'">
  <div class="container boxed-md px-0" style="max-width: 1020px;">
    <app-breadcrumbs [breadcrumbItems]="breadCrumbItems" class="account-breadcrumbs text-light d-none d-sm-none d-md-block"></app-breadcrumbs>
    <div class="d-flex align-items-center justify-content-between pb-4 mb-2 contenedor-header-anfitrion">
      <div class="d-flex align-items-center">
        <div class="position-relative flex-shrink-0"><img class="brand-image rounded-circle border border-white" src="{{storage+Brand?.image}}" width="100" height="100" alt="{{Brand?.name}}">
          <button class="btn btn-icon btn-light btn-xs rounded-circle shadow-sm position-absolute end-0 bottom-0" type="button" placement="top" ngbTooltip="Change image" (click)="openModalEditBrand(editBrand)"><i class="fi-pencil fs-xs"></i></button>
        </div>
        <div class="ps-3 ps-sm-4">
          <p style="color: #ffffff; margin-bottom: 0px;">¡Bienvenido!</p>
          <h3 class="h4 mb-2">{{Brand?.name}}</h3><span class="star-rating"><i class="star-rating-icon fi-star-filled active"></i><i class="star-rating-icon fi-star-filled active"></i><i class="star-rating-icon fi-star-filled active"></i><i class="star-rating-icon fi-star-filled active"></i><i class="star-rating-icon fi-star-filled active"></i></span>
        </div>
      </div>
    </div>
    <button class="btn btn-outline-primary togglepanel d-none d-md-block" type="button" (click)="isHidden = !isHidden">Ocultar Panel</button>
  </div>
  <div class="rounded-border-separator">

  </div>
</section>
<div class="finger-actions">
<div class="finger-bar" (click)="isHidden = !isHidden" (pandown)="openPanel()" (panup)="closePanel()">
  </div>
</div>


<ng-template #editBrand let-offcanvas>
  <div class="offcanvas-header" *transloco="let t; scope: 'account'">
    <h5 class="offcanvas-title">{{t('member.edita_tu_marca')}}</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>

  </div>
  <div class="offcanvas-body" *transloco="let t; scope: 'member'">
    <form novalidate (ngSubmit)="submitEditBrand()" [formGroup]="EditBrandForm">
      <div class="mb-3">
        <label class="form-label" for="brand-name">{{t('member.nombre_de_la_marca')}}</label>
        <input class="form-control" type="text" id="brand-name" formControlName="name">
      </div>
      <div class="mb-3">
        <label class="form-label" for="brand-description">{{t('member.descripcion_de_la_marca')}}</label>
        <dropzone [config]="dropzoneconfig" (success)="onUploadSuccess($event)"></dropzone>
      </div>
      <div class="position-relative mt-3">
      <button type="submit" class="btn btn-primary">{{t('member.guardar_cambios')}}</button>
        </div>
    </form>
    </div>

  </ng-template>

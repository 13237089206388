import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DateTime, easepick, LockPlugin } from '@easepick/bundle';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Observable, map, startWith } from 'rxjs';
import { SearchService } from 'src/app/core/services/search.service';
import { environment } from 'src/environments/environment';
import { Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-check-availability-horizontal',
  templateUrl: './check-availability-horizontal.component.html',
})
export class CheckAvailabilityHorizontalComponent implements OnInit, AfterViewInit {
  term : string = '';
  @Input() is_mini : boolean = false;
  @Input() is_mobile_mini : boolean = false;
  BaseURl : string = environment.baseUrl
  suggestions : any = [];
  suggestionsMini : any = [];
  private recentSearch : any = [];
  private calendar : any;
  mobile = false;
  private lang : string = 'es-ES';
  myControl = new FormControl('');
  myControlMini = new FormControl('');
  filteredSuggest : Observable<any> | undefined;
  filterData : any = [];
  @Output() onAutocompleteSelect = new EventEmitter<string>();
  @ViewChild('datepicker_calendar', {static: false}) datepicker! : any;
  @ViewChild('datepicker_calendar_mini', {static: false}) datepicker_mini! : any;
  AvailabilityQuery : any = {
    date : moment().format('YYYY-MM-DD'),
    guests : 2,
};

constructor(private _search : SearchService, private _route : Router,
  private _activatedroute : ActivatedRoute, private _modal : NgbModal) {

    if(moment().hours() < 10) {
      this.AvailabilityQuery.hour = '0'+moment().hours()+':00';
    } else
    {
      this.AvailabilityQuery.hour = moment().hours()+':00';
    }
  }



  get Suggestions(){
    return this.suggestions;
  }
  /**
   * Get 24 Hours of an day
   */
  public getdayHours(){
    let hours = [];
    for(let i = 0; i < 24; i++){
      let hour;
      if(i < 10){
        hour = "0" + i + ":00";

      }else{
        hour = i + ":00";
      }

      const key = moment(hour, 'HH:mm:ss').format('hh:mm:ss');
      const value = moment(hour, 'HH:mm:ss').format('hh:mm:ss');

      hours.push({normal : key, formated : value})

    }

    return hours;

  }

  /**
  * Add a new search to the recent searches
  */
 public AddRecentSearch() {

  let term = this.myControl.value;
  if(term){
  this._search.AddRecentSearch(term);
  }

  }

  get RecentSearch() {
    return this.recentSearch;
  }

search(){
  this._search.GetSuggestedItems(this.term).subscribe((response:any) => {
      if(response.success){
      this.suggestions = response.data;
      }

    });

}


  public FindSuggestions($event : any) {

    if($event.target.value.length > 3){
    this._search.GetSuggestedItems($event.target.value).subscribe((response:any) => {

      this.suggestions = response.data;

    })
  }

   }

   public SearchPublications(){
    this._modal.dismissAll();
    this.AvailabilityQuery.date = moment(this.AvailabilityQuery.date).format('YYYY-MM-DD');
    this.AddRecentSearch();



    if(this.myControl.value !== '') {
    this.AvailabilityQuery.qsf = this.myControl.value;
    }

    if(this.myControlMini.value !== '') {
      this.AvailabilityQuery.qsf = this.myControlMini.value;
      }

    if(this._route.url.includes('/publications/map')){
      this._route.navigate(['/publications/map'], { queryParams: this.AvailabilityQuery });
    } else{
    this._route.navigate(['/publications/grid'], { state: { filters: this.filterData }, queryParams: this.AvailabilityQuery });
    }

  }

public DisplayCalendar() {
  this.calendar = new easepick.create({
    element: this.datepicker.nativeElement,
    css: [
      `${this.BaseURl}/assets/css/calendar_final.css`,
      `${this.BaseURl}/assets/css/calendar_lock.css`,
    ],
    zIndex: 9999,
    lang: this.lang,
    autoApply: true,
    plugins: [LockPlugin],
    LockPlugin: {
      minDate: new Date(),

    },

  });

  this.calendar.on('select', (evt: any) => {
    const { view, date, target } = evt.detail;

    this.AvailabilityQuery.date = moment(date).format('YYYY-MM-DD');

  });
}

public DisplayCalendarMini() {
  this.calendar = new easepick.create({
    element: this.datepicker_mini.nativeElement,
    css: [
      `${this.BaseURl}/assets/css/calendar_final.css`,
      `${this.BaseURl}/assets/css/calendar_lock.css`,
    ],
    zIndex: 9999,
    lang: this.lang,
    autoApply: true,
    plugins: [LockPlugin],
    LockPlugin: {
      minDate: new Date(),

    },

  });

  this.calendar.on('select', (evt: any) => {
    const { view, date, target } = evt.detail;

    this.AvailabilityQuery.date = moment(date).format('YYYY-MM-DD');

  });
}

setFilter(event : any) {
 this.myControl.setValue('');
this.filterData = [];
this.AvailabilityQuery.date = moment(this.AvailabilityQuery.date).format('YYYY-MM-DD');

if(event.option.value.type == 'location') {
this.filterData['type'] = 'location';
this.filterData['country'] = event.option.value.id_country ? Number(event.option.value.id_country) : "";
this.filterData['state'] = event.option.value.id_state ? Number(event.option.value.id_state) :"";
this.filterData['city'] = event.option.value.id_city ? Number(event.option.value.id_city) : "";
this.filterData['district'] = event.option.value.id_district ? Number(event.option.value.id_district) : "";
}

if(event.option.value.type == 'publication') {
  this.filterData['type'] = 'publication';
  this.AvailabilityQuery.qsf = event.option.value.name;
  this.myControl.setValue(event.option.value.name);
}

if(event.option.value.type == 'cuisine') {
  this.filterData['type'] = 'cuisine';
this.filterData['cuisine_id'] = Number(event.option.value.id);

}


this.onAutocompleteSelect.emit(this.filterData);


if(this._route.url.includes('/publications/map')){
  this._route.navigate(['/publications/map'], { queryParams: this.AvailabilityQuery });
} else{
this._route.navigate(['/publications/grid'], { state: { filters: this.filterData }, queryParams: this.AvailabilityQuery });
}


}
  ngOnInit(): void {

     this.myControl.valueChanges.subscribe((value : any) => {
      if(value.length > 3){

   this._search.GetSuggestedItems(value).subscribe((response:any) => {
      if(response.success){


        if(response.data.location){

          response.data.location.forEach((location : any) => {
            location.type = 'location';
            if(location.name_district !== '') {
              location.name = location.name_district;
              return;
            }

            if(location.name_district == '' && location.name_city !== '') {
              location.name = location.name_city;
              return;
            }

            if(location.name_city == '' && location.name_state !== '') {
              location.name = location.name_state;
              return;
            }

          });
        }

        if(response.data.publications){
          response.data.publications.forEach((publication : any) => {
            publication.type = 'publication';
          });
        }

        if(response.data.cuisine){
          response.data.cuisine.forEach((cuisine : any) => {
            cuisine.type = 'cuisine';
          });
        }

      this.suggestions =
         [
          {
            group: 'Ubicaciones',
            items: (response.data.location ? response.data.location : [])
          },
          {
          group: 'Publicaciones',
          items:  (response.data.publications ? response.data.publications : [])
        },
        {
          group: 'Cuisine',
          items: (response.data.cuisine ? response.data.cuisine : [])
      }]
    }
  });


}


  });

this._activatedroute.queryParams.subscribe((params : any) => {
  if(Object.keys(params).length > 0){
    this.AvailabilityQuery = {
      date : params.date,
      hour : params.hour,
      guests : params.guests,
    }
  }
});

if(typeof window !== 'undefined' && window.screen.width < 680) {
  // 768px portrait
    this.mobile = true;

  }


  }

  ngAfterViewInit(): void {

    if(typeof window !== 'undefined'){
      if(this.datepicker){
       this.DisplayCalendar();
      }
      if(this.datepicker_mini){
        this.DisplayCalendarMini();
      }
      }
  }


}



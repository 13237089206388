import { Component, Input } from '@angular/core';

@Component({
  selector: 'timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss']
})
export class TimepickerComponent {
  @Input() id : string = '';
  @Input() name : string = '';
  @Input() class : string = '';
  @Input() disabled? : string  | null = null;

}

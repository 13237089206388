  <div class="modal-body">
    <!-- Primary border spinner -->
    <div class="position-relative" style="min-height: 265px;" *ngIf="is_loading; else content;">
      <div class="position-absolute top-50 start-50 translate-middle">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
      <ng-template #content>
    <div class="text-center py-5" *ngIf="chatMessages.length == 0" >
        <img src="assets/img/empty-chat.svg" alt="empty chat">
        <h6 class="mb-0" *transloco="let t; scope: 'account'">{{t('account.enviar_mensaje_al_restaurante')}}</h6>
        <p *transloco="let t; scope: 'account'">{{t('account.el_staff_respondera')}}</p>
    </div>
    <ngx-simplebar style="height: 350px" #ChatSimplebar class="location-nav message-container" [ngClass]="chatMessages.length > 0 ? 'd-block' : ''" *ngIf="chatMessages.length > 0">
    <div id="message-list" class="messages-list">
        <div class="message" #msj [ngClass]="message.sender_id == usuario.id ? 'user-message' : 'restaurant-message'" *ngFor="let message of chatMessages; let i = index;">
            <p>{{message.message}}</p>
        </div>
      </div>
    </ngx-simplebar>
    </ng-template>
  </div>
  <div class="modal-footer">
    <form [formGroup]="chatForm" class="w-100" (ngSubmit)="SendMessage()">
    <div class="input-group">
      <input class="form-control" type="text" formControlName="message">
      <div class="input-group-text p-0">
        <button class="btn d-block text-body px-3 py-2" [attr.disabled]="is_loading ? true : null">
          <i class="fi-send mt-n1 text-primary"></i>
        </button>
      </div>
    </div>
    </form>
  </div>

import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { PublicationsService } from 'src/app/core/services/publications.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent implements OnInit {
  users_favorite_publications : any = [];
  constructor( private _user : UserService, private _auth : AuthService) {

  }
  @Input()
  publication_id : number = -1;
  favorite = false;

  SetFavoriteStatus(status : any) {
    if(!this._auth.isloggedin){

    }
    let favstatus = status ? 1 : 0;
this._user.SetFavoriteStatus(this.publication_id, favstatus).subscribe((resp : any) => {
  this.favorite = status;
  this._auth.refreshUserData();
})
  }

  ngOnInit() {
 let favorites = this._auth.FavoritesPublications;


  if(favorites.length > 0){
    if(favorites.indexOf(this.publication_id) > -1){
      this.favorite = true;
    }

  }
}

}

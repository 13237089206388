import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExperiencesService {
private ApiExperiencias = environment.ApiPublicaciones;
  constructor(private _http : HttpClient) { }

  /**
   * Add experience to publication
   * @param data
   * @param pub_id
   * @returns
   */
  public addExperience(data : any, pub_id : number) {
    const url = `${this.ApiExperiencias}/admins/publication/${pub_id}/experience`;
    return this._http.post(url, data);
  }

  /**
   * Find Anfitrion experiences
   * @param queryParams
   * @returns
   */

  public findAnfitrionExperiences(queryParams?: any) {
      const url = `${this.ApiExperiencias}/admins/experiences`;
    return this._http.get(url, { params: queryParams });
  }

  /**
   * Change status experience
   * @param id
   * @param status
   * @returns
   */
  public changesStatusExperience(id : number, status : boolean, publication_id : number) {
    const url = `${this.ApiExperiencias}/admins/publication/${publication_id}/experience/${id}/status`;
    return this._http.patch(url, {'status': status});
  }

  /**
   * Get one experience by id
   * @param id
   * @returns
   */
  public getExperience(id : any) {
    const url = `${this.ApiExperiencias}/experience/${id}`;
    return this._http.get(url);
  }

  /**
   * Update Experience
   * @param publication_id
   * @param experience_id
   * @param data
   * @returns
   */
  public updateExperience(publication_id : number, experience_id : number, data : any) {
    const url = `${this.ApiExperiencias}/admins/publication/${publication_id}/experience/${experience_id}`;
    return this._http.patch(url, data);

  }

  /**
   * Get Experiences by publication id
   * @param publication_id
   * @returns Observable
   */
 public getExperienceByPublication(publication_id : number) {
    const url = `${this.ApiExperiencias}/publication/${publication_id}/experiences`;
    return this._http.get(url);
  }

  /**
   * List all Public Experiences
   * @returns
   */
  public ListPublicExperience(queryParams?: any) {
    const url = `${this.ApiExperiencias}/experiences/actives`;
    return this._http.get(url, { params: queryParams });
  }

  /**
   * Get Exclusive dates for experiences by publication id, if exist Exclusive experiences in publication
   * @param Publication_id
   * @returns Observable
   */

  public GetExclusiveDates(Publication_id : any) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `${this.ApiExperiencias}/admins/publication/${Publication_id}/experiences/exclusives/dates`;
    return this._http.get(url, { params: { 'timezone': timezone } });
  }

/**
 * Change order experiences
 * @param ExperiencesArray
 * @param Publication_id
 * @returns Observable
 */

  public ChangeOrderExperiences(Experiences : any, Publication_id : any) {
    const url = `${this.ApiExperiencias}/admins/publication/${Publication_id}/experiences/order`;
    return this._http.patch(url, Experiences);
  }

  /**
   * Get Experiences for Calendar for Discount Rate
   * @param queryParams
   * @param Publication_id
   * @returns Observable
   */

  public GetExperiencesForCalendarDiscountRate(Publication_id : any, queryParams : any) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    queryParams.timezone = timezone;
    const url = `${this.ApiExperiencias}/publication/${Publication_id}/experiences/calendar-discount`;
    return this._http.get(url, { params: queryParams });
  }

  /**
   * Get Experiences for date and hour
   * @param Publication_id
   * @param queryParams
   * @returns Observable
   */

  public GetExperiencesForDateAndHour(queryParams : any) {

    const url = `${this.ApiExperiencias}/experiences`;
    return this._http.get(url, { params: queryParams });
  }

  /**
   * Get Addons for Experience
   * @param Experience_id
   * @returns Observable
   */

  public GetAddonsForExperience(Experience_id : any) {
    const url = `${this.ApiExperiencias}/experience/${Experience_id}/addons`;
    return this._http.get(url);
  }

  /**
   * ChangeAddonQuantity
   * @param Experience_id
   * @param Addon_id
   * @param Quantity
   * @param reservationCode
   * return Observable
   */

  public ChangeAddonQuantity(Experience_id : any, Addon_id : any, Quantity : any, reservationCode : any) {
      const url = `${this.ApiExperiencias}/experience/${Experience_id}/addon/${Addon_id}`;
      return this._http.post(url, { 'qty': Quantity, 'reservationId': reservationCode });

  }

  /**
   * Create Experience reservation
   * @param Experience_id
   */

  public async CreateExperienceReservation(Experience_id : any, data : any)  {
    const url = `${this.ApiExperiencias}/experience/${Experience_id}`;
   let response =  this._http.post(url, data);
   return await lastValueFrom(response);
  }



}

<ng-container *ngIf="!is_mini">
<div class="d-none d-md-flex position-relative justify-content-center rounded-md-pill mb-2 mb-sm-4 mb-lg-2" *ngIf="mobile !== true" >
  <div class="home-search form-group d-block rounded-md-pill mb-3 mx-md-2 w-md-50">
    <div class="row g-0 ms-sm-n2">
      <div class="d-sm-flex align-items-center">
        <div class="input-group border-end-sm me-1">
            <i class="fi-calendar position-absolute top-50 start-0 translate-middle-y ms-3"></i>
            <input class="form-control date-picker-search rounded ps-5" [(ngModel)]="AvailabilityQuery.date" type="text" #datepicker_calendar>
        </div>
        <hr class="d-sm-none my-2">
        <div class="input-group border-end-sm me-1" style="color: #000;">
            <i class="fi-clock position-absolute top-50 start-0 translate-middle-y ms-3" style=" font-size: 20px;"></i>
            <select class="form-control ps-5" [(ngModel)]="AvailabilityQuery.hour">
              <option value="00:00">12:00 AM</option>
              <option value="01:00">01:00 AM</option>
              <option value="02:00">02:00 AM</option>
              <option value="03:00">03:00 AM</option>
              <option value="04:00">04:00 AM</option>
              <option value="05:00">05:00 AM</option>
              <option value="06:00">06:00 AM</option>
              <option value="07:00">07:00 AM</option>
              <option value="08:00">08:00 AM</option>
              <option value="09:00">09:00 AM</option>
              <option value="10:00">10:00 AM</option>
              <option value="11:00">11:00 AM</option>
              <option value="12:00">12:00 PM</option>
              <option value="13:00">01:00 PM</option>
              <option value="14:00">02:00 PM</option>
              <option value="15:00">03:00 PM</option>
              <option value="16:00">04:00 PM</option>
              <option value="17:00">05:00 PM</option>
              <option value="18:00">06:00 PM</option>
              <option value="19:00">07:00 PM</option>
              <option value="20:00">08:00 PM</option>
              <option value="21:00">09:00 PM</option>
              <option value="22:00">10:00 PM</option>
              <option value="23:00">11:00 PM</option>
            </select>
        </div>
        <hr class="d-sm-none my-2">
        <div class="input-group ps-4">
          <i class="fi-user position-absolute  top-50 start-0 translate-middle-y ms-3"></i>
              <select class="form-select" id="select-input" [(ngModel)]="AvailabilityQuery.guests" *transloco="let t; scope: 'home'" style="padding-right: 0;
              margin-right: -21px;">
                <option value="1">1 {{t('persona')}}</option>
                <option value="2" selected>2 {{t('personas')}}</option>
                <option value="3">3 {{t('personas')}}</option>
                <option value="4">4 {{t('personas')}}</option>
                <option value="5">5 {{t('personas')}}</option>
                <option value="6">6 {{t('personas')}}</option>
                <option value="7">7 {{t('personas')}}</option>
                <option value="8">8 {{t('personas')}}</option>
                <option value="9">9 {{t('personas')}}</option>
                <option value="10">10 {{t('personas')}}</option>
              </select>
      </div>
    </div>
  </div>
  </div>
  <div class="home-seach-input form-group d-block rounded-md-pill mb-3 mx-md-2">
    <div class="input-group">
      <span class="input-group-text text-muted">
        <i class="fi-search"></i>
      </span>
      <input class="form-control border-start-0 rounded" type="text"   [formControl]="myControl" [matAutocomplete]="autoGroup"   *transloco="let t" placeholder="Ubicación, restaurante, cocina.">

      <mat-autocomplete #autoGroup="matAutocomplete" (optionSelected)="setFilter($event)">
        <ng-container *ngFor="let group of suggestions">
      <mat-optgroup *ngIf="group.items.length > 0" [label]="group.group">
        <mat-option *ngFor="let item of group.items" [value]="item">
          {{item.name}}
        </mat-option>
    </mat-optgroup>
      </ng-container>
    </mat-autocomplete>
    </div>
  </div>

    <button class="btn btn-primary btn-lg rounded-pill w-100 w-md-auto mb-3 ms-sm-3"  type="button" (click)="SearchPublications()">Buscar</button>

</div>

<div class="d-md-none form-group rounded-pill row mb-3 mx-1" *ngIf="mobile == true" style="padding: 0.2rem;">
  <div class="col input-group" (click)="datepicker_calendar.click()">
    <i class="fi-calendar position-absolute top-50 start-0 translate-middle-y ms-3" style="color: #000; padding-left: 8px;"></i>
    <input class="form-control date-picker-search rounded " [(ngModel)]="AvailabilityQuery.date" type="text" #datepicker_calendar style="padding-left: 2.3rem;">
    <i class="fi-chevron-down position-absolute top-50 end-0 translate-middle-y ms-3" style="color: #A39FB0;padding-right: 5px; font-size: 13px;"></i>
  </div>
  <div class="col input-group">
      <hr style="width: 1px; height: 42px;">
      <i class="fi-clock position-absolute top-50 start-0 translate-middle-y ms-3" style="color: #000; padding-left: 10px;"></i>
      <select class="form-control" style="z-index: 99; background-image: none;">
        <option value="00:00">12:00 AM</option>
        <option value="01:00">01:00 AM</option>
        <option value="02:00">02:00 AM</option>
        <option value="03:00">03:00 AM</option>
        <option value="04:00">04:00 AM</option>
        <option value="05:00">05:00 AM</option>
        <option value="06:00">06:00 AM</option>
        <option value="07:00">07:00 AM</option>
        <option value="08:00">08:00 AM</option>
        <option value="09:00">09:00 AM</option>
        <option value="10:00">10:00 AM</option>
        <option value="11:00">11:00 AM</option>
        <option value="12:00">12:00 PM</option>
        <option value="13:00">01:00 PM</option>
        <option value="14:00">02:00 PM</option>
        <option value="15:00">03:00 PM</option>
        <option value="16:00">04:00 PM</option>
        <option value="17:00">05:00 PM</option>
        <option value="18:00">06:00 PM</option>
        <option value="19:00">07:00 PM</option>
        <option value="20:00">08:00 PM</option>
        <option value="21:00">09:00 PM</option>
        <option value="22:00">10:00 PM</option>
        <option value="23:00">11:00 PM</option>
      </select>
      <i class="fi-chevron-down position-absolute top-50 end-0 translate-middle-y ms-3" style="color: #A39FB0;padding-right: 12px; font-size: 13px; z-index: 2"></i>
  </div>

</div>

<div class="d-md-none form-group rounded-pill row mb-3 mx-1" style="padding: 0.2rem;">
  <div class="input-group ps-4">
    <i class="fi-user position-absolute  top-50 start-0 translate-middle-y ms-3" style="padding-left: 8px;"></i>
        <select class="form-select" id="select-input" [(ngModel)]="AvailabilityQuery.guests" *transloco="let t; scope: 'home'" style="padding-right: 0;
        margin-right: -21px; padding-left: 1.5rem; background-image: none !important;">
          <option value="1">1 {{t('persona')}}</option>
          <option value="2" selected>2 {{t('personas')}}</option>
          <option value="3">3 {{t('personas')}}</option>
          <option value="4">4 {{t('personas')}}</option>
          <option value="5">5 {{t('personas')}}</option>
          <option value="6">6 {{t('personas')}}</option>
          <option value="7">7 {{t('personas')}}</option>
          <option value="8">8 {{t('personas')}}</option>
          <option value="9">9 {{t('personas')}}</option>
          <option value="10">10 {{t('personas')}}</option>
        </select>
        <i class="fi-chevron-down position-absolute top-50 end-0 translate-middle-y ms-3" style="color: #A39FB0;padding-right: 12px; font-size: 13px; z-index: 2"></i>
</div>

</div>

<div class="d-md-none form-group rounded-pill row mb-3 mx-1" style="padding: 0.2rem;">
  <div class="input-group">
    <span class="input-group-text text-muted">
      <i class="fi-search" style="color: #000; margin-left: -8px;"></i>
    </span>
    <input class="form-control border-start-0 rounded" type="text"   [formControl]="myControl" [matAutocomplete]="autoGroup" *transloco="let t" placeholder="Ubicación, restaurante, cocina.">

    <mat-autocomplete #autoGroup="matAutocomplete" (optionSelected)="setFilter($event)">
      <ng-container *ngFor="let group of suggestions">
    <mat-optgroup *ngIf="group.items.length > 0" [label]="group.group">
      <mat-option *ngFor="let item of group.items" [value]="item">
        {{item.name}}
      </mat-option>
  </mat-optgroup>
    </ng-container>
  </mat-autocomplete>
  </div>
</div>
<div class="d-flex justify-content-center">
<button class="btn btn-primary  rounded-pill  mx-auto" style="padding-left: 50px; padding-right: 50px;" type="button" (click)="SearchPublications()" *ngIf="mobile == true"><span *transloco="let t">{{t('buscar_mesa')}}</span></button>
</div>
</ng-container>

<ng-container *ngIf="is_mini">
<div id="reservation-horizontal-widget" class="d-block d-md-flex  position-relative"  *transloco="let t">
  <div class="form-group d-block">
    <div class="row g-0 ms-sm-n2">
      <div class="asd d-sm-flex align-items-center">
        <div class="input-group border-end-sm me-1">
            <i class="fi-calendar position-absolute top-50 start-0 translate-middle-y ms-3"></i>
            <input type="text" class="form-control form-control-sm"  [(ngModel)]="AvailabilityQuery.date" type="text" #datepicker_calendar_mini>
        </div>
        <hr class="d-sm-none my-2">
        <div class="input-group border-end-sm me-1">
            <i class="fi-clock position-absolute top-50 start-0 translate-middle-y ms-3"></i>
            <select class="form-control ps-5" [(ngModel)]="AvailabilityQuery.hour">
              <option value="00:00">12:00 AM</option>
              <option value="01:00">01:00 AM</option>
              <option value="02:00">02:00 AM</option>
              <option value="03:00">03:00 AM</option>
              <option value="04:00">04:00 AM</option>
              <option value="05:00">05:00 AM</option>
              <option value="06:00">06:00 AM</option>
              <option value="07:00">07:00 AM</option>
              <option value="08:00">08:00 AM</option>
              <option value="09:00">09:00 AM</option>
              <option value="10:00">10:00 AM</option>
              <option value="11:00">11:00 AM</option>
              <option value="12:00">12:00 PM</option>
              <option value="13:00">01:00 PM</option>
              <option value="14:00">02:00 PM</option>
              <option value="15:00">03:00 PM</option>
              <option value="16:00">04:00 PM</option>
              <option value="17:00">05:00 PM</option>
              <option value="18:00">06:00 PM</option>
              <option value="19:00">07:00 PM</option>
              <option value="20:00">08:00 PM</option>
              <option value="21:00">09:00 PM</option>
              <option value="22:00">10:00 PM</option>
              <option value="23:00">11:00 PM</option>
            </select>
        </div>
        <hr class="d-sm-none my-2">
        <div class="input-group border-end-sm">
          <i class="fi-user position-absolute  top-50 start-0 translate-middle-y ps-1" style="padding-left: 8px;"></i>
          <select class="form-select ms-1" id="select-input" [(ngModel)]="AvailabilityQuery.guests" *transloco="let t; scope: 'home'" style="padding-right: 0;
          margin-right: -21px; padding-left: 1.5rem; background-image: none !important;">
            <option value="1">1 {{t('persona')}}</option>
            <option value="2" selected>2 {{t('personas')}}</option>
            <option value="3">3 {{t('personas')}}</option>
            <option value="4">4 {{t('personas')}}</option>
            <option value="5">5 {{t('personas')}}</option>
            <option value="6">6 {{t('personas')}}</option>
            <option value="7">7 {{t('personas')}}</option>
            <option value="8">8 {{t('personas')}}</option>
            <option value="9">9 {{t('personas')}}</option>
            <option value="10">10 {{t('personas')}}</option>
          </select>
      </div>
      <hr class="d-sm-none my-2">
      <div class="input-group">
        <span class="input-group-text text-muted">
          <i class="fi-search"></i>
        </span>

         <input class="form-control border-start-0 rounded" type="text"   [formControl]="myControl"  *transloco="let t" placeholder="Ubicación, restaurante, cocina.">


      </div>
     <div class="input-group">
      <button class="btn btn-primary btn-sm rounded-pill w-100 w-md-auto ms-sm-3" type="button" (click)="SearchPublications()">{{t('buscar_mesa')}}</button>
    </div>
    </div>
  </div>
  </div>
</div>
</ng-container>






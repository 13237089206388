import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DateTime, LockPlugin, easepick } from '@easepick/bundle';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { MessagesService } from 'src/app/core/services/messages.service';
import { ReservationsService } from 'src/app/core/services/reservations.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-single-reservation',
  templateUrl: './single-reservation.component.html',
  styleUrls: ['./single-reservation.component.scss']
})
export class SingleReservationComponent implements OnInit, AfterViewInit{
  @Input()
  Reservation: any;
  @ViewChild('datepicker', { static: false }) datepicker!: ElementRef;
  BaseURl = environment.baseUrl;
  calendar!: any;
  AvailableDates: any[] = [];
  lockedDays: any[] = [];
  HourList: any[] = [];
  PeopleList: any[] = [];
  availability : any = {};
  turnos : any = {};
  zones : any = [];
  is_loading: boolean = false;
  is_submit : boolean = false;
  @Output() onReserve = new EventEmitter<any>();
  constructor(private _reservations : ReservationsService, private _formBuilder : FormBuilder,
    private _ngbModal : NgbModal, private _message : MessagesService) { }





  ngOnInit(): void {

    this.availability.date = new DateTime().format('YYYY-MM-DD');
    this._reservations.GetBookingForm(this.Reservation.publication.id, this.availability.date).subscribe((res : any) => {
      this.HourList = res.body.data.hours;
      this.PeopleList = res.body.data.people
      this.availability.hour = this.HourList[0].option;
      this.availability.num_guests = this.PeopleList[0].value;
      this.is_loading = false;
   });



  }

  CheckAvailability() {
    this.is_loading = true;
    this._reservations.CheckAvailability(this.Reservation.publication.id, this.availability).subscribe((res : any) => {
      this.turnos = res.body.data;
      this.is_loading = false;
      this.is_submit = true;
    });
  }

  CheckZones(turno : any) {
    this.availability.index = turno.index;
    this.availability.hour = turno.hour;
    if(turno.form.zone_ids.length > 0){
   this.zones = turno.form.zone_ids;
    } else {
      this.zones = [];
    }
  }

  UpdateReservation() {
      this.is_loading = true;
    this._reservations.UpdateReservation(this.Reservation.code, this.availability).subscribe((res : any) => {
      if(res.status == 200){
        this.is_loading = false;
        this._ngbModal.dismissAll();
        this._message.show('Reservación Modificada', 'Se ha modificado su reservación correctamente','success');
      }
    });
  }
  ngAfterViewInit() {
    this._reservations.GetDaysLocked(this.Reservation.publication.id, this.availability.date, moment().endOf('month').format('YYYY-MM-DD')).subscribe((res : any) => {
      this.lockedDays = res.body.data;
      const dates = this.AvailableDates;
      let lockedDays = this.lockedDays;

      let resdate : string = new Date().toDateString();
      const today = new Date();
      const prices : any = {};
      this.calendar = new easepick.create({
        element: this.datepicker.nativeElement,
        css: [
          `${this.BaseURl}/assets/css/easepick.css`,
          `${this.BaseURl}/assets/css/lockplugin.css`,
        ],
        setup(picker) {
          dates.forEach(x => {
            prices[x.date] = x.discount;
          });

          // add price to day element
          picker.on('view', (evt) => {
            const { view, date, target } = evt.detail;
            const d = date ? date.format('YYYY-MM-DD') : null;

            if (view === 'CalendarDay' && prices[d]) {
              const span = target.querySelector('.day-discount') || document.createElement('span');
              span.className = 'day-discount';
              span.innerHTML = `${prices[d]} %`;
              target.append(span);
            }
          });


        },
        plugins: [LockPlugin],
        LockPlugin: {
          minDate: new Date(),
          filter(date , picked) {
            const fechas =  new DateTime(date).format('YYYY-MM-DD');
            return lockedDays.includes(fechas);
          },
        },

      });



      this.calendar.on('select', (evt : any) => {
        const { date } = evt.detail;
        const d = date ? date.format('YYYY-MM-DD') : null;
        this.availability.date = d;

      });

      let currentdate = new DateTime();

     this.calendar.on('click', (evt : any) => {

      if(evt.target.className == "next-button unit"){

     currentdate = currentdate.clone().add(1, 'month');

      this._reservations.GetDaysLocked(this.Reservation.publication.id, moment(currentdate).startOf('month').format('YYYY-MM-DD'), moment(currentdate).endOf('month').format('YYYY-MM-DD')).subscribe((res : any) => {
        this.lockedDays = res.body.data;
        const LockPlugin = this.calendar.PluginManager.getInstance('LockPlugin');
      const lockedDays = this.lockedDays;
  LockPlugin.options.filter =  function (date : any ) {
    const fechas =  new DateTime(date).format('YYYY-MM-DD');

    return lockedDays.includes(fechas);

  }

        this.calendar.renderAll();
      });
      }

      if(evt.target.className == "previous-button unit"){

        currentdate = currentdate.clone().subtract(1, 'month');

         this._reservations.GetDaysLocked(this.Reservation.publication.id, moment(currentdate).startOf('month').format('YYYY-MM-DD'), moment(currentdate).endOf('month').format('YYYY-MM-DD')).subscribe((res : any) => {
           this.lockedDays = res.body.data;
           const LockPlugin = this.calendar.PluginManager.getInstance('LockPlugin');
         const lockedDays = this.lockedDays;
     LockPlugin.options.filter =  function (date : any ) {
       const fechas =  new DateTime(date).format('YYYY-MM-DD');

       return lockedDays.includes(fechas);

     }

           this.calendar.renderAll();
         });
         }

     });

     });









  }





}

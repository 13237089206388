import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Flat Picker


// Page Routing
import { PagesRoutingModule } from './pages-routing.module';
import { SharedModule } from "../shared/shared.module";

// Light Box
import { LightboxModule } from 'ngx-lightbox';
import { TranslocoModule } from '@ngneat/transloco';
import { MatAutocompleteModule, MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY } from '@angular/material/autocomplete';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MAT_SELECT_SCROLL_STRATEGY_PROVIDER } from '@angular/material/select';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY } from '@angular/material/autocomplete';
import { Overlay } from '@angular/cdk/overlay';
import { ContentLoaderModule } from '@ngneat/content-loader';

@NgModule({
  declarations: [

  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    TranslocoModule,
    ContentLoaderModule,
    ScrollingModule,
    SharedModule,
    LightboxModule
  ],
  providers: [
    {
      provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
      useFactory: MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY,
      deps: [Overlay],
     },
  ],
})
export class PagesModule { }

import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ReviewsService {


  private apiURL = environment.ApiReviews;
  constructor(private _http : HttpClient) { }


  /**
   * Get reviews by publication id
   * @param id publication id
   * @param body
   * @returns Observable
   */

  getReviewsByPublicationId(id: number, params : any) {
    return this._http.get(`${this.apiURL}/publications/${id}/reviews`, {observe: 'response', params: params});
  }

  /**
   * Get reviews for Anfitrion Panel
   * @param id publication id
   * @param body
   * @returns Observable
   */

  getReviewsForAnfitrionPanel(id: number, params : any) {
    return this._http.get(`${this.apiURL}/admins/publications/${id}/reviews`, {observe: 'response', params: params});
  }


  /**
   * Get Recommendations
   * return Observable
   */
  getRecommendations() {
    return this._http.get(`${this.apiURL}/recommendations`);
  }

  /**
   * Get reviews per publication
   * @param id publication id
   * @retun Observable
   */



  /**
  * Add Review to publication
  * @param id publication id
  * @param review review
  * @returns Observable
  */
  addReview(id: number, review: any) {
    return this._http.post(`${this.apiURL}/publications/${id}/reviews`, review);
  }

  /**
   * Create Report
   * @param report report
   * @param id review id
   * @returns Observable
   */

  createReport(report: any, id: number) {
    return this._http.post(`${this.apiURL}/admins/reviews/${id}/reports`, report, {observe: 'response'});
  }

  /**
   * Create Comment
   * @param comment comment
   * @param id review id
   * @returns Observable
   */

  createComment(id: number, comment: any) {
    return this._http.post(`${this.apiURL}/reviews/${id}/comments`, comment, {observe: 'response'});
  }

  /**
   * Edit Review
   * @param review review
   * @param publication_id publication id
   * @param review_id review id
   * @returns Observable
   */

  editReview(review: any, publication_id: number, review_id: number) {
    return this._http.put(`${this.apiURL}/publications/${publication_id}/reviews/${review_id}`, review);
  }

  /**
   * Edit Comment
   * @param comment comment
   * @param publication_id publication id
   * @param comment_id comment id
   * @returns Observable
   */

  editComment(comment: any, publication_id: number, comment_id: number) {
    return this._http.put(`${this.apiURL}/admins/publications/${publication_id}/comments/${comment_id}`, comment, {observe: 'response'});
  }

  /**
   * Mark all reviews as read
   * @param publication_id publication id
   * @return Observable
   */

  markAllReviewsAsRead(publication_id: number) {
    return this._http.put(`${this.apiURL}/publications/${publication_id}/reviews/views`, {});
  }

  /**
   * Add like to review
   * @param review_id review id
   * @returns Observable
   */

  addLikeToReview(review_id: number) {
    return this._http.get(`${this.apiURL}/reviews/${review_id}/likes`, {});
  }

  /**
   * Get User Reviews
   * @return Observable
   */

  getUserReviews() {
    return this._http.get(`${this.apiURL}/users`, {observe: 'response'});
  }


  /**
   * Give Like to a Review
   * @param review_id review id
   */

  giveLikeToReview(review_id: number, like_status : boolean) {
    let body = {
      like : like_status
    }
    return this._http.post(`${this.apiURL}/reviews/${review_id}/like`, body);
  }


  /**
   * Get Reports Types
   * @returns Observable
   */

  getReportsTypes() {
    return this._http.get(`${this.apiURL}/reports/types`, {observe: 'response'});
  }



}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReservationsService {
  private ApiURL = environment.ApiMesas;
  constructor(private _http : HttpClient, private _router : Router) { }

  /**
   * Get Booking Form
   * @param publication_id
   * @param date
   * @return Observable
   */
  GetBookingForm(publication_id : number, date : string){
    return this._http.get(`${this.ApiURL}/microsites/${publication_id}/availability/formatAvailability`, {params : {date : date}, observe : 'response'});
  }

  /**
   * Check Availability
   * @param publication_id
   * @param Reservation (date, hour, numb_guests)
   */

  CheckAvailability(publication_id : number, Reservation : any){
    return this._http.get(`${this.ApiURL}/microsites/${publication_id}/availability/basic`, {params: Reservation, observe : 'response'});
  }

  /**
   * Get Days Locked
   * @param publication_id
   * @param date_initial
   * @param date_final
   */

  GetDaysLocked(publication_id : number, date_initial : string, date_final : string){
    return this._http.get(`${this.ApiURL}/microsites/${publication_id}/availability/daysdisabled`, {params: {date_ini : date_initial, date_fin : date_final}, observe : 'response'});
  }


  /**
   * Create Temporal Reservation
   * @param publication_id
   * @param Reservation (date, hour, numb_guests, zone_id, index)
   * @returns Observable
   */

  CreateTemporalReservation(publication_id : number, Reservation : any){
    return this._http.post(`${this.ApiURL}/microsites/${publication_id}/reservationstemporary`, Reservation, {observe : 'response'});
  }

  /**
   * Cancel Temporal Reservation
   * @param reservation_code
   * Method Patch
   * @returns Observable
   */

  CancelTemporalReservation(reservation_code : string){
    return this._http.patch(`${this.ApiURL}/reservationstemporary/${reservation_code}/cancel`, {});
  }


  /**
   * Create Reservation
   * @param publication_id
   * @param Reservation (date, hour, numb_guests, zone_id, index)
   * @returns Observable
   */

  CreateReservation(publication_id : number, Reservation : any, reservation_code : string){
    return this._http.post(`${this.ApiURL}/microsites/${publication_id}/reservations/${reservation_code}`, Reservation, {observe : 'response'});
  }


  /**
   * List descriptions
   * return Observable
   */

  ListDescriptions(){
    return this._http.get(`${this.ApiURL}/descriptions`, {observe : 'response'});
  }


  /**
   * Get User Reservations
   * return Observable
   */

  GetUserReservations(queryParams : any){
    return this._http.get(`${this.ApiURL}/users/reservations`, {observe : 'response' , params : queryParams});
  }

  /**
   * Get user single reservation by id
   * @param reservation_id : number
   */

  GetUserSingleReservation(reservation_id : number){
    return this._http.get(`${this.ApiURL}/users/reservations/${reservation_id}`, {observe : 'response'})

  }

  /**
   * Cancel User Reservation
   * @param reservationcode : string
   * return Observable
   */

  CancelUserReservation(reservationcode : string){
    return this._http.patch(`${this.ApiURL}/users/reservations/${reservationcode}/cancel`, {});
  }

  /**
   * Get Availability for multiple Publicaitions
   * @param mas_publications_id : Array
   * @param date : string
   * @param hour : string
   * @param num_guests : number
   * return Observable
   */

  GetAvailabilityMultiplePublications(mas_publications_id : Array<number>, date : string, hour : string, num_guests : number){
    return this._http.post(`${this.ApiURL}/microsites/availability/multi`, {mas_publications_id : mas_publications_id, date : date, hour : hour, num_guests : num_guests}, {observe : 'response'});
  }


  /**
   * Update Reservation
   * @param reservation_code
   * @param Reservation (date, hour, numb_guests, zone_id, index)
   */

  UpdateReservation(reservation_code : string, Reservation : any){
    return this._http.put(`${this.ApiURL}/users/reservations/${reservation_code}/update`, Reservation, {observe : 'response'});
  }


  /**
   * Get Turns and Zones
   * @param publication_id
   */

  GetTurnsAndZones(publication_id : number){
    return this._http.get(`${this.ApiURL}/admins/microsites/${publication_id}/configurations/turns-and-zones`);

  }

  /**
   * Create Turn
   * @param publication_id
   * @param turnData
   */

  CreateTurn(publication_id : number, turnData : any){
    return this._http.post(`${this.ApiURL}/admins/microsites/${publication_id}/configurations/turn-fast`, turnData);
  }

  /**
   * Delete Turn
   * @param publication_id
   * @param turn_id
   */

  DeleteTurn(publication_id : number, turn_id : number){
    return this._http.delete(`${this.ApiURL}/admins/microsites/${publication_id}/configurations/turn-fast/${turn_id}`);
  }


  /**
   * ADD Mesa
   * @param publication_id
   * @param zone_id
   * @param mesaData
   */

  AddMesa(publication_id : number, zone_id : number, mesaData : any){
    return this._http.post(`${this.ApiURL}/admins/microsites/${publication_id}/configurations/zones/${zone_id}/tables-fast`, mesaData);
  }

  /**
   * Update Mesa
   * @param publication_id
   * @param mesaData
   * @param mesa_id
   *
   */

  UpdateMesa(publication_id : number, mesaData : any, mesa_id : number){
    return this._http.put(`${this.ApiURL}/admins/microsites/${publication_id}/configurations/tables-fast/${mesa_id}`, mesaData);
  }

  /**
   * Delete Mesa
   * @param publication_id
   * @param mesa_id
   */

  DeleteMesa(publication_id : number, mesa_id : number){
    return this._http.delete(`${this.ApiURL}/admins/microsites/${publication_id}/configurations/tables-fast/${mesa_id}`);
  }

  /**
   * Get Temporary Reservations
   * ReservationCode
   */

  GetTemporaryReservations(reservation_code : string){
    return this._http.get(`${this.ApiURL}/reservationstemporary/${reservation_code}`);
  }


  /**
 * Get Upcoming Reservations
 * return Observable
 */

getUpcomingReservations() {
  const url = `${this.ApiURL}/users/upcoming`;
  return this._http.get(url, {observe : 'response'});
}


/**
 * Create turns and zones default
 * @param publication_id
 * @param data
 */


CreateTurnsAndZonesDefault(publication_id : number, data : any){
  return this._http.post(`${this.ApiURL}/microsites/${publication_id}/configurations/turns-and-zones`, data);

}
}

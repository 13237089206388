import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/services/auth.service';
import { ReviewsService } from 'src/app/core/services/reviews.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-review',
  templateUrl: './edit-review.component.html',
  styleUrls: ['./edit-review.component.scss']
})
export class EditReviewComponent {

  @Input()
  publication_id! : any;
  @Input()
  reservation! : any;
  @Input()
  review! : any;
  ReviewsForm! : UntypedFormGroup;
  usuario: any;
  food = new FormControl<number>(0, Validators.min(1));
  service = new FormControl<number>(0, Validators.min(1));
  overall = new FormControl<number>(0, Validators.min(1));
  ambience = new FormControl<number>(0, Validators.min(1));
  noise = new FormControl<number>(0, Validators.min(1));
  pmessage : boolean = false;
  recommendations : any[] = [];

  //steps = ['Step 1', 'Step 2', 'Step 3'];

  step_1  = new FormControl('', Validators.required);
  step_2  = new FormControl('', Validators.required);

  private apiUpload = environment.ApiUpload;

  reviewsDropzone = {
    url: `${this.apiUpload}/publications/temp/reviewimgrating`,
    maxFilesize: 10,
    acceptedFiles: 'image/*',
    uploadMultiple: false,
    parallelUploads: 1,
    maxFiles: 1,

  }
  constructor(private _reviews : ReviewsService, private _formBuilder : FormBuilder, private _modal : NgbModal, private _auth: AuthService) { }


  /**
   * Get the recommendations control from the form
   * @returns Reviews Form recommendations array
   */
 public FormRecommendations() {
    return this.ReviewsForm.get('recommendations') as FormArray;
  }

  public OpenModal(content: any) {
    this._modal.open(content, {centered: true, size: 'lg'});
  }

  FormControls() {
    return this.ReviewsForm.controls;
  }

  /**
   * Set the value of the recommendation
   * @param event
   */
public setRecommendation(event: any) {
  const value = parseInt(event.target.value);
  if (event.target.checked) {
    this.FormRecommendations().push(new FormControl(value));
  }
  else {
    let i: number = 0;
    this.FormRecommendations().controls.forEach((item: any) => {
      if (item.value == value) {
        this.FormRecommendations().removeAt(i);
        return;
      }
      i++;
    });
  }
}
 /**
  * Send review to API
  * @returns void
  */
  public SendReview(): void {
   if(this.ReviewsForm.valid){
     this._reviews.editReview(this.ReviewsForm.value, this.publication_id, this.review.id).subscribe((response: any) => {

     });
   }
  }

  public UploadImageSuccess(event: any) {
    let img = event[1].data.name;
    this.ReviewsForm.addControl('photo', new FormControl(img));
  }

  public ValidateStep1() {

    if(this.ReviewsForm.controls['food'].valid && this.ReviewsForm.controls['service'].valid && this.ReviewsForm.controls['overall'].valid && this.ReviewsForm.controls['ambience'].valid && this.ReviewsForm.controls['noise'].valid){
      this.step_1.setValue('true');
    }
  }

  public ValidateStep2() {
    if(this.ReviewsForm.controls['review'].valid){
      this.step_2.setValue('true');
    }
  }

  ngOnInit(): void {

    this.usuario = this._auth.usuario;
    /**
     * Form to add review
     * Initalization
     */
    this.ReviewsForm = this._formBuilder.group({
      review: ['', {Validators: [Validators.required], updateOn: 'change'}],
      food: this.food,
      service: this.service,
      overall: this.overall,
      ambience: this.ambience,
      noise: this.noise,
      value: [5],
      nickname: [''],
      is_recommended: [false],
      privatemessage: [''],
      reservationId: this.reservation.id,
      recommendations: this._formBuilder.array([])
    });

    this._reviews.getRecommendations().subscribe((response: any) => {
      if (response.count > 0){
      this.recommendations = response.data;
      }
    });

  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-members-dashboard-menu',
  templateUrl: './menu.component.html',
})
export class MenuMembersComponent implements OnInit {

  constructor(private route : ActivatedRoute) { }
  publication_id : any;
  ngOnInit(): void {

    this.publication_id = this.route.snapshot.paramMap.get('id');
  }

}

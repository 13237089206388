
<div class="d-flex flex-row">
  <div class="form-group d-block rounded-md-pill mb-3 mx-md-2 w-md-75">
    <div class="row g-0 ms-sm-n2">
      <div class="d-sm-flex align-items-center">
        <div class="input-group border-end-sm me-1 placeholder-glow">
          <input type="text" class="form-control" id="date-input" [ngClass]="is_loading ? 'placeholder disabled' : ''" [(ngModel)]="availability.date" #datepicker>
        </div>
        <hr class="d-sm-none my-2">
        <div class="input-group border-end-sm me-1 placeholder-glow">
          <select class="form-select" id="select-input" [ngClass]="is_loading ? 'placeholder' : ''"  [(ngModel)]="availability.hour" >
            <option *ngFor="let hour of HourList" [value]="hour.option">{{hour.option_user}}</option>
          </select>
        </div>
        <hr class="d-sm-none my-2">
        <div class="input-group placeholder-glow">
              <select class="form-select" id="select-input"[ngClass]="is_loading ? 'placeholder' : ''"   *transloco="let t; scope: 'home'" [(ngModel)]="availability.num_guests">
                <option *ngFor="let people of PeopleList" [value]="people.value">{{people.text}}</option>
              </select>
      </div>
    </div>
  </div>
  </div>
    <button class="btn btn-primary btn-lg rounded-pill w-100 w-md-auto mb-3 ms-sm-3" *transloco="let t; scope: 'account'" type="button" (click)="CheckAvailability()">
      <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" *ngIf="is_loading"></span>
      {{is_loading ? '': t('account.buscar_nueva_mesa')}}</button>

</div>

<ng-container *ngIf="turnos.length == 0 && is_submit">
<p>No encontramos horarios disponibles</p>
</ng-container>
<ng-container *ngIf="turnos.length > 0">
  <div class="mt-2 mb-4 px-5">
    <h6 class="fs-6">Selecciona tu horario</h6>
  <div class="row row-cols-5 gx-5 gy-5">
    <div class="col" *ngFor="let turno of turnos">
      <input type="radio" class="btn-check" id="btn-check-{{turno.index}}" name="hora" autocomplete="off" [attr.disabled]="turno.availability == false ? 'disabled' : null" (change)="CheckZones(turno)">
      <label class="btn btn-outline-gray position-relative w-100 pb-3" for="btn-check-{{turno.index}}" >{{turno.hour}} <span class="hour-discount">10%</span></label>
    </div>
  </div>
  </div>
</ng-container>

<ng-container *ngIf="zones.length > 0">
  <div class="my-2 px-5">
    <h6 class="fs-6">Selecciona una zona</h6>
    <div class="row gy-3 row-cols-5">
      <div class="col" *ngFor="let zone of zones">
      <input type="radio" class="btn-check" id="zone-{{zone.id}}" [(ngModel)]="availability['zone_id']" [value]="zone.id" autocomplete="off">
      <label class="btn btn-sm btn-outline-gray d-flex align-items-center" for="zone-{{zone.id}}"><mat-icon  fontIcon="table_restaurant" class="me-1"></mat-icon>{{zone.name}}</label>
      </div>
    </div>
    <button *ngIf="availability.zone_id" class="btn btn-primary my-5" (click)="UpdateReservation()">Reservar</button>
  </div>
</ng-container>

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from "./basic/home/home.component";

import { AuthGuard } from '../core/guards/auth.guard';
const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
    title: "Masmesa - Portal",
  },
  {
    path: '', loadChildren: () => import('./basic/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
    canActivate: [ AuthGuard ],
    title: "Masmesa - Account",
    data: {routing: 'account'}
  },
  {
    path: 'members', loadChildren: () => import('./members/members.module').then(m => m.MembersModule),
    canActivate: [ AuthGuard ],
    title: "Masmesa - Members",
    data: {routing: 'members'}
  },
  {
    path: 'paginas', loadChildren: () => import('./extrapages/extrapages.module').then(m => m.ExtrapagesModule)
  },

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesRoutingModule { }

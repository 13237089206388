import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { ExperiencesService } from 'src/app/core/services/experiences.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-select-reservation-type',
  templateUrl: './select-reservation-type.component.html'
})
export class SelectReservationTypeComponent implements OnInit {
@Input() publicationId : any = {};
@Input() Publication : any = {};
@Input() is_external : boolean = false;
@Input() reservationData : any = {
  experience_id: null,
};
storage : any = environment.Storage;
storage_experiences : any = environment.Storage_experiences;
queryParams : any = {};
hasReservation : boolean = false;

  constructor(private _experiences : ExperiencesService, private _ssrcookie : SsrCookieService, private breakpointObserver : BreakpointObserver) {
    if(this._ssrcookie.check('ReservationData')) {
      this.reservationData = JSON.parse(this._ssrcookie.get('ReservationData'));
      this.hasReservation = true;
    }

    }

  ngOnInit(): void {

    this.queryParams = {
      publicationId : this.publicationId,
      day: this.reservationData.date,
      hour: moment(this.reservationData.hour, 'hh:mm:ss').format('HH:mm'),
    }
    this._experiences.GetExperiencesForDateAndHour(this.queryParams).subscribe((response:any)=>{
      if(response.success) {
      this.Publication.experiences = response.data.data;
      console.log(this.Publication.experiences);
      }
    });
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  baseUrl: 'https://masmesa.woostore.cyou',
  ApiUsuarios: 'https://dev-usuarios.masmesa.com/api/v1/es',
  ApiUpload: 'https://dev-uploadfiles.masmesa.com/api/v1/es',
  ApiPublicaciones: 'https://dev-publicaciones.masmesa.com/v1/es',
  ApiReviews: 'https://dev-reviews.masmesa.com/v1/es',
  ApiMesas : 'https://dev-mesas.masmesa.com/v1/es',
  ApiNotificaciones: 'https://dev-node-masmesa.masmesa.com/v1/es/api',
  ApiPagos: 'https://dev-pagos.masmesa.com/v1/es',
  blog_api : 'https://blogmasmesa.woostore.cyou/',
  Google_login_auth : "917081551810-ce6mpa36trsdoaeogsknf52v96dt3pq6.apps.googleusercontent.com",
  Google_Maps_Apikey : "AIzaSyCgH54RbxJNpDTvFKxy7d11o-QoG_r2yqQ",
  IPGeolocation : 'https://ipgeolocation.abstractapi.com/v1/?api_key=cd56ed1392ec4d9cb797a758bfaa0aad',
  Node_masmesa : 'https://dev-node-masmesa.masmesa.com/v1/es/api',

  //sockets
  Socket_Mesas : 'https://dev-socket-masmesa.masmesa.com',
  Socket_Chat : 'https://dev-chat.masmesa.com',


  Storage: 'https://storage.googleapis.com/st-masmesa-dev-100/publication/img/content/',
  Storage_temp : 'https://storage.googleapis.com/st-masmesa-dev-100/temp/publication/img/content/',
  Storage_brand:'https://storage.googleapis.com/st-masmesa-dev-100/publication/img/brand/',
  Storage_temp_brand:'https://storage.googleapis.com/st-masmesa-dev-100/temp/publication/img/brand/',
  Storage_menu : 'https://storage.googleapis.com/st-masmesa-dev-100/publication/img/menu/',
  Storage_temp_menu : 'https://storage.googleapis.com/st-masmesa-dev-100/temp/publication/img/menu/',
  Storage_experiences : 'https://storage.googleapis.com/st-masmesa-dev-100/publication/img/experience/',
  Storage_temp_experiences : 'https://storage.googleapis.com/st-masmesa-dev-100/temp/publication/img/experience/',
  Storage_video : 'https://storage.googleapis.com/st-masmesa-dev-100/publication/video/content/',
  Storage_video_temp : 'https://storage.googleapis.com/st-masmesa-dev-100/temp/publication/video/content/',
  Storage_chat : 'https://storage.googleapis.com/st-masmesa-dev-100/chat/file/message/',
  Profile_storage : 'https://storage.googleapis.com/st-masmesa-dev-100/user/img/perfil/200x200/',

 //storage
 /*
 Storage: 'https://storage.masmesa.com/publication/img/content/',
 Storage_temp : 'https://storage.masmesa.com/temp/publication/img/content/',
 Storage_brand:'https://storage.masmesa.com/publication/img/brand/',
 Storage_temp_brand:'https://storage.masmesa.com/temp/publication/img/brand/',
 Storage_menu : 'https://storage.masmesa.com/publication/img/menu/',
 Storage_temp_menu : 'https://storage.masmesa.com/temp/publication/img/menu/',
 Storage_experiences : 'https://storage.masmesa.com/publication/img/experience/',
 Storage_temp_experiences : 'https://storage.masmesa.com/temp/publication/img/experience/',
 Storage_video : 'https://storage.masmesa.com/publication/video/content/',
 Storage_video_temp : 'https://storage.masmesa.com/temp/publication/video/content/',
 Storage_chat : 'https://storage.googleapis.com/st-masmesa-dev-100/chat/file/message/',
 */
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

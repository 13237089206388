import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(private _http : HttpClient) { }
  APIUrl = environment.ApiPagos;

  /**
  * Send Payment  with Card present
  @param PaymentData
  @returns Observable

  **/

  sendPayment(PaymentData : any){
    return this._http.post(`${this.APIUrl}/payment/gateways/process`, PaymentData);
  }

  /*
  * Send Payment with card Token
   */

  sendPaymentToken(PaymentData : any){
    return this._http.post(`${this.APIUrl}/payment/gateways/process-with-token`, PaymentData);
  }

  /**
   * Get payments
   * @param queryParams
   * @returns Observable with the payemnt data Anfitrion
   * based on the user token
   */

  getPayments(queryParams : any){
    return this._http.get(`${this.APIUrl}/payments`, {params : queryParams});
  }

  /**
   * Get payments Parameters
   * @returns Observable with the payemnt data Anfitrion
   */

  getPaymentsParameters(){
    return this._http.get(`${this.APIUrl}/payment/parameter`);
  }

  /**
   * Get cards by user
   * @param PaymentGatewayID
   */

  getCardsByUser(PaymentGatewayID : any){
    return this._http.get(`${this.APIUrl}/payment/gateways/list-cards/${PaymentGatewayID}`);
  }

  /**
   * Add Card
   * @param CardData
   * @returns Observable
   */

  addCard(CardData : any){
    return this._http.post(`${this.APIUrl}/payment/gateways/add-card`, CardData);
  }

}

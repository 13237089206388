import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of, BehaviorSubject, EmptyError, EMPTY } from 'rxjs';

import { SocialAuthService } from "@abacritt/angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "@abacritt/angularx-social-login";
import { MessagesService } from './messages.service';
import { TranslocoService } from '@ngneat/transloco';
import { Moment } from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private AuthApi = environment.ApiUsuarios;
  private _usuario : any;
  private _isloggedin : boolean = false;
  FavoritesPublications : any = [];
  private _isLoggedin = new BehaviorSubject<boolean>(false);
  public isLoggedin$ = this._isLoggedin.asObservable();

  constructor(private http: HttpClient,
    private SocialAuth: SocialAuthService,
    private messageService: MessagesService,
    private translator : TranslocoService,
    private _ngbModal : NgbModal) {



if(typeof window !== 'undefined'){
    //if token exists on localstorage, verify it and set the user data
    let token = localStorage.getItem('token_session');
    if(token && this._usuario == undefined){
      this.getUsuario().subscribe( resp => {
        if(resp.success){
          this._usuario = resp.data.user;
          this._isloggedin = true;
          this._isLoggedin.next(true);
          if(this._usuario.favorites.length > 0){
            this._usuario.favorites.forEach((favorite : any) => {
              this.FavoritesPublications.push(favorite.id);
            });
          }

        }
      });
    }
  }
    this.SocialAuth.authState.subscribe((user) => {
      if(user.provider == 'GOOGLE'){
        const url = `${this.AuthApi}/auth/socialnetwork`;
      let user_data;

      user_data = {
        "user_social": {
          'fullname' : user.name,
          'email' : user.email,
          'id' : user.id,
          'bs_socialnetwork_id' : 3,
          'token' : user.authToken,
          'photo' : user.photoUrl
        }
      }

      this.http.post<any>(url, user_data).subscribe( resp => {
        if(resp.success){
          localStorage.setItem('token_session', resp.data.token_session);
          this._usuario = resp.data.user;
          this._isloggedin = true;
          this._isLoggedin.next(true);
          if(this._usuario.favorites.length > 0){
            this._usuario.favorites.forEach((favorite : any) => {
              this.FavoritesPublications.push(favorite.id);
            });
          }
        }

      });
      }
    });

   }

  /*
  * Return the user data
  */
  get usuario() {
    return { ...this._usuario };
  }

/*
* return the user is logged in
*/
get isloggedin() : boolean {
  return this._isloggedin;
}


/*
* login(credentials)
* params : credentials (email, password)
* This function is used to login the user.
*/

login(credentials : string) {
  const url = `${this.AuthApi}/auth/bookersnap/`;
  return this.http.post<any>(url, credentials, {observe: 'response'}).pipe(
    tap( resp  => {
      if (resp.status == 200) {
        localStorage.setItem('token_session', resp.body.data.token_session);
        this._usuario = resp.body.data.user;
        this._isloggedin = true;
        this._isLoggedin.next(true);
        if(this._usuario.favorites.length > 0){
          this._usuario.favorites.forEach((favorite : any) => {
            this.FavoritesPublications.push(favorite.id);
          });
        }
      }
    }),
  map(resp => resp.body.success),
  catchError(resp => {
    if(resp.status == 401) {
      console.log(resp);
      this.messageService.show('Credenciales incorrectas', "Las credenciales Ingresadas no son correctas", 'danger');
    }

    if(resp.status == 500) {
      console.log(resp);
      this.messageService.show('Error', "El servicio no se encuentra disponible por favor intenta mas tarde", 'danger');
    }
    return of(false);
  }),
  )

}

/*
*signup(user_data)
* params : user_data (fullname, email, password, password_confirmation, country, phone, terms)
* This function is used to register the user.
*/
signup(user : any) {
  const url = `${this.AuthApi}/user`;

  return this.http.post<any>(url, user);


}


/*
* Verify token
* This function is used to verify the session token.
*/

verifyToken() : Observable<boolean> {
  const url = `${this.AuthApi}/user`;
  const token = localStorage.getItem('token_session');

  if(!token){
    return of(false);
  }

  return this.http.get<any>(url).pipe(
    tap( resp  => {
      if ( resp.success) {
        this._usuario = resp.data.user;
        this._usuario['brand_id'] = resp.data.brand_id;
        this._isloggedin = true;
        this._isLoggedin.next(true);
      }
    }),
  map(resp => resp.success),
  catchError(err => {
    if(err.status == 401){
      this._usuario = undefined;
      this._isloggedin = false;
      this._isLoggedin.next(false);
      localStorage.removeItem('token_session');
    }
    return of(false);
  })

  )
}


/*
* logout()
* This function is used to logout the user.
*/

logout() {
  localStorage.removeItem('token_session');
  this._usuario = undefined;
  this._isloggedin = false;
  this._isLoggedin.next(false);
  let socialAuthsubscribe = this.SocialAuth.authState.subscribe((user) => {
    if(!user){
      this.SocialAuth.signOut();
      socialAuthsubscribe.unsubscribe();
    }

  });
}


/*
* siginWithFacebook ()
* This function is used to login the user with facebook.
*/

siginWithFacebook() {
  this.SocialAuth.signIn(FacebookLoginProvider.PROVIDER_ID).then( resp => {
    this.http.get<any>(`https://graph.facebook.com/me/permissions?access_token=${resp.authToken}`).subscribe( response => {
    let permisions_declided = response.data.filter( (permission : any) => permission.status == 'declined');

    //if the user has declined some permission (email permission), we need to ask for them again
    if(permisions_declided.some( (permission : any) => permission.permission == 'email')){

      this.SocialAuth.signOut(); //logout the user
      this.messageService.show(this.translator.translate('error_email_login_facebook_title'), this.translator.translate('error_email_login_facebook_body'), 'danger', 5000);

      const requestOptions = {
        scope: 'email,public_profile',
        locale: 'en_US',
        fields: 'name,email,picture,first_name,last_name',
        version: 'v15.0',
        auth_type: 'rerequest'
    };

      this.SocialAuth.signIn(FacebookLoginProvider.PROVIDER_ID , requestOptions);

    } else {

    if(resp.email == undefined) { //if the user has not email in the Facebook Account
      this.SocialAuth.signOut();


    } else {
      const url = `${this.AuthApi}/auth/socialnetwork`;
      let user_data;

      user_data = {
        "user_social": {
          'fullname' : resp.name,
          'email' : resp.email,
          'id' : resp.id,
          'bs_socialnetwork_id' : 1,
          'token' : resp.authToken,
          'photo' : resp.photoUrl
        }
      }

      this.http.post<any>(url, user_data).subscribe( resp => {
        if(resp.success){
          localStorage.setItem('token_session', resp.data.token_session);
          this._usuario = resp.data.user;
          this._usuario['brand_id'] = resp.data.brand_id;
          this._isloggedin = true;
          this._isLoggedin.next(true);
        }

      });
    }

  }

  });

  });
}

/*
*ForgotPasswordGetToken(email)
* params : email
* This function is used to send the email to the user to reset the password.
*/

ForgotPasswordGetToken(email : string) {
  const url = `${this.AuthApi}/auth/recover-password-token`;

  return this.http.post<any>(url, email, {observe: 'response'}).pipe(
    tap( resp  => {
      if ( resp.status == 200) {
        this.messageService.show('Correo Enviado', 'Se ha enviado un correo a su cuenta de correo para recuperar su contraseña', 'success', 5000);
      }
    }),
    map( resp => resp.body.success ),
    catchError( err => {
      if(err.status == 404){
        this.messageService.show('Correo no encontrado', 'El correo ingresado no se encuentra registrado en nuestra plataforma', 'danger', 5000);
      }

      if(err.status == 500){
        this.messageService.show('Error', 'El servicio no se encuentra disponible por favor intenta mas tarde', 'danger', 5000);
      }

      return of(false);
    } )
  );

}

Setnewpassword(password : string, password_confirmation : string, token : string) {
  const url = `${this.AuthApi}/auth/recover-password-change/${token}`;

  return this.http.post<any>(url, {password, password_confirmation}).pipe(
    tap( resp  => {
      if ( resp.success) {
        this.messageService.show('Nueva Contraseña Agregada', 'Se ha cambiado exitosamente su Contraseña', 'success', 5000);
      }
    }),
    map( resp => resp.success),
    catchError( err => of(false) )
  );

}


getUsuario() {
  const url = `${this.AuthApi}/user`;

  return this.http.get<any>(url)
}


/**
 * Refresh the user data
 */

refreshUserData() {
  this.getUsuario().subscribe( resp => {
    if(resp.success){
      this._usuario = resp.data.user;
      this._usuario['brand_id'] = resp.data.brand_id;
      this.FavoritesPublications = [];
         if(this._usuario.favorites.length > 0){
          this._usuario.favorites.forEach((favorite : any) => {
            this.FavoritesPublications.push(favorite.id);
          });
        }
    }
  });
}

VerifyEmailToken(token : string) {
  const url = `${this.AuthApi}/user/mail-confirmation?token=${token}`;

  return this.http.get<any>(url);
}

}

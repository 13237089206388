import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ThemeService } from 'src/app/core/services/theme.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html'
})
export class TopbarComponent implements OnInit {
  ActiveLang : string ="";
  dark : boolean = false;
  hiddenBlock : boolean = false;
  constructor(private _service: TranslocoService, private _theme : ThemeService) {
    if(this._service.getActiveLang() == 'es') {
      this.ActiveLang = "Esp";
    }
    else {
      this.ActiveLang = "Eng";
    }
  }


  ChangeLanguage(language : string) {
    this._service.setActiveLang(language);
    if(this._service.getActiveLang() == 'es') {
      this.ActiveLang = "Esp";
    }
    else {
      this.ActiveLang = "Eng";
    }

  }

  ToggleMode() {
    this._theme.ToggleMode();
  }

  ngOnInit(): void {
    this.dark = this._theme.mode;
  this._theme.mode$.subscribe((mode) => {
    this.dark = mode;
  });

  this._theme.hiddenBlock$.subscribe((block) => {
    this.hiddenBlock = block;
  });
  }

}

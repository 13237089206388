import { Injectable, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService  {
  mode : boolean = false;
  mode$ : Subject<boolean> = new Subject<boolean>();
  hiddenBlock : boolean = false;
  hiddenBlock$ : Subject<boolean> = new Subject<boolean>();
  constructor() {
    this.hiddenBlock$.next(this.hiddenBlock);
    if(typeof window !== 'undefined'){
    localStorage.getItem('theme') == 'dark' ? this.mode = true : this.mode = false;
    }
    this.mode$.next(this.mode);
    if (this.mode) {
      document.body.classList.add('dark-theme');
    }
  }


  ToggleMode() {
    this.mode = !this.mode;
    localStorage.setItem('theme', this.mode ? 'dark' : 'light');
    this.mode$.next(this.mode);
    document.body.classList.toggle('dark-theme');
  }

  ToggleHiddenBlock() {
    this.hiddenBlock = !this.hiddenBlock;
    this.hiddenBlock$.next(this.hiddenBlock);
  }

  disableHiddenBlock() {
    this.hiddenBlock = false;
    this.hiddenBlock$.next(this.hiddenBlock);
  }

}

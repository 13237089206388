import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

import * as _ from "lodash";
@Injectable({
  providedIn: 'root'
})
export class PublicationsService implements OnInit{

  private apiURL = environment.ApiPublicaciones;
  private currentPublication! : any;
  private taxonomies : any;
  private countries : any;
  private states : any;
  private districts : any;
  private cities : any;
  private categories : any;


  constructor(private http: HttpClient) {
    this.getallTaxonomies();
    this.getStates(1); //Mexico for testing Purposes
  }


  ngOnInit(): void {

  }

public get CurrentPublication() {
    return this.currentPublication;
  }

  /**
  * Get one Publication by id for Admins
  * @params ID
  * return Observable
  **/
  public GetPublication(id: number)  {
    const url = `${this.apiURL}/admins/publication/${id}`;
    return this.http.get(url, ).pipe(
      tap((resp: any) => {
        this.currentPublication = resp.data;
      })
    );
  }

  /**
  * Get one Publication by id for Public
  * @params ID
  * return Observable
  **/
  public GetPublicationPublic(id: number)  {
    const url = `${this.apiURL}/publication/${id}`;
    return this.http.get(url).pipe(
      tap((resp: any) => {
        this.currentPublication = resp.data;
      })
    );
  }
  /**
   * Add a new Publication
   * @params data
   * return Observable<boolean>
   */

  public AddPublication(data: any) : Observable<any> {
    const url = `${this.apiURL}/admins/publications`;
    return this.http.post(url, data);
  }

  /**
   * Update one Publication
   * @param id
   * @param data
   * @returns Observable
   */

  public UpdatePublication(id: number, data: any) : Observable<any> {
    const url = `${this.apiURL}/admins/publications/${id}`;
    return this.http.patch(url, data);
  }

  /*
  * Get Taxonomies
  * @params
  * return Observable
  * */
public getallTaxonomies() {
  const url = `${this.apiURL}/admins/descriptions`;
  return this.http.get(url);
}
/**
* Get Taxonomies by Type and ID
* @params taxonomies [], type (string), ids (number)
* return Array
* */

public filterTaxonomies(taxonomies : any, type : string, ids : number[] | number) {
  return _.find(taxonomies[type], function(o) {
    if (o.id ) return o;

  });

}

/**
 * Get Countries
 * return Array
 */
 public getCountries() {
  const url = `${this.apiURL}/admins/countries`;
  return this.http.get(url);
 }

/**
* Get one Taxonomy by Type and ID
* @params type (string), ids (number)
* return Array
* */

public getTaxonomy(type: string, id : number) {
  return _.find(this.taxonomies[type], function(o) {
    if (o.id ) return o;
  });

}

public getStates (countryID : number) {
  const url = `${this.apiURL}/admins/states/${countryID}`;
  return this.http.get(url).pipe(
    map((resp: any) => {
      this.states = resp.data;
    })
  );
}

public getAllStates (countryID : number) {
  const url = `${this.apiURL}/admins/states/${countryID}`;
  return this.http.get(url);
}


public getCities (stateID : number) {
const url = `${this.apiURL}/admins/cities/${stateID}`;

return this.http.get(url).pipe(
  map((resp: any) => {
    this.cities = resp.data;
  }
  )
);

}

public getAllCities (stateID : number) {
  const url = `${this.apiURL}/admins/cities/${stateID}`;
  return this.http.get(url);
}


public getCity(id : number) {
  return _.find(this.cities, function(o) {
    if (o.id ) return o;
  });

}

public getDistricts (cityID : number) {
  const url = `${this.apiURL}/admins/city/${cityID}/districts`;
  return this.http.get(url);
}

public getCategories(){
  const url = `${this.apiURL}/admins/categories`;
  return this.http.get(url);
}

public getSubCategories(categoryID : number){
  const url = `${this.apiURL}/admins/category/${categoryID}/subcategories`;
  return this.http.get(url);
}


public findpublications(queryParams?: any) {
  const url = `${this.apiURL}/public/publications`;
  return this.http.get(url, { params: queryParams });
}

public GetMultimedia(publicationId : any){
  const url = `${this.apiURL}/admins/publication/${publicationId}/multimedia`;
  return this.http.get<any>(url);
}

public UpdateMultimedia(data : any){
  const url = `${this.apiURL}/admins/multimedia`;
  return this.http.patch<any>(url, data);

}

/**
 * Create Menu Category
 * @param name (string) name of the category
 * @param id (number) id of the publication
 * @returns Observable
 */

public CreateMenuCategory(name : string , id : number ){
  const url = `${this.apiURL}/admins/publication/${id}/menu/category`;
  return this.http.post<any>(url, name);
}


/**
 * Get List Menu subcategories
 * @param id (number) id of the publication
 * @returns Observable
 */

public GetMenuCategories(id : number ){
  const url = `${this.apiURL}/admins/publication/${id}/menu/categories/list`;

  return this.http.get<any>(url);
}

/**
 * Get List Public Menu Categories
 * @param id (number) id of the publication
 * @returns Observable
 */

public GetPublicMenuCategories(id : number ){
  const url = `${this.apiURL}/publication/${id}/menu/categories/list`;

  return this.http.get<any>(url);
}


/**
 * Add Menu
 * @param data (object) data of the menu
 * @param publication_id (number) id of the publication
 * @returns Observable
 */

public AddMenu(data : any, publication_id : number ){
  const url = `${this.apiURL}/admins/publication/${publication_id}/menu`;
  return this.http.post<any>(url, data);
}

/**
 * Update Menu
 * @param data (object) data of the menu
 * @param publication_id (number) id of the publication
 * @param menu_id (number) id of the menu
 * @returns Observable
 */

public UpdateMenu(data : any, publication_id : number, menu_id : number ){
  const url = `${this.apiURL}/admins/publication/${publication_id}/menu/${menu_id}`;
  return this.http.patch<any>(url, data);
}

/**
 * Get List Menus
 * @param id (number) id of the publication
 * @returns Observable
 */

public GetMenus(id : number ){
  const url = `${this.apiURL}/publication/${id}/menus`;
  return this.http.get<any>(url);
}

/**
 * Get Single Menu
 * @param id (number) id of the publication
 * @param menu_id (number) id of the menu
 * @returns Observable
 */

public GetSingleMenu(publication_id : number, menu_id : number ){
  const url = `${this.apiURL}/admins/publication/${publication_id}/menu/${menu_id}`;
  return this.http.get<any>(url);
}

/**
 * Delete Menu
 * @param id (number) id of the publication
 * @param menu_id (number) id of the menu
 * @returns Observable
 */

public DeleteMenu(publication_id : number, menu_id : number ){
  const url = `${this.apiURL}/admins/publication/${publication_id}/menu/${menu_id}`;
  return this.http.delete<any>(url);
}

/**
 * Set Type of Menu
 * @param id (number) id of the publication
 * @param type (number) type of the menu
 * @returns Observable
 */

public SetTypeMenu(publication_id : number, type : number ){
  const url = `${this.apiURL}/admins/publication/${publication_id}/menu/set-type`;
  return this.http.patch<any>(url, type);
}

/**
 * Get Cuisine for Subcategories
 * @param subcategorie_id (number) id of the publication
 */


public GetCuisine(subcategorie_id : number ){
  const url = `${this.apiURL}/subcategories/${subcategorie_id}/cuisines`;
  return this.http.get<any>(url);

}

/**
 * Filter Search
 * @param queryParams
 */

public FilterSearch(queryParams : any){
  const url = `${this.apiURL}/public/publications/filter-bar`;
  return this.http.get<any>(url, { params: queryParams });
}


/**
 * Get Subcategories of Subcategory
 * @param subcategorie_id (number
 * @returns Observable
 */

public GetSubcategories(subcategorie_id : number ){
  const url = `${this.apiURL}/admins/subcategories/${subcategorie_id}/sub-subcategories`;
  return this.http.get<any>(url);
}



}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private _http : HttpClient) { }
  private apiURL = environment.ApiPublicaciones;
  private queryParams : any = [];
  private recentSearch: any = [];


  get QueryParams() {
    return this.queryParams;
  }

  /**
   * Get the Suggestions for the search bar
   * @param term
   * @returns Observable
   */

  public GetSuggestedItems(term: string) {
    const url = `${this.apiURL}/public/publications/filter-bar`;
    return this._http.get(url, { params: { q: term } })
  }


  /**
   * Add a new search to the recent searches
   * @param term
   * @returns void
   */

  public AddRecentSearch(term: string) {
  let recentSearches = this.GetRecentSearches();
  let search = {
    'name' : term,
  }

  recentSearches = recentSearches.filter((item:any) => item.name !== term);
  recentSearches.push(search);
  localStorage.setItem('recentSearches', JSON.stringify(recentSearches));
  }

  /**
   * Get the recent searches
   * @returns Array
   */

  public GetRecentSearches() {
    return (localStorage.getItem('recentSearches') !== null) ? JSON.parse(localStorage.getItem('recentSearches')!) : [];
  }


}

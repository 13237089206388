import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { easepick } from '@easepick/bundle';
import * as moment from 'moment';
import { PublicationsService } from 'src/app/core/services/publications.service';
import { ReservationsService } from 'src/app/core/services/reservations.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-check-availability-experiences',
  templateUrl: './check-availability-experiences.component.html',
})
export class CheckAvailabilityExperiencesComponent implements OnInit, AfterViewInit {
@Input() Experience: any = {};
Publication: any = {};
storage : any = environment.Storage_experiences;
Calendar: any = {};
BaseURl : string = environment.baseUrl;
checkform! : UntypedFormGroup;
selectedHour : any = {};
selectedZone : number | null | undefined;
ReservationData : any = [];
hasReservation : boolean = false;
hassubmitted : boolean = false;
hour_available : boolean = false;
is_mobile : boolean = false;
@ViewChild('datepicker_calendar_experience', {static: false}) datePicker: any;
  constructor(private _publications : PublicationsService, private _fb : FormBuilder, private _reservations : ReservationsService) { }

  public checkAvailability(){

    let date = this.checkform.controls['date'].value;
    let hour = this.checkform.controls['hour'].value;
    let num_guests = Number(this.checkform.controls['num_guests'].value);
    let publicationId = this.Experience.publicationId;

    let data = {
      date,
      hour,
      num_guests
    }
    this._reservations.CheckAvailability(publicationId, data).subscribe((response:any)=>{
      this.hassubmitted = true;
      if(response.body.success){
     this.Publication.availability = response.body.data;
     this.hour_available = true;
      } else {

        this.hour_available = false;
      }




    });

  }

  selectHour(hour : any) {
    this.selectedHour = hour;
    console.log("click", this.selectedHour );
  }

  crearReservacion(){
    this.hasReservation = true;
    this.ReservationData = {
      experience_id: this.Experience.experienceId,
      date : this.checkform.controls['date'].value,
      hour : this.selectedHour.hour,
      index: this.selectedHour.index,
      zone_id : this.selectedZone,
      brand_id : this.Publication.brand_id,
      num_guests: this.checkform.controls['num_guests'].value,
      }


  }

  ngOnInit(): void {
    this._publications.GetPublicationPublic(this.Experience.publicationId).subscribe((response:any)=>{
      this.Publication = response.data;
    });
    console.log(this.Experience);
    let hour;
    let date = moment().format('YYYY-MM-DD');
    if(moment().hours() < 10) {
      hour = '0'+moment().hours()+':00'+':00';
    } else
    {
     hour = moment().hours()+':00'+':00';
    }

    this.checkform = this._fb.group({
      date: [date],
      hour: [hour],
      num_guests: [2],
    });


    if (typeof window !== 'undefined'){

      if (window.screen.width <= 678) {
        this.is_mobile = true;
      }
      }
  }

  ngAfterViewInit(): void {
    this.Calendar = new easepick.create({
      element: this.datePicker.nativeElement,
      format: 'DD/MM/YYYY',
      css: [
        `${this.BaseURl}/assets/css/calendar_final.css`,
        `${this.BaseURl}/assets/css/calendar_lock.css`,
      ],

    });

    this.Calendar.on('select', (evt: any) => {
      let date = moment(evt.detail.date).format('YYYY-MM-DD');

      this.checkform.controls['date'].setValue(date);

    });


  }


}

import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent, LoadedImage, base64ToFile, ImageCropperComponent } from 'ngx-image-cropper';
import { AuthService } from 'src/app/core/services/auth.service';
import { MediauploadService } from 'src/app/core/services/mediaupload.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-account-header',
  templateUrl: './account-header.component.html',
  styleUrls: ['./account-header.component.scss']
})
export class AccountHeaderComponent implements OnInit {
  @Input() mostrarBotonReservas: boolean = false;

  constructor(private modalService : NgbModal, private mediaUpload : MediauploadService,
    private auth : AuthService, private _user : UserService, private _route : Router) { }

  @Input()
  usuario! : any;
  breadCrumbItems!: Array<{}>;
  ngOnInit(): void {
    let currentlabel = ' ';

  if(this._route.url.includes('configuration')){
    currentlabel = 'Mi cuenta';

    }

    if(this._route.url.includes('reservations')){
      currentlabel = 'Mis reservas';
    }

    if(this._route.url.includes('favorites')){
      currentlabel = 'Mis favoritos';
    }

    if(this._route.url.includes('notifications')){
      currentlabel = 'Mis notificaciones';
    }

    if(this._route.url.includes('reviews')){
      currentlabel = 'Mis reseñas';
    }



    this.breadCrumbItems = [
      { label: 'Panel del usuario', link:'../' },
      { label: currentlabel, active: true},
    ];


    this.generateavatar = this._user.GenerateAvatar(this.usuario.fullname, true, 100);
  }

generateavatar : any ;

private userAvatar : any;

  EditProfliePicModal(editProfilePic: any)  {

    this.modalService.open(editProfilePic, { size: 'lg', centered: true });
  }

  imageChangedEvent: any = '';
  croppedImage: any = '';

  fileChangeEvent(event: any): void {
      this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
  }
  imageLoaded(image: LoadedImage) {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

  saveAvatar(){
    this.croppedImage;
    this.mediaUpload.UploadUserAvatar(this.croppedImage).subscribe(resp => {
    this.auth.verifyToken().subscribe(resp => {
    this.usuario = this.auth.usuario;

    });
    this.modalService.dismissAll();
  });
}


}

import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ChatSocket extends Socket {
  constructor() {
    let token = localStorage.getItem('token_session') ? localStorage.getItem('token_session') : '';
    super({
      url: environment.Socket_Chat,
      options: {
      extraHeaders: {
        'Authorization': 'Bearer ' + token,
      }
    }
    });
  }


}

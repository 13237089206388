<ng-container *ngIf="reservationTemp == false && isLoggedin == false">
  <div class="mx-md-5 px-md-5 d-flex flex-column justify-content-center text-center">
    <h5 class="mb-3" style="font-size: 19px;"> Reservación en {{Publication.name ? Publication.name : Publication.publicationName}} </h5>
    <div class="reservation-short-info bg-primary rounded-pill px-3 py-1 mb-3">
      <p class="mb-0 text-light"> {{reservationData['num_guests']}} personas | {{reservationData['date'] | date:'EEEE d MMM'}} | {{reservationData['hour'] | time}} | {{reservationData['zone_name'] !== undefined? reservationData['zone_name'] : 'Estándar'}}</p>
    </div>
  </div>
  <hr class="my-4 text-dark" style="width: 90%;color: #666276 !important;margin: 0 auto;">
  <div class="px-md-5">
<p class="fw-bold text-center">Proporcione su correo electrónico para completar su reserva</p>

<form  class="reservation-guest-mail" [formGroup]="reservationFormTemp" (ngSubmit)="CreateReservationTemp()">
  <div class="mb-3">
    <label for="email" class="form-label">Correo electrónico</label>
    <input class="form-control" type="text" id="email" formControlName="email">
  </div>
  <button class="btn btn-primary w-100" [attr.disabled]="reservationFormTemp && !reservationFormTemp.valid ? true : null">Continuar</button>
  <div  class="d-flex align-items-center py-3"><hr class="w-100"><div class="px-3" *transloco="let t; scope : 'auth'">{{t('auth.o')}}</div><hr class="w-100"></div>
  <a class="btn btn-outline-info facebook-sign  w-100 mb-3" href="javascript:void(0);" *transloco="let t; scope : 'auth'" (click)="signInWithFB()"><img src="/assets/img/icons/fb-icon.svg" class="me-2" width="25" height="25">{{t('auth.login_con_facebook')}}</a>
  <app-googlesignupbutton></app-googlesignupbutton>
  <ul class="nav flex-column mt-4">
    <li><i class="fi-lock me-2"></i>No publicamos sin tu autorización</li>
    <li><i class="fi-rotate-right me-2"></i>¡Esto es mucho más rapido!</li>
  </ul>
</form>

</div>
</ng-container>

<ng-container *ngIf="reservationTemp == true && addons !== undefined && addons.length > 0 && show_ReservationForm == false">
  <div class="restaurant-info" *ngIf="!isLoading && !is_external">
    <h2 class="h4">{{Publication.name  ? Publication.name : Publication.publicationName }}</h2>
    <div class="d-flex flex-column flex-md-row">
      <div class="flex-shrink-0 pe-1">
        <img [src]=" Publication.perfilImage ? storage + Publication.perfilImage : storage  + Publication.publicationPerfilImage" class="rounded" [alt]="Publication.name" width="250" height="250">
      </div>
      <div class="flex-grow-1 mt-4 mt-md-2 ms-4 pe-4 pe-md-0">
        <div class="d-flex flex-row align-items-center mb-3">
          <span class="d-flex align-items-center"><i class="fi-star-filled text-warning me-1" ></i> {{Publication.average}} <strong>({{Publication.totalReviews}})</strong></span>
          <span class="d-flex align-items-center ms-3"><i class="fi-map-pin text-primary me-1" ></i>{{Publication.masDistrictName}}</span>
        </div>
        <div class="row row-cols-2 gy-2 w-md-85 align-items-center bg-gray-500 pb-2 px-2 mb-3 rounded-pill">
            <div class="col border-end pe-2">
              <p class="mb-0 d-flex align-items-center"><i class="fi-calendar me-2"></i>{{reservationData['date'] | date:'E dd MMM'}}</p>
            </div>
            <div class="col ps-3">
              <p class="mb-0 d-flex align-items-center"><i class="fi-clock me-2"></i>{{reservationData['hour'] | time}}</p>
            </div>
          </div>
          <div class="row row-cols-2 gy-2 w-md-85 align-items-center bg-gray-500 pb-2 px-2 mb-3 rounded-pill">
          <div class="col border-end pe-2">
            <p class="mb-0 d-flex align-items-center"><i class="fi-users me-2"></i>{{reservationData['num_guests']}} personas</p>
          </div>
          <div class="col ps-3">
            <p class="mb-0 d-flex align-items-center"><mat-icon fontIcon="table_restaurant" class="material-icons-outlined me-0"></mat-icon>{{reservationData['zone_name'] ? reservationData['zone_name'] : 'Estándar'}}</p>
          </div>

        </div>

      </div>
    </div>
  </div>
  <h4 class="mt-3" style="font-size: 20px;">Agregar extras <span class="fs-sm">(opcional)</span></h4>
  <ng-container *ngFor="let addon of addons">
    <div class="addon d-flex flex-column" style="margin-bottom: 2px;">
      <div class="addons-meta d-flex justify-content-between align-items-center">
        <div>
          <h6 class="mb-0" style="font-size: 16px;">{{addon.title}}</h6>
          <span class="fs-sm">(El precio es por persona)</span>
        </div>
        <div>
          <span class="fw-bold">{{addon.amount | currencyconvert}}</span>
        </div>
        <div class="d-flex align-items-center">
        <button class="btn p-0 text-primary" (click)="RemoveAddon(addon)"><i class="fi-minus-circle fs-lg"></i></button>
        <span class="addon-qty mx-2">{{addon.quantity}}</span>
        <button class="btn p-0 text-primary" (click)="addAddon(addon)"><i class="fi-plus-circle fs-lg"></i></button>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="d-flex justify-content-center mt-3">
    <button class="btn btn-primary btn-lg rounded-pill" (click)="show_ReservationForm = true" >Continuar</button>
  </div>
  </ng-container>
<ng-container *ngIf="reservationTemp == true && reservationSuccess == false && show_ReservationForm == true">
  <div *ngIf="isLoading && !is_external">
    <facebook-content-loader></facebook-content-loader>
  </div>
  <div class="restaurant-info" *ngIf="!isLoading  && !is_external">
    <h2 class="h4">{{Publication.name  ? Publication.name : Publication.publicationName }}</h2>
    <div class="d-flex flex-column flex-md-row">
      <div class="flex-shrink-0 pe-1">
        <img [src]=" Publication.perfilImage ? storage + Publication.perfilImage : storage  + Publication.publicationPerfilImage" class="rounded" [alt]="Publication.name" width="250" height="250">
      </div>
      <div class="flex-grow-1 mt-4 mt-md-2 ms-4 pe-4 pe-md-0">
        <p class="mb-0"><span *ngFor="let cuisine of Publication.cuisines" class="me-1">{{cuisine.name}},</span></p>
        <div class="d-flex flex-row align-items-center mb-3">
          <span class="d-flex align-items-center"><i class="fi-star-filled text-warning me-1" ></i> {{Publication.average}} <strong>({{Publication.totalReviews}})</strong></span>
          <span class="d-flex align-items-center ms-3"><i class="fi-map-pin text-primary me-1" ></i>{{Publication.masDistrictName}}</span>
        </div>
        <div class="row row-cols-2 gy-2 w-md-85 align-items-center bg-gray-500 pb-2 px-2 mb-3 rounded-pill">
            <div class="col border-end pe-2">
              <p class="mb-0 d-flex align-items-center"><i class="fi-calendar me-2"></i>{{reservationData['date'] | date:'E dd MMM'}}</p>
            </div>
            <div class="col ps-3">
              <p class="mb-0 d-flex align-items-center"><i class="fi-clock me-2"></i>{{reservationData['hour'] | time}}</p>
            </div>
          </div>
          <div class="row row-cols-2 gy-2 w-md-85 align-items-center bg-gray-500 pb-2 px-2 mb-3 rounded-pill">
          <div class="col border-end pe-2">
            <p class="mb-0 d-flex align-items-center"><i class="fi-users me-2"></i>{{reservationData['num_guests']}} personas</p>
          </div>
          <div class="col ps-3">
            <p class="mb-0 d-flex align-items-center"><mat-icon fontIcon="table_restaurant" class="material-icons-outlined me-0"></mat-icon>{{reservationData['zone_name'] ? reservationData['zone_name'] : 'Estándar'}}</p>
          </div>

        </div>

      </div>
    </div>
  </div>
<div class="px-2 mt-3">
  <p class="text-center text-primary bg-gray-500 py-1 rounded">Te guardamos esto durante :  <countdown [config]="countDownConfig"></countdown></p>
  <div class="experiencia mb-4" *ngIf="reservationData.experience_id !== false">
    <h6>Experiencia reservada</h6>
    <div class="d-flex justify-content-between bg-gray-500 p-2 mb-2 rounded">
      <p class="mb-0">{{reservationData['num_guests']+ ' x ' +SelectedExperience.title}}</p>
      <div class="payment-type d-flex">
        <p class="mb-0">{{(reservationData['num_guests']*SelectedExperience.discountAmount) | currencyconvert}}<p>
      </div>
    </div>
    <ng-container *ngFor="let addon of addons">
      <div class="d-flex justify-content-between bg-gray-500 align-items-center p-2 mb-2 rounded" *ngIf="addon.quantity > 0">
        <div><span>{{addon.quantity}} x </span> {{addon.title}}</div>
        <div>{{addonSubtotal(addon) | currencyconvert}}</div>
      </div>
    </ng-container>
    <div class="d-flex justify-content-end mb-5">
      <p class="mb-0 fw-bold mt-2">{{SelectedExperience.paymentTypeExperience == 2 ? 'Total por pagar' : 'Total por pagar en restaurante'}}: {{calcularTotalaPagar() | currencyconvert}}</p>
    </div>
  </div>
  <form novalidate [formGroup]="reservationForm" (ngSubmit)="CreateReservation()">
  <div class="row">
    <div class="col-12">
      <h6>Detalles del comensal</h6>
    </div>
    <div class="col-12 col-md-6">
      <div class="mb-3">
        <label for="firstname" class="form-label fw-bold">Nombre</label>
        <input class="form-control" type="text" id="firstname"  [(ngModel)]="reservationData.firstname"
        [ngModelOptions]="{standalone: true}" [ngClass]="reservationForm_submitted && formData['guest'].get('first_name')?.hasError('required')  ? 'is-invalid' : '' ">
        <div class="invalid-feedback" *ngIf="reservationForm_submitted && formData['guest'].get('first_name')?.hasError('required')">
          Por favor, escribe tu Nombre
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="mb-3">
        <label for="lastname" class="form-label fw-bold" >Apellido</label>
        <input class="form-control" type="text" id="lastname"  [(ngModel)]="reservationData.lastname"
        [ngModelOptions]="{standalone: true}" [ngClass]="reservationForm_submitted && formData['guest'].get('last_name')?.hasError('required')  ? 'is-invalid' : '' ">
        <div class="invalid-feedback" *ngIf="reservationForm_submitted && formData['guest'].get('last_name')?.hasError('required')">
          Por favor, escribe tu Apellido
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="mb-3">
      <label for="phone" class="form-label fw-bold">Número de teléfono</label>
      <ngx-intl-tel-input
      [preferredCountries]="[CountryISO.Mexico, CountryISO.UnitedKingdom]"
      [enableAutoCountrySelect]="false"
      [enablePlaceholder]="false"
      [searchCountryFlag]="true"
      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
      [selectFirstCountry]="false"
      [selectedCountryISO]="CountryISO.Mexico"
      [maxLength]="15"
      [separateDialCode]="true"
      [phoneValidation]="false"
        [(ngModel)]="reservationData.phone"
        [ngModelOptions]="{standalone: true}"
        [cssClass]="reservationForm_submitted && formData['guest'].get('phone')?.hasError('required')  ? 'form-control w-100 is-invalid' : 'form-control w-100' "
    >
  </ngx-intl-tel-input>
  <div class="d-block invalid-feedback" *ngIf="reservationForm_submitted && formData['guest'].get('phone')?.hasError('required')">
    Por favor, escribe tu Número de Teléfono
  </div>

    </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="mb-3">
        <label for="email" class="form-label fw-bold">Correo electrónico</label>
        <input class="form-control" type="text" id="email" [ngModel]="reservationData.email" [ngModelOptions]="{standalone: true}" disabled>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="mb-3">
        <label for="ocasion" class="form-label fw-bold">Seleccione una ocasión</label>
        <select class="form-select" id="ocasion" formControlName="conversion_id">
          <option *ngFor="let ocassion of ocassions" [value]="ocassion.id">{{ocassion.name}}</option>
        </select>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="mb-3">
        <label for="text-input" class="form-label">Agregar una solicitud especial <span class="fs-xs">(opcional)</span></label>
        <input class="form-control" type="text" id="text-input" formControlName="note" placeholder="Escribir mensaje">
      </div>
    </div>
    </div>
    <hr style="margin-top: 15px;">
    <!-- Experience Payment-->

<div class="experience-payment mt-4"  *ngIf="this.SelectedExperience.paymentTypeExperience == 2">
  <app-alert [message]="error.message" [show]="error.show"></app-alert>
  <div class="d-flex justify-content-between align-items-center mb-4">
    <h6>Método de pago</h6>
    <img src="/assets/img/icons/cards.png" width="148" height="43">
  </div>
  <div class="row row-cols-2 row-cols-md-3 gy-3 gx-2 justify-content-center payment-method-list mt-3 mb-3 px-3">
    <div class="col payment-method-item" *ngFor="let card of cards;">
      <div class="card" [ngClass]="card_selected.id == card.id ? 'card_active' : ''">
        <a class="stretched-link" href="javascript:void(0)" (click)="selectPaymentMethod(card)"></a>
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <div>
            <img src="/assets/img/logo_visa.png" alt="logo_payment_method" width="33" height="13">
            </div>
          </div>
          <p class="mb-0 card-number">{{card.pan}}</p>
        </div>
      </div>
    </div>


    <div class="col payment-method-item add-method">
      <div class="card" (click)="card_selected = 0" [ngClass]="card_selected == 0? 'card_active' : ''">
        <a class="stretched-link" href="javascript:void(0)"></a>
        <div class="card-body d-flex justify-content-center align-items-center">
          <div class="d-flex justify-content-center text-center">
            <p class="mb-0"><mat-icon fontIcon="add_circle"></mat-icon><br>
            Añadir tarjeta</p>
          </div>
        </div>
      </div>
    </div>

</div>

<div [formGroup]="paymentFormWithToken" *ngIf="card_selected !== 0">
  <div formGroupName="noPresentCardData">
    <div class="mb-3">
      <input class="form-control" type="text" id="cvv" formControlName="cvv" placeholder="cvv">
    </div>
  </div>

</div>
<div [formGroup]="paymentForm" *ngIf="card_selected  == 0 ">
  <h6 class="fs-sm">Información de la tarjeta</h6>
    <div class="row mb-3" formGroupName="cardData">
      <div class="col-12 col-md-12">
        <div class="mb-3">
          <input class="form-control" type="text" id="cardholderName" formControlName="cardholderName" placeholder="Nombre del titular de la tarjeta">
        </div>
      </div>
      <div class="col-12 col-md-12">
        <div class="mb-3">
          <input class="form-control" type="text" id="cardNumber"  data-format="card" formControlName="cardNumber" placeholder="Número de tarjeta">
        </div>
      </div>
      <div class="col-12 col-md-8 row row-cols-2 mx-0 px-0">
        <div class="col">
          <div class="mb-3">
            <input class="form-control" type="text" id="expirationYear" formControlName="expirationYear" placeholder="Año de expiración">
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <input class="form-control" type="text" id="expirationMonth" formControlName="expirationMonth" placeholder="Mes de expiración">
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
          <div class="mb-3">
            <input class="form-control w-100" type="text" id="cvv" formControlName="cvv" placeholder="CVV">
          </div>
      </div>
    </div>
    <h6 class="fs-sm">Información de Facturación</h6>
    <div class="row mb-3" formGroupName="customerInformation">
      <div class="col-12 col-md-6">
        <div class="mb-3">
          <input class="form-control" type="text" id="firstname" formControlName="firstName" placeholder="Nombre">
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="mb-3">
          <input class="form-control" type="text" id="middleName" formControlName="middleName" placeholder="Segundo Nombre">
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="mb-3">
          <input class="form-control" type="text" id="lastname" formControlName="lastName" placeholder="Apellido">
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="mb-3">
          <input class="form-control" type="text" id="emailCustomer" formControlName="email" placeholder="Correo electrónico">
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="mb-3">
          <input class="form-control" type="text" id="phoneCustomer" formControlName="phone1" placeholder="Número de teléfono">
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="mb-3">
          <input class="form-control" type="text" id="country" formControlName="country" placeholder="País">
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="mb-3">
          <input class="form-control" type="text" id="state" formControlName="state" placeholder="Estado">
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="mb-3">
          <input class="form-control" type="text" id="city" formControlName="city" placeholder="Ciudad">
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="mb-3">
          <input class="form-control" type="text" id="address1" formControlName="address1" placeholder="Dirección">
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="mb-3">
          <input class="form-control" type="text" id="postalCode" formControlName="postalCode" placeholder="Código Postal">
        </div>
      </div>



    </div>
    </div>
</div>
    <div class="row mt-3">
    <div class="col-12">
      <div class="form-check">
      <input class="form-check-input" id="check-1" type="checkbox" formControlName="notification_restaurant_offer">
      <label class="form-check-label" for="check-1" style="width:95%; margin-left: 5px;font-size: 14px;">Darme de alta para recibir ofertas gastronómicas y noticias de este restaurante por email.</label>
      </div>
    </div>
    <div class="col-12">
      <div class="form-check">
      <input class="form-check-input" id="check-2" type="checkbox" formControlName="notification_masmesa_offer">
      <label class="form-check-label" for="check-2" style="width:95%; margin-left: 5px;font-size: 14px;">Darme de alta para recibir ofertas gastronómicas y novedades de +mesa por email.</label>
      </div>
    </div>
    <div class="col-12">
      <div class="form-check">
      <input class="form-check-input" id="check-3" type="checkbox" formControlName="notification_reserva">
      <label class="form-check-label" for="check-3" style="width:95%; margin-left: 5px;font-size: 14px;">Sí, quiero recibir actualizaciones de texto y recordatorios sobre mis reservas.</label>
      </div>
    </div>
    <div class="col-12 mb-3 mt-3 d-flex justify-content-center">
      <button type="submit" class="btn btn-primary rounded-pill" >Confirmar reservación</button>
    </div>
    <div class="col-12">
      <p style="font-size: 14px;">Al hacer clic en "Confirmar reserva" y aceptas los <strong>términos de uso</strong> y la <strong>política de privacidad de +mesa</strong>. se puede aplicar una tarifa de mensaje de texto estándar. puede optar por no recibir mensajes de texto en cualquier momento.</p>
    </div>
  </div>
</form>
</div>
</ng-container>

<ng-container *ngIf="reservationSuccess == true">
<div class="p-5 text-center">
  <h2><i class="fi-checkbox-checked-alt text-success fs-2 me-2"></i>Tu reserva esta confirmada</h2>
  <p>Recibirás un correo de confirmación.</p>
  <a class="btn btn-outline-success rounded" *ngIf="isLoggedin && !is_external" [routerLink]="'/account/reservations/'+ reservationID + '/details' " (click)="modaldismiss()">Ir a mis reservaciones</a>
  <a class="btn btn-outline-success rounded" *ngIf="!isLoggedin || is_external" (click)="modaldismiss()">Aceptar</a>
</div>
</ng-container>

import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, Inject, Query, QueryList, Renderer2, ViewChildren } from '@angular/core';


import { environment } from 'src/environments/environment';
import { OneSignal } from 'onesignal-ngx';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements AfterViewInit {
  title = 'Masmesa';
  @HostListener("dragstart", ["$event"]) onDragOver(evt : any) {
    evt.preventDefault();
  }
  constructor(private oneSignal : OneSignal) {
    if(typeof document !== 'undefined') {


      this.oneSignal.init({
        appId: "0c4a164b-b7cd-4a73-9c42-8b5433f32cb0",
        safari_web_id: "web.onesignal.auto.528b6caf-1e6b-4608-b4e6-faa03f146a9c",
        notifyButton: {
          enable: true,
        },
        subdomainName: "masmesa",
      });

  }
}

  ngAfterViewInit(): void {

  }

}



<div id="reservation-mobile-map" class="d-block d-md-flex  position-relative"  *transloco="let t">
  <div class="form-group d-block">
    <div class="row g-0 ms-sm-n2">
        <div class="col input-group border-end-sm me-1">
            <i class="fi-calendar position-absolute top-50 start-0 translate-middle-y ms-3"></i>
            <input type="text" class="form-control form-control-sm"  [(ngModel)]="AvailabilityQuery.date" type="text" #datepicker_calendar_mini>
        </div>
        <div class="col input-group border-end-sm me-1">
            <i class="fi-clock position-absolute top-50 start-0 translate-middle-y ms-3"></i>
            <select class="form-control ps-5" [(ngModel)]="AvailabilityQuery.hour">
              <option value="00:00">12:00 AM</option>
              <option value="01:00">01:00 AM</option>
              <option value="02:00">02:00 AM</option>
              <option value="03:00">03:00 AM</option>
              <option value="04:00">04:00 AM</option>
              <option value="05:00">05:00 AM</option>
              <option value="06:00">06:00 AM</option>
              <option value="07:00">07:00 AM</option>
              <option value="08:00">08:00 AM</option>
              <option value="09:00">09:00 AM</option>
              <option value="10:00">10:00 AM</option>
              <option value="11:00">11:00 AM</option>
              <option value="12:00">12:00 PM</option>
              <option value="13:00">01:00 PM</option>
              <option value="14:00">02:00 PM</option>
              <option value="15:00">03:00 PM</option>
              <option value="16:00">04:00 PM</option>
              <option value="17:00">05:00 PM</option>
              <option value="18:00">06:00 PM</option>
              <option value="19:00">07:00 PM</option>
              <option value="20:00">08:00 PM</option>
              <option value="21:00">09:00 PM</option>
              <option value="22:00">10:00 PM</option>
              <option value="23:00">11:00 PM</option>
            </select>
       </div>

        <div class="col input-group border-end-sm">
          <i class="fi-user position-absolute  top-50 start-0 translate-middle-y ps-1" style="padding-left: 8px;"></i>
          <select class="form-select ms-1" id="select-input" [(ngModel)]="AvailabilityQuery.guests" *transloco="let t; scope: 'home'" style="padding-right: 0;
          margin-right: -21px; padding-left: 1.5rem; background-image: none !important;">
            <option value="1">1 {{t('persona')}}</option>
            <option value="2" selected>2 {{t('personas')}}</option>
            <option value="3">3 {{t('personas')}}</option>
            <option value="4">4 {{t('personas')}}</option>
            <option value="5">5 {{t('personas')}}</option>
            <option value="6">6 {{t('personas')}}</option>
            <option value="7">7 {{t('personas')}}</option>
            <option value="8">8 {{t('personas')}}</option>
            <option value="9">9 {{t('personas')}}</option>
            <option value="10">10 {{t('personas')}}</option>
          </select>
      </div>

    </div>

     <div class="row">
      <div class="col input-group">
        <span class="input-group-text text-muted">
          <i class="fi-search"></i>
        </span>

         <input class="form-control border-start-0 rounded" type="text"   [formControl]="myControl"  *transloco="let t" placeholder="Ubicación, restaurante, cocina.">


      </div>
     <div class="col-auto">
      <button class="btn btn-primary btn-sm rounded-circle" type="button" (click)="SearchPublications()" style="width: 36px;padding: 6.5px 6px;"><i class="fi-search"></i></button>
    </div>

  </div>
  </div>
</div>






/**
 * Categories List
 */
 const categoriesData = [
  {
    icon: "none",
    title: "Restaurantes",
    id: "1",
    bg_color: "primary"
  },
  {
    icon: "fi-cocktail",
    title: "Comer y Beber",
    id: "5",
    bg_color: "warning"
  },
  {
    icon: "fi-disco-ball",
    title: "Vida Nocturna",
    id: "2",
    bg_color: "accent2"
  },
  {
    icon: "fi-alert-circle",
    title: "Club de Playa",
    id: "3",
    bg_color: "success"
  },
  {
    icon: "fi-ticket",
    title: "Eventos",
    id: "4",
    bg_color: "primary"
  },
];

/**
 * Berlin List
 */
 const publicationsData = [
  {
    image: "assets/img/restaurants/01.jpg",
    title: "Harry's Cancun Prime Steaks",
    rating: "5.0",
    people_count: "48",
    price: "$$",
    location: "cancún",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
    reserved_count: 12,
  },
  {
    image: "assets/img/restaurants/02.jpg",
    title: "Taboo Cancun",
    rating: "4.8",
    people_count: "24",
    price: "$$$",
    location: "cancún",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
    reserved_count: 12
  },
  {
    image: "assets/img/restaurants/03.jpg",
    title: "Ryoshi Cancun",
    rating: "4.9",
    people_count: "43",
    price: "$$$",
    location: "cancun",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
    reserved_count: 12
  },
  {
    image: "assets/img/restaurants/04.jpg",
    title: "Rosa Negra Cancun",
    rating: "4.5",
    people_count: "13",
    price: "$$",
    location: "cancun",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
    reserved_count: 12
  },
  {
    image: "assets/img/restaurants/05.jpg",
    title: "Porfirio's Cancun",
    rating: "4.5",
    people_count: "13",
    price: "$$",
    location: "cancun",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
    reserved_count: 12
  },
];

/**
 * Events List
 */
 const eventsData = [
  {
    image: "assets/img/city-guide/home/upcoming-1.jpg",
    date: "Nov 15",
    time: "21:00",
    title: "Simon Rock Concert",
    price: "50",
  },
  {
    image: "assets/img/city-guide/home/upcoming-2.jpg",
    date: "Dec 2",
    time: "10:00",
    title: "Holi Festival",
    price: "35",
  },
  {
    image: "assets/img/city-guide/home/upcoming-3.jpg",
    date: "No 11",
    time: "18:00",
    title: "Football Match",
    price: "40",
  },
];

/**
 * New Berlin List
 */
 const newBerlinData = [
  {
    image: "assets/img/rueda_de_la_fortuna.jpg",
    title: "Rueda de la fortuna",
    location: "Ollenhauer Str. 29, 10118",
    time: "9:00 – 23:00",
    price: "$$",
    content: "Blandit lorem dictum in velit. Et nisi at faucibus mauris pretium enim. Risus sapien nisi aliquam egestas leo dignissim ut quis ac. Amet, cras orci justo, tortor nisl aliquet. Enim tincidunt tellus nunc, nulla arcu posuere quis. Velit turpis orci venenatis risus felis, volutpat convallis varius. Enim non euismod adipiscing a enim."
  },
  {
    image: "assets/img/city-guide/home/new-2.jpg",
    title: "Mall of Berlin",
    location: "Ollenhauer Str. 29, 10118",
    time: "10:00 – 20:00",
    price: "$$",
    content: "Sem nibh urna id arcu. Quis tortor vestibulum morbi volutpat. Et duis et sed tellus. Egestas ultrices viverra in pretium nec. Dui ornare fusce vel fringilla scelerisque posuere pharetra ut. Dui donec sapien, dictum nunc varius."
  },
];

/**
 * Blog List
 */
 const blogData = [
  {
    image: "assets/img/city-guide/blog/01.jpg",
    sub_title: "Travelling",
    title: "Air Travel in the Time of COVID-19",
    profile: "assets/img/avatars/16.png",
    name: "Bessie Cooper",
    date: "May 24",
    comments: "No"
  },
  {
    image: "assets/img/city-guide/blog/02.jpg",
    sub_title: "Entertainment",
    title: "10 World-Class Museums You Can Visit Online",
    profile: "assets/img/avatars/18.png",
    name: "Annette Black",
    date: "Apr 28",
    comments: "4"
  },
  {
    image: "assets/img/city-guide/blog/03.jpg",
    sub_title: "Travelling",
    title: "7 Tips for Solo Travelers in Africa",
    profile: "assets/img/avatars/17.png",
    name: "Ralph Edwards",
    date: "Apr 15",
    comments: "2"
  },
];

export { categoriesData, publicationsData, eventsData, newBerlinData, blogData };

import {Directive, ElementRef} from '@angular/core';
import {take} from 'rxjs';
import {SocialAuthService} from '@abacritt/angularx-social-login';
@Directive({
  selector: 'app-googlesignupbutton',

})
export class GooglesignupbuttonComponent {

  constructor(private el : ElementRef, private SocialAuth : SocialAuthService) {
    SocialAuth.initState.pipe(take(1)).subscribe(() => {
      // @ts-ignore
      google.accounts.id.renderButton(el.nativeElement, {
        theme: "outline",
        size: "large",
        "data-logo_alignment": "left",
        "width": '249px',
      });
    })


  }


}

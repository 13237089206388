import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {
  private APIurl = environment.ApiPublicaciones;
  constructor(private _http : HttpClient) { }

  /**
   * @description Método para suscribirse a la newsletter
   * @param email
   * @returns Observable
   */

  public suscribirse(email : string){
    return this._http.post(`${this.APIurl}/subscribe`, {email: email});
  }
}

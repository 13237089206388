<div class="restaurant-info" *ngIf="!hasReservation">
  <ng-container *ngIf="!is_external">
  <h2 class="h4">{{Publication.name  ? Publication.name : Publication.publicationName }}</h2>
  <div class="d-flex flex-column flex-md-row">
    <div class="flex-shrink-0 pe-1 pe-md-3">
      <img [src]=" Publication.perfilImage ? storage + Publication.perfilImage : storage  + Publication.publicationPerfilImage" class="rounded" [alt]="Publication.name" width="250" height="250">
    </div>
    <div class="flex-grow-1 mt-4 mt-md-2 ms-2 pe-4 pe-md-0">
      <p class="mb-0"></p>
      <div class="d-flex flex-row align-items-center mb-3">
        <span class="d-flex align-items-center"><i class="fi-star-filled text-warning me-1"></i> {{Publication.average}} <strong class="ps-1">({{Publication.totalReviews}})</strong></span>
        <span class="d-flex align-items-center ms-3"><i class="fi-map-pin text-primary me-1"></i>{{Publication.masDistrictName}}</span>
      </div>
      <div class="row row-cols-2 gy-2 w-md-85 align-items-center bg-gray-500 pb-2 px-2 mb-3 rounded-pill">
          <div class="col border-end pe-2">
            <p class="mb-0 d-flex align-items-center"><i class="fi-calendar me-2"></i>{{reservationData['date'] | date:'E dd MMM'}}</p>
          </div>
          <div class="col ps-3">
            <p class="mb-0 d-flex align-items-center"><i class="fi-clock me-2"></i>{{reservationData['hour'] | time}}</p>
          </div>
        </div>
        <div class="row row-cols-2 gy-2 w-md-85 align-items-center bg-gray-500 pb-2 px-2 mb-3 rounded-pill">
        <div class="col border-end pe-2">
          <p class="mb-0 d-flex align-items-center"><i class="fi-users me-2"></i>{{reservationData['num_guests']}} personas</p>
        </div>
        <div class="col ps-3">
          <p class="mb-0 d-flex align-items-center"><mat-icon fontIcon="table_restaurant" class="material-icons-outlined me-0"></mat-icon>{{reservationData['zone_name'] !== undefined? reservationData['zone_name'] : 'Estándar'}}</p>
        </div>

      </div>

    </div>
  </div>
  </ng-container>
  <div class="select-reservation-type my-3">
    <h6 class="text-primary">Selecciona tu tipo de reserva</h6>
    <div class="experiences-options my-3">
      <ng-container *ngIf="Publication.experiences &&  Publication.experiences.length > 0">
      <div class="mb-4" *ngFor="let experience of Publication.experiences">
        <input type="radio" class="btn-check" id="promo-{{experience.experienceId}}" name="promo" [(ngModel)]="reservationData['experience_id']" [value]="experience.experienceId">
        <label class="position-relative btn btn-sm btn-outline-secondary fw-normal text-start w-100" for="promo-{{experience.experienceId}}">
          <div class="d-flex align-items-center">
            <div class="flex-shrink-0">
              <img class="experience-image" [src]="storage_experiences + experience.image" [alt]="experience.title">
            </div>
            <div class="flex-grow-1 ms-3 d-flex flex-column">
              <div class="position-absolute top-0 end-0 pt-1 pe-1">
                <mat-icon fontIcon="radio_button_checked" *ngIf="reservationData['experience_id'] == experience.experienceId"></mat-icon>
                <mat-icon fontIcon="radio_button_unchecked" *ngIf="reservationData['experience_id'] !== experience.experienceId"></mat-icon>
              </div>
              <strong>{{experience.title}}</strong>
              <p class="mb-0"><i class="fi-credit-card pe-1"></i><span class="pe-2 text-decoration-line-through" *ngIf="experience.amount > experience.discountAmount">{{experience.amount | currencyconvert}}</span>{{experience.discountAmount | currencyconvert}} por persona<br/><span class="ms-1 badge bg-primary fw-bold mt-2" *ngIf="experience.discountrate > 0">{{experience.discountrate+'% OFF'}}</span><span class="ms-1 badge bg-success fw-bold mt-2"><i class="fi-credit-card pe-1" style="font-size: 11px;position: relative;top: -1px;"></i>Prepago</span></p><br/>
            </div>
          </div>
        </label>
      </div>
      </ng-container>
        <div class="mb-4">
          <input type="radio" class="btn-check" id="sin_promo" name="promo" [(ngModel)]="reservationData['experience_id']" [value]="false">
          <label class="btn btn-sm btn-outline-secondary fw-normal text-start w-100" for="sin_promo">
            <div class="position-absolute top-0 end-0 pt-1 pe-1">
              <mat-icon fontIcon="radio_button_checked" *ngIf="reservationData['experience_id'] === false"></mat-icon>
              <mat-icon fontIcon="radio_button_unchecked" *ngIf="reservationData['experience_id'] !== false"></mat-icon>
            </div>
            <strong>Reservar en {{Publication.publicationName}}</strong><br/>
            <span>Reservacion con Menú a la carta sin oferta</span><br/>
          </label>
        </div>

    </div>
    <div class="d-flex justify-content-center">
      <button class="btn btn-outline-primary rounded-pill" (click)="hasReservation = true">Crear reservación</button>
    </div>
  </div>
</div>

<ng-container *ngIf="hasReservation">
  <app-reservationform [reservationData]="reservationData" [publicationId]="publicationId ? publicationId : Publication.publicationId" [Publication]="Publication" [is_external]="is_external"></app-reservationform>
</ng-container>



<div class="top-bar">
    <div class="container">
        <div class="d-flex flex-row">
                <div class="justify-content-start">
                    <ul class="nav">
                        <li class="nav-item"><a href="tel:+1 800 123 4567" class="nav-link"><i class="fi-phone me-md-2"></i> <span class="d-none d-md-inline-block">+1 800 123 4567</span></a></li>
                        <li class="nav-item"><a href="mailto:info@masmesa.com"  class="nav-link"><i class="fi-mail me-md-2"></i> <span class="d-none d-md-inline-block">Info@masmesa.com</span></a></li>
                    </ul>
                </div>
                <div class="ms-auto">
                  <ul class="nav">
                      <li class="nav-item px-2"><div class="dropdown mt-2" ngbDropdown>
                        <a class="nav-link nav-link-light dropdown-toggle fs-sm align-items-center p-0 fw-normal" href="javascript:void(0);" id="langSwitcher" data-bs-toggle="dropdown" role="button" aria-expanded="false" ngbDropdownToggle>
                          <i class="fi-globe mt-n1 me-1 align-middle"></i>{{ActiveLang}}
                        </a>
                        <ul class="dropdown-menu dropdown-menu-dark my-1" aria-labelledby="langSwitcher" ngbDropdownMenu>
                          <li><a class="dropdown-item text-nowrap py-1" href="javascript:void(0);" (click)="ChangeLanguage('en')"><img class="me-2" src="assets/img/flags/en.png" width="20" alt="English">English</a></li>
                          <li><a class="dropdown-item text-nowrap py-1" href="javascript:void(0);" (click)="ChangeLanguage('es')"><img class="me-2" src="assets/img/flags/es.png" width="20" alt="Español">Español</a></li>
                        </ul>
                      </div></li>
                      <li class="nav-item px-2"><div class="dropdown mt-2" ngbDropdown>
                        <a class="nav-link nav-link-light dropdown-toggle fs-sm align-items-center p-0 fw-normal" href="javascript:void(0);" id="currencySwitcher" data-bs-toggle="dropdown" role="button" aria-expanded="false" ngbDropdownToggle>
                          <i class="fi-cash mt-n1 me-1 align-middle"></i>USD
                        </a>
                        <ul class="dropdown-menu dropdown-menu-dark my-1" aria-labelledby="currencySwitcher" ngbDropdownMenu>
                          <li><a class="dropdown-item text-nowrap py-1" href="javascript:void(0);">USD</a></li>
                          <li><a class="dropdown-item text-nowrap py-1" href="javascript:void(0);">MXN</a></li>
                        </ul>
                      </div></li>
                      <li class="nav-item px-2">
                        <a href="javascript:void(0)" (click)="ToggleMode()"><mat-icon  fontIcon="wb_sunny" *ngIf="dark"></mat-icon><mat-icon  fontIcon="dark_mode" *ngIf="!dark"></mat-icon></a>

                      </li>
                  </ul>
              </div>
        </div>
  </div>
</div>

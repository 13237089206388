import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, tap } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { MessagesService } from '../services/messages.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private _auth: AuthService,
    private _router: Router, private _message: MessagesService, private _transloco: TranslocoService) { }

     canActivate (): Observable<boolean> | boolean{
    return this._auth.verifyToken()
    .pipe(
      tap( valid => {
        if ( !valid ) {
          this._router.navigateByUrl('').then(
            () => {
              this._auth.logout();
              this._message.show('error', 'Debes iniciar sesión', 'danger');
            }
          );
          /*
          this._transloco.selectTranslate('error_token_invalido')
          .subscribe(value => {
            this._message.show('error', value, 'danger');
          });
          */

        }
      })
    );
    }
}

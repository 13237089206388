import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { ReservationsService } from 'src/app/core/services/reservations.service';
import { ExperiencesService } from 'src/app/core/services/experiences.service';
import { environment } from 'src/environments/environment';
import { PublicationsService } from 'src/app/core/services/publications.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {SsrCookieService} from 'ngx-cookie-service-ssr';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { SocketService } from 'src/app/core/services/socket.service';
import { PaymentsService } from 'src/app/core/services/payments.service';
@Component({
  selector: 'app-reservationform',
  templateUrl: './reservationform.component.html',
})
export class ReservationformComponent implements OnInit, OnDestroy {

@Input() publicationId!: number;
@Input() reservationData!: any;
@Input() Publication!: any;
@Input() is_external : boolean = false;
private publication_id! : number;
private reservationCode : string = "";
SelectedExperience : any = {};
storage = environment.Storage;
reservationTemp : boolean = false;
timer : any = "00:00";
reservationFormTemp! : FormGroup;
reservationForm! : FormGroup;
isLoading = true;
ocassions : any[] =  [];
countDownConfig = {
  leftTime : 5 * 60,
}
has_Experience = false;
show_ReservationForm = false;
isLoggedin = false;
addons! : any[];
reservationSuccess = false;
reservationID : number = 0;
reservationForm_submitted = false;
private timeoutNavigate : any;
// Telephone Input

separateDialCode = false;
SearchCountryField = SearchCountryField;
CountryISO = CountryISO;
PhoneNumberFormat = PhoneNumberFormat;
preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
is_reservation_temp_created = false;
is_confirmed_reservation: boolean = false;
paymentForm! : UntypedFormGroup;
error : any = {
  show : false,
  message : ""
};
cards : any = [];
card_selected : any = 0;
paymentFormWithToken! : FormGroup;
constructor(private _reservations : ReservationsService, private _formBuilder : FormBuilder,
  private _router : ActivatedRoute, private _auth : AuthService,
  private _experiences : ExperiencesService, private _publication : PublicationsService,
   private _route : Router, private _ngbModal : NgbModal, private _ssrcookie : SsrCookieService,
   private _notifications : NotificationsService , private _socket : SocketService, private _payment: PaymentsService) {

    }

    selectPaymentMethod(card : any) {
      this.card_selected = card;
    }

  public CreateReservationTemp() {
    if(this.reservationFormTemp.invalid) {
      return;
    }


    this._reservations.CreateTemporalReservation(this.publicationId, this.reservationFormTemp.value).subscribe((response : any) => {
      this.reservationData['code'] = response.body.data.code;
      this.reservationData['email'] = this.reservationFormTemp.controls['email'].value;
      this.reservationTemp = true;

      if(this.reservationData.experience_id) {
        let reserve_experience = new FormGroup({
          reservationId : new FormControl(this.reservationData['code']),
          personQty : new FormControl(this.reservationData['num_guests']),
          percentageTip : new FormControl(Number(20))
        })
        this._experiences.CreateExperienceReservation(this.reservationData.experience_id, reserve_experience.value);

      }
      this.GetPublication();
    });
  }

get formData() {
  return this.reservationForm.controls;
}

 async PayReservationWithExperience() {
  this.paymentForm.controls['reservationId'].setValue(this.reservationData['code']);

 const payment =  new Promise((resolve, reject) => {
    this._payment.sendPayment(this.paymentForm.value).subscribe({
      next: (response : any) => {
        if(response.success) {
          resolve(response);
        } else {
          reject(response);
        }
      },
      error: (response) => {
        this.error.message = response.error.msg;
        console.log("error", response.error.msg)
        this.error.show = true;
        reject(new Error(response.error.msg));
      }
    });
  })

  // Si la promesa falla, termina la ejecución de la función
  try {
    await payment;
  } catch (error) {
    throw error;

  }


}

async PayReservationExperienceWithToken() {
  this.paymentFormWithToken.controls['noPresentCardData'].patchValue({
    cardToken : this.card_selected.id,
  });

  this.paymentFormWithToken.controls['customerinformation'].patchValue({
    firstName : this.card_selected.firstName,
    lastName : this.card_selected.lastName,
  });

 this.paymentFormWithToken.controls['reservationId'].setValue(this.reservationData['code']);

  const payment =  new Promise((resolve, reject) => {
    this._payment.sendPaymentToken(this.paymentFormWithToken.value).subscribe({
      next: (response : any) => {
        if(response.success) {
          resolve(response);
        } else {
          reject(response);
        }
      },
      error: (response : any) => {
        this.error.message = response.error.msg;
        console.log("error", response.error.msg)
        this.error.show = true;
        reject(new Error(response.error.msg));
      }
    });
  });

  // Si la promesa falla, termina la ejecución de la función

  try {
    await payment;
  } catch (error) {
    throw error;

  }

}
  public async CreateReservation() {
    this.reservationForm.controls['code'].setValue(this.reservationData['code']);



 this.reservationForm.controls['guest'].patchValue({
  first_name : this.reservationData.firstname,
  last_name : this.reservationData.lastname,
  phone : this.reservationData.phone ? this.reservationData.phone.number.replace(/\s/g,'') : '',
  country : this.reservationData.phone? this.reservationData.phone.countryCode : '',
  });

  this.reservationForm_submitted = true;


    if(this.reservationForm.invalid) {
      return;
    }

    if(this.SelectedExperience.paymentTypeExperience == 2) {

      if(this.card_selected == 0) {
   await this.PayReservationWithExperience()
      }
      else {
        await this.PayReservationExperienceWithToken();
      }


    }


    this._reservations.CreateReservation(this.publicationId,this.reservationForm.value, this.reservationData['code']).subscribe((response : any) => {
     if(response.status == 201) {
       this.reservationSuccess = true;
       this.reservationID = response.body.data.id;
        this.is_confirmed_reservation = true;

      this._socket.emit('noti-message-publication-reserva',
      {mas_publications_id : this.publicationId,
        title : "Nueva Reservación en " + this.Publication.name,
        description: "Se ha realizado una nueva reservación en tu publicación",
        action : "create",
        noti_types_id : 1});
        if(this.isLoggedin && !this.is_external){
       this.timeoutNavigate  = setTimeout(() => {
        this._route.navigate(['/account/reservations/'+ this.reservationID + '/details']);
        this._ngbModal.dismissAll();
    }, 5000);  //5
  }

     }
    });





  }


  calcularTotalaPagar() {
    let total = 0;

    if(this.SelectedExperience) {
      total+= this.reservationData['num_guests']*this.SelectedExperience.discountAmount;

      if(this.addons && this.addons.length > 0) {
      this.addons.forEach((addon : any) => {
        total+= addon.quantity * addon.discountAmount;
      });
    }
  }

  return total;

}
  signInWithFB(): void {
    this._auth.siginWithFacebook();
  }

  public addAddon(addon : any) {
    if(addon.quantity == addon.quantityToSelect)  {
      return;
    }
    this._experiences.ChangeAddonQuantity(this.reservationData.experience_id, addon.id, addon.quantity + 1, this.reservationData['code']).subscribe((response : any) => {
      if(response.success) {
        addon.quantity = addon.quantity + 1;
      }
    });

  }

  public RemoveAddon (addon : any) {
    if(addon.quantity == 0) {
      return;
    }
    this._experiences.ChangeAddonQuantity(this.reservationData.experience_id, addon.id, addon.quantity - 1, this.reservationData['code']).subscribe((response : any) => {
      if(response.success) {
        addon.quantity = addon.quantity - 1;
      }
    });
  }

  public addonSubtotal(addon : any) {
    return addon.quantity * addon.discountAmount;
  }

 guestformgroup() {
    return this.reservationForm.get('guest') as FormGroup;
  }

  GetPublication() {
    this._reservations.GetTemporaryReservations(this.reservationData.code).subscribe((response : any) => {
      if(response.success) {
        this._publication.GetPublicationPublic(response.data.ms_microsite_id).subscribe((request : any) => {
          if(request.success) {
            this.Publication = request.data;
            this.isLoading = false;
          }
        });
      }
    });
  }

  public modaldismiss() {
    this._ngbModal.dismissAll();
    clearTimeout(this.timeoutNavigate);
  }

  ngOnInit(): void {
console.log("publicationId", this.publicationId);
    this.reservationFormTemp = this._formBuilder.group({
      email : ['', [Validators.required, Validators.email]],
      date : [this.reservationData.date, Validators.required],
      hour : [this.reservationData.hour, Validators.required],
      index: [this.reservationData.index, Validators.required],
      num_guests : [this.reservationData.num_guests, Validators.required],
      zone_id : [this.reservationData.zone_id],

    });


    this.reservationForm = this._formBuilder.group({
      conversion_id : [1, {validators : Validators.required, updateOn : 'change'}],
      note : '',
      guest: this._formBuilder.group({
        first_name : ['', {validators : Validators.required, updateOn : 'blur'}],
        last_name : ['', {validators : Validators.required, updateOn : 'blur'}],
        phone : ['', {validators : Validators.required, updateOn : 'blur'}],
        country : ['', {validators : Validators.required, updateOn : 'blur'}],
      }),
      code : [this.reservationData['code'], {validators : Validators.required, updateOn : 'change'}],
      notification_updates : 1,
      notification_restaurant_offer : 0,
      notification_masmesa_offer : 0,
      notification_reserva : 0,

    });


    this.paymentForm = this._formBuilder.group({
      paymentGatewaysId : [1, {validators : Validators.required, updateOn : 'change'}],
      currency : ['484', {validators : Validators.required, updateOn : 'change'}],
      publicationId : [this.publicationId, {validators : Validators.required, updateOn : 'change'}],
      brandId : [this.reservationData['brand_id'], {validators : Validators.required, updateOn : 'change'}],
      reservationId: [this.reservationData['code'], {validators : Validators.required, updateOn : 'change'}],
      cardData : this._formBuilder.group({
        cardNumber : ['', {validators : Validators.required, updateOn : 'change'}],
        cvv : ['', {validators : Validators.required, updateOn : 'change'}],
        cardholderName: ['', {validators : Validators.required, updateOn : 'change'}],
        expirationYear : ['', {validators : Validators.required, updateOn : 'change'}],
        expirationMonth: ['', {validators : Validators.required, updateOn : 'change'}],
      }),
      customerInformation : this._formBuilder.group({
        firstName : ['', {validators : Validators.required, updateOn : 'change'}],
        middleName: ['', {validators : Validators.required, updateOn : 'change'}],
        lastName : ['', {validators : Validators.required, updateOn : 'change'}],
        email : ['', {validators : Validators.required, updateOn : 'change'}],
        phone1 : ['', {validators : Validators.required, updateOn : 'change'}],
        city : ['', {validators : Validators.required, updateOn : 'change'}],
        address1 : ['', {validators : Validators.required, updateOn : 'change'}],
        postalCode : ['', {validators : Validators.required, updateOn : 'change'}],
        state : ['', {validators : Validators.required, updateOn : 'change'}],
        country : ['', {validators : Validators.required, updateOn : 'change'}],
      }),



    });

    this.paymentFormWithToken = this._formBuilder.group({
      paymentGatewaysId : [1, {validators : Validators.required, updateOn : 'change'}],
      publicationId : [this.publicationId, {validators : Validators.required, updateOn : 'change'}],
      brandId : [this.reservationData['brand_id'], {validators : Validators.required, updateOn : 'change'}],
      reservationId: [this.reservationData['code'], {validators : Validators.required, updateOn : 'change'}],
      currency : ['484', {validators : Validators.required, updateOn : 'change'}],
      noPresentCardData : this._formBuilder.group({

        cardToken : ['', {validators : Validators.required, updateOn : 'change'}],
        cvv : ['', {validators : Validators.required, updateOn : 'change'}],
      }),
      customerinformation : this._formBuilder.group({
        firstName : ['', {validators : Validators.required, updateOn : 'change'}],
        lastName : ['', {validators : Validators.required, updateOn : 'change'}],
      }),
    });

      if( this.reservationData.experience_id === false) {
        this.show_ReservationForm = true;

      } else {
         this._experiences.getExperience(this.reservationData.experience_id).subscribe((response : any) => {
          this.SelectedExperience = response.data;
          console.log("SelectedExperience", this.SelectedExperience);
        });

       this._experiences.GetAddonsForExperience(this.reservationData.experience_id).subscribe((response : any) => {
        if(response.data.length > 0) {
          this.addons = response.data;
          this.addons.forEach( (addon : any) => {
            addon.quantity = 0;
          });

          if(this.isLoggedin) {
            this.isLoading = false;
            this.reservationFormTemp.controls['email'].setValue(this._auth.usuario.email);
          this.CreateReservationTemp();

           }

        } else {
          this.show_ReservationForm = true;
          if(this.isLoggedin) {
            this.reservationFormTemp.controls['email'].setValue(this._auth.usuario.email);
            this.CreateReservationTemp();
            this.show_ReservationForm = true;
            this.reservationTemp = true;
            this.reservationSuccess = false;


          }
        }
       });

      }




      this._auth.isLoggedin$.subscribe((response : any) => {
        this.isLoggedin = response;
        if(this.isLoggedin && this.show_ReservationForm == true && this.reservationTemp == false) {
          this.reservationFormTemp.controls['email'].setValue(this._auth.usuario.email);
           this.CreateReservationTemp();
           this.show_ReservationForm = true;
           this.reservationTemp = true;
           this.reservationSuccess = false;

        }

      });




      this._reservations.ListDescriptions().subscribe((response : any) => {
        this.ocassions = response.body.data.occasion;
      });

       this._payment.getCardsByUser(1).subscribe((response: any) => {
        this.cards = response.data;
        if(this.cards.length == 0) {
          return;
        }
        let card_selected = this.cards.find((card : any) => card.isDefault == true);
        if(card_selected) {
          this.card_selected = card_selected;
        }

      });

}

  ngOnDestroy(): void {


    if(!this.is_confirmed_reservation) {
      this._reservations.CancelTemporalReservation(this.reservationData['code']).subscribe((response : any) => {
        if(response.success) {
          this._ssrcookie.delete('ReservationData');
        }
      });
    }
    this.reservationData = [];

  }
}

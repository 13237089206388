import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(private _http: HttpClient ) { }

  private  url = environment.blog_api;
  GetPosts(){
    let url = this.url + 'wp-json/wp/v2/posts/?per_page=6';
    return this._http.get(url);
  }

  GetFeaturedImage(id: any){
    return this._http.get(this.url + 'wp-json/wp/v2/media/' + id)
  }

  GetUser(id: any){
    return this._http.get(this.url + 'wp-json/wp/v2/users/' + id)
  }

}

<!-- Danger toast -->
<ngb-toast *ngFor="let message of messageService.messages"  class="toast fade position-fixed bottom-0 end-0 {{message.type}}" [autohide]="true" [delay]="5000" (hidden)="messageService.remove(message)">
  <ng-template ngbToastHeader class="">
    <div class="me-auto">
      <i class="fas fa-exclamation-triangle"></i>
      <span class="ms-2">{{message.header}}</span>
    </div>
  </ng-template>
  <div class="toast-body {{'text-'+message.type}}">{{message.body}}</div>
</ngb-toast>


  <ul class="navbar-nav navbar-nav-scroll" style="max-height: 35rem;">
    <!-- Cities -->
    <li class="nav-item dropdown me-lg-2"><a class="nav-link dropdown-toggle align-items-center pe-lg-4" href="javascript:void(0);" data-bs-toggle="dropdown" role="button" aria-expanded="false" (click)="demosDroupDownClick()"><img src="assets/img/icons/pin.svg" class="me-2" width="13" height="15" />{{selectedDistrict?.name }}</a>
      <div class="dropdown-menu" style="width: 680px;">
        <div class="row row-cols-3 gx-0">
          <div class="col">
            <li class="nav-item"><h6 class="dropdown-header">Estados</h6></li>
            <li><hr class="dropdown-divider"></li>
            <ngx-simplebar style="max-height: 380px" class="location-nav">
              <li class="nav-item" *ngFor="let state of States"  [ngClass]="selectedState.id == state.id ? 'active' : ''"><a class="nav-link" (click)="changeState(state.id)">{{state.name}}</a></li>
            </ngx-simplebar>
          </div>
          <div class="col">
            <li class="nav-item"><h6 class="dropdown-header">Municipios</h6></li>
            <li><hr class="dropdown-divider"></li>
            <ngx-simplebar style="max-height: 380px" class="location-nav">
              <li class="nav-item" *ngFor="let municipio of Citys"  [ngClass]="selectedMunicipio.id == municipio.id ? 'active' : ''" (click)="changeCity(municipio.id)"><a class="nav-link">{{municipio.name}} </a></li>
            </ngx-simplebar>
          </div>
          <div class="col">
            <li class="nav-item"><h6 class="dropdown-header">Distrito</h6></li>
            <li><hr class="dropdown-divider"></li>
            <ngx-simplebar style="max-height: 380px" class="location-nav">
              <li class="nav-item" *ngFor="let district of Districts" [ngClass]="selectedDistrict.id == district.id ? 'active' : ''" (click)="changeDistrict(district.id)"><a class="nav-link">{{district.name}}</a></li>
            </ngx-simplebar>
          </div>
        </div>
      </div>
    </li>
    </ul>


<div class="experience-reservation-form" *ngIf="!hasReservation">
<h2 style="font-size: 20px">{{Experience.title}}</h2>

<div class="d-flex flex-column flex-md-row align-items-center">
  <div class="flex-shrink-0">
    <img class="experience-image rounded" [src]="storage + Experience.image" [alt]="Experience.title" width="222" height="173">
  </div>
  <div class="flex-grow-1 ms-3">
   <h6 class="fs-base mb-1">{{Experience.publicationName}}</h6>
   <ul class="nav flex-column">
    <li class="d-flex align-items-center"><i class="star-score fi-star-filled text-warning pe-1"></i> {{Publication.average}} <span class="fw-bold ps-1">({{Publication.totalReviews}})</span></li>
    <li class="d-flex align-items-center"><span *ngFor="let cuisine of Publication.cuisines">{{cuisine.name}}</span></li>
    <li><span class="border-end pe-2"><i class="fi-map-pin pe-1 pb-1"></i><span style="font-size: 14px;"> {{Experience.district}}</span></span> <span class="ps-2" style="font-size: 14px;">{{Publication.address}}</span></li>
    <li class="d-flex align-items-center"><i class="fi-credit-card pe-2"></i> <span style="font-size: 14px;">{{Experience.discountAmount | currencyconvert}} por Persona</span></li>
  </ul>


  </div>
</div>

<hr class="my-3">
<!-- Desktop -->
<ng-container *ngIf="!is_mobile">
<form class="experience-availability-form d-flex flex-column flex-md-row" [formGroup]="checkform" (submit)="checkAvailability()">
  <div class="form-group d-block rounded-md-pill mb-3 mx-md-2 d-flex flex-row w-md-75" style="height: 50px;">
    <div class="input-group border-end-sm me-1">
      <i class="fi-calendar position-absolute top-50 start-0 translate-middle-y ms-3"></i>
      <input class="form-control date-picker-search rounded" formControlName="date" type="text" #datepicker_calendar_experience style="padding-left: 40px;">
    </div>
    <div class="input-group border-end-sm me-1" style="color: #000;">
      <i class="fi-clock position-absolute top-50 start-0 translate-middle-y ms-3" style=" font-size: 20px;"></i>
      <select class="form-control ps-5" formControlName="hour">
        <option value="00:00:00">12:00 AM</option>
        <option value="01:00:00">01:00 AM</option>
        <option value="02:00:00">02:00 AM</option>
        <option value="03:00:00">03:00 AM</option>
        <option value="04:00:00">04:00 AM</option>
        <option value="05:00:00">05:00 AM</option>
        <option value="06:00:00">06:00 AM</option>
        <option value="07:00:00">07:00 AM</option>
        <option value="08:00:00">08:00 AM</option>
        <option value="09:00:00">09:00 AM</option>
        <option value="10:00:00">10:00 AM</option>
        <option value="11:00:00">11:00 AM</option>
        <option value="12:00:00">12:00 PM</option>
        <option value="13:00:00">01:00 PM</option>
        <option value="14:00:00">02:00 PM</option>
        <option value="15:00:00">03:00 PM</option>
        <option value="16:00:00">04:00 PM</option>
        <option value="17:00:00">05:00 PM</option>
        <option value="18:00:00">06:00 PM</option>
        <option value="19:00:00">07:00 PM</option>
        <option value="20:00:00">08:00 PM</option>
        <option value="21:00:00">09:00 PM</option>
        <option value="22:00:00">10:00 PM</option>
        <option value="23:00:00">11:00 PM</option>
      </select>
    </div>
    <div class="input-group people-select ps-4">
      <i class="fi-user position-absolute  top-50 start-0 translate-middle-y ms-3"></i>
          <select class="form-select" formControlName="num_guests"  *transloco="let t; scope: 'home'">
            <option value="1">1 {{t('persona')}}</option>
            <option value="2" selected>2 {{t('personas')}}</option>
            <option value="3">3 {{t('personas')}}</option>
            <option value="4">4 {{t('personas')}}</option>
            <option value="5">5 {{t('personas')}}</option>
            <option value="6">6 {{t('personas')}}</option>
            <option value="7">7 {{t('personas')}}</option>
            <option value="8">8 {{t('personas')}}</option>
            <option value="9">9 {{t('personas')}}</option>
            <option value="10">10 {{t('personas')}}</option>
          </select>
  </div>
  </div>
  <button class="btn btn-primary rounded-pill w-100 w-md-25 mb-3 ms-sm-3"  type="submit">Buscar mesa</button>
</form>
</ng-container>
<!-- Mobile -->
<ng-container *ngIf="is_mobile">
  <form class="experience-availability-form d-flex flex-column flex-md-row" [formGroup]="checkform" (submit)="checkAvailability()">
    <div class="form-group px-3 py-1 rounded-pill mb-3">
      <div class="input-group w-50 border-end me-1">
        <i class="fi-calendar position-absolute top-50 start-0 translate-middle-y ms-3"></i>
        <input class="form-control date-picker-search rounded" formControlName="date" type="text" #datepicker_calendar_experience style="padding-left: 40px;">
      </div>
      <div class="input-group  w-50 me-1" style="color: #000;">
        <i class="fi-clock position-absolute top-50 start-0 translate-middle-y ms-3" style=" font-size: 20px;"></i>
        <select class="form-control ps-5" formControlName="hour">
          <option value="00:00:00">12:00 AM</option>
          <option value="01:00:00">01:00 AM</option>
          <option value="02:00:00">02:00 AM</option>
          <option value="03:00:00">03:00 AM</option>
          <option value="04:00:00">04:00 AM</option>
          <option value="05:00:00">05:00 AM</option>
          <option value="06:00:00">06:00 AM</option>
          <option value="07:00:00">07:00 AM</option>
          <option value="08:00:00">08:00 AM</option>
          <option value="09:00:00">09:00 AM</option>
          <option value="10:00:00">10:00 AM</option>
          <option value="11:00:00">11:00 AM</option>
          <option value="12:00:00">12:00 PM</option>
          <option value="13:00:00">01:00 PM</option>
          <option value="14:00:00">02:00 PM</option>
          <option value="15:00:00">03:00 PM</option>
          <option value="16:00:00">04:00 PM</option>
          <option value="17:00:00">05:00 PM</option>
          <option value="18:00:00">06:00 PM</option>
          <option value="19:00:00">07:00 PM</option>
          <option value="20:00:00">08:00 PM</option>
          <option value="21:00:00">09:00 PM</option>
          <option value="22:00:00">10:00 PM</option>
          <option value="23:00:00">11:00 PM</option>
        </select>
      </div>
    </div>
    <div class="form-group px-3 py-0 rounded-pill mb-3">
      <div class="input-group people-select ps-4 w-100">
        <i class="fi-user position-absolute  top-50 start-0 translate-middle-y ms-3"></i>
            <select class="form-select" formControlName="num_guests"  *transloco="let t; scope: 'home'">
              <option value="1">1 {{t('persona')}}</option>
              <option value="2" selected>2 {{t('personas')}}</option>
              <option value="3">3 {{t('personas')}}</option>
              <option value="4">4 {{t('personas')}}</option>
              <option value="5">5 {{t('personas')}}</option>
              <option value="6">6 {{t('personas')}}</option>
              <option value="7">7 {{t('personas')}}</option>
              <option value="8">8 {{t('personas')}}</option>
              <option value="9">9 {{t('personas')}}</option>
              <option value="10">10 {{t('personas')}}</option>
            </select>
    </div>
    </div>

    <button class="btn btn-primary rounded-pill w-100 w-md-25 mb-3 ms-sm-3"  type="submit">Buscar mesa</button>
  </form>
  </ng-container>

<hr class="mb-3">
<div class="results d-flex flex-row justify-content-between mb-4" *ngIf="hassubmitted && hour_available">
  <div class="horario position-relative" *ngFor="let hour of Publication.availability" [ngClass]="[!hour.availability ? 'hour-disabled' : '', hour == selectedHour ? 'active-hour' : '']" (click)="selectHour(hour)">
    <ng-container *ngIf="hour.availability">
      <span class="hora">{{hour.hour | time}}</span>
      <span class="descuento" *ngIf="Experience.discountrate > 0">{{Experience.discountrate}}%</span>
    </ng-container>
  </div>
</div>
<div class="not-available mb-3" *ngIf="hassubmitted && !hour_available">
  <p>No hay disponibilidad para la fecha y hora seleccionada</p>
</div>
<div class="mb-4" *ngIf="selectedHour.form">
  <ng-container *ngIf="selectedHour.form.zone_ids">
  <h6 class="fs-base mb-3">Selecciona tu opción de mesa</h6>
  <div class="row">
    <div class="col-auto" *ngFor="let zone of selectedHour.form.zone_ids">
      <input type="radio" class="btn-check" id="zone-{{zone.id}}" [value]="zone.id" autocomplete="off" [(ngModel)]="selectedZone">
    <label class="btn btn-outline-primary" for="zone-{{zone.id}}">{{zone.name}}</label>

    </div>
  </div>
</ng-container>
<ng-container *ngIf="selectedHour.form && selectedHour.form.zone_ids && selectedZone || selectedHour.form.zone_ids && selectedHour.form.zone_ids.length == 0 ">
  <div class="d-flex justify-content-center my-4">
  <button class="btn btn-primary rounded-pill shadow-sm w-50 w-md-25" style="padding: 10px 20px;" (click)="crearReservacion()">Reservar</button>
  </div>
</ng-container>
</div>

<h6 class="fs-base">Sobre {{Experience.title}}</h6>
<p style="font-size: 14px">{{Experience.description}}</p>

</div>

<ng-container *ngIf="hasReservation">
  <app-reservationform [reservationData]="ReservationData" [publicationId]="Experience.publicationId" [Publication]="Publication"></app-reservationform>
</ng-container>

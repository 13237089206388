import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gender'
})
export class GenderPipe implements PipeTransform {

  transform(gender: string): string {
    if(gender == 'M') {
      return 'Masculino';
    }

    if(gender == 'F') {
      return 'Femenino';
    }

    if(gender == 'O') {
      return 'Otro';
    }

    return 'No especificado';

  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImgFallbackDirective } from './directives/imgfallback.directive';
import { TimePipe } from './pipes/time.pipe';
import { CurrencyPipe } from './pipes/currency.pipe';
import { GenderPipe } from './pipes/gender.pipe';
import { GridtimePipe } from './pipes/gridtime.pipe';





@NgModule({
  declarations: [
    ImgFallbackDirective,
    TimePipe,
    CurrencyPipe,
    GenderPipe,
    GridtimePipe,
    ],
  imports: [
    CommonModule,
  ],
  exports: [
    ImgFallbackDirective,
    TimePipe,
    CurrencyPipe,
    GenderPipe,
    GridtimePipe
  ]
})
export class CoreModule { }

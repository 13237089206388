import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgbDropdown, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Splide from '@splidejs/splide';
// Swiper Slider
import { SwiperComponent, SwiperDirective } from 'ngx-swiper-wrapper';

import { categories, publications, events, newBerlin, blog } from './home.model';
import { categoriesData, publicationsData, eventsData, newBerlinData, blogData } from './data';
import { TranslocoService } from '@ngneat/transloco';
import { filter, pluck } from 'rxjs';
import { SearchService } from 'src/app/core/services/search.service';
import { PublicationsService } from 'src/app/core/services/publications.service';
import { environment } from 'src/environments/environment';
import { BlogService } from 'src/app/core/services/blog.service';
import { ExperiencesService } from 'src/app/core/services/experiences.service';
import { UserService } from 'src/app/core/services/user.service';
import * as moment from 'moment';
import { DateTime, easepick } from '@easepick/bundle';
import { ReservationsService } from 'src/app/core/services/reservations.service';
import { GeolocationComponent } from 'src/app/shared/geolocation/geolocation.component';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})

/**
 * Home Component
 */
export class HomeComponent implements OnInit, AfterViewInit {
  BaseURl = environment.baseUrl;
  ubicacion = "Cancún";
  categoriesData!: any[];
  almuerzosData!: publications[];
  comidaalirelibre!:publications[];
  eventsData!: events[];
  newBerlinData!: newBerlin[];
  blogData!: blog[];
  storage = environment.Storage
  storage_experiences = environment.Storage_experiences;
  is_publication_loading = true;
  is_experience_loading = true;
  is_blog_loading = true;
  calendar : any ;
  CalendarMobile : any;
  ReservationDetails : any = {};
  selectedExperience : any = {};
  PublicationsAlaireFake : any = [];
  private suggestions : any = [];
private recentSearch : any = [];
disableTooltip : boolean = false;
  private Publication: any = [];
  private blogPosts: any = [];
  private blogImages: any = [];
  private blogAuthors: any = [];
  private experiences: any = [];
  private availableDates : any = [];
  is_mobile : boolean = false;
  private expQueryParams: any = {

    page : 1,
    maxPerPage : 6,
    date : 0
  };
  @ViewChild(SwiperComponent, { static: false }) componentRef?: SwiperComponent;
  @ViewChild(SwiperDirective, { static: false }) directiveRef?: SwiperDirective;

  @ViewChild('splidetest', {static: true}) splide!: ElementRef;
  @ViewChild('splidedesayuno', {static: true}) splidedesayuno!: ElementRef;
  @ViewChild('splidequehay', {static: true}) splidequehay!: ElementRef;
  @ViewChild('splidealaire', {static: true}) splidealaire!: ElementRef;
  @ViewChild('splideblogslider',  {static: true}) splideblogslider!: ElementRef;
  @ViewChild('filterExperiencesCalendar', {static: false}) filterExperiencesCalendar!: ElementRef;
  @ViewChild('filterExperiencesCalendarMobile', {static: false}) filterExperiencesCalendarMobile!: ElementRef;

  @ViewChildren('splideitem') splides!: QueryList<ElementRef>;
  @ViewChildren('splideitemdesayuno') splideitemdesayuno!: QueryList<ElementRef>;
  @ViewChildren('splideitemquehay') splideitemquehay!: QueryList<ElementRef>;
  @ViewChildren('splideitemalaire') splideitemalaire!: QueryList<ElementRef>;
  @ViewChildren('splideitemblog') splideitemblog!: QueryList<ElementRef>;
  term = '';
  AvailabilityQuery : any = {
    date : moment().format('YYYY-MM-DD'),
    guests : 2,
};


  constructor(private modalService: NgbModal, private transloco: TranslocoService,
     private _search : SearchService, private _publications : PublicationsService,
     private _blog : BlogService, private _experiences : ExperiencesService,
     private _user : UserService, private _reservations : ReservationsService,
     private _ngbmodal: NgbModal, private _ssrcookies : SsrCookieService, private breakpointObserver : BreakpointObserver) {
      this.suggestions =   {
          location : [
            {
              state : []
            },
            {
              city : []
            },
            {
              district : []
            }
          ],
          publications : []
        }
      ;
      }



openAvailabilityExperienceModal(modal : any, experience : any) {
  let sizeModal = 'modal-mediano';

  if(this.is_mobile) {
    sizeModal = 'fullscreen';
  }
  this.selectedExperience = experience;
  this._ngbmodal.open(modal, {size: sizeModal, centered: true});

}

  FilterExperience(option : any) {
    this.experiences = [];
    this.expQueryParams['page'] = 1;
    this.expQueryParams['maxPerPage'] = 6;
    this.expQueryParams['date'] = option;
    this._experiences.ListPublicExperience(this.expQueryParams).subscribe((response:any) => {
      this.experiences = response.data.data;
    });
  }

  /**
   * Get the recent searches
   * @returns Array
   * @param $event
   */



  public GetRecentSearches() {
    this.recentSearch = this._search.GetRecentSearches();
  }

  /*
   Check user hour and get if dinner, breakfast or lunch
   */

public GetHour(): string {
  const hour = moment().hours();
  if(hour >= 0 && hour < 12) {
    return 'breakfast';
  } else if(hour >= 12 && hour < 18) {
    return 'lunch';
  } else {
    return 'dinner';
  }
}

 get Suggestions() {
  return this.suggestions;
 }

 get Publications() {
  return this.Publication;
  }

  get BlogPosts() {
    return this.blogPosts;
  }

  get BlogImages() {
    return this.blogImages;

  }

  get BlogAuthors() {
    return this.blogAuthors;
  }

  get Experiences() {
    return this.experiences;
  }

  public GetDiscount(publication_range : any, hour : any) {
    let formatedHour = moment(hour, 'HH:mm:ss').format('HH:mm');
    let range = publication_range;
    let rangeHour = range.find((item : any) => item.rangeHour.includes(formatedHour));

    if(rangeHour) {
      return rangeHour.discount_rate;
    }

    return 0;
    }

    public Create_reserva(modal : any, hour: any, zone_id : any, publication_range : any, brand_id : number) {
      let experience_id = false;
      let zone = [];
      if(hour.form.zone_ids){
zone = hour.form.zone_ids.find((zone : any) => {
      return zone.id == zone_id;
     });

    }

     if(!zone) {
    zone['name'] = 'Standard'
    }

     this.ReservationDetails = {
     experience_id: experience_id,
     date : moment().format('YYYY-MM-DD'),
     hour : hour.hour,
     index: hour.index,
     zone_id : zone_id,
     num_guests: 2,
     zone_name : zone.name,
     brand_id : brand_id,

     }
     let Modalsize = 'modal-mediano';

     if(this.is_mobile) {
      Modalsize = 'fullscreen';
     }
     this._ngbmodal.open(modal, {size: Modalsize, centered: true, backdrop: 'static' });

   }


   CancelTemporaryReservation(modal : any) {
    let cancelModal = this._ngbmodal.open(modal, {size: 'modal-mediano', centered: true, backdrop: 'static' });
    cancelModal.result.then((result:any) => {
    if(result == 'cancelar') {
      this._ssrcookies.delete('ReservationData');
      this._ssrcookies.delete('ReservationExpires');
      this._ngbmodal.dismissAll();
    }
    });



   }


   dropDownOpen(isOpen : any) {
    console.log("isOpen", isOpen);
    if (typeof window !== 'undefined'){
      if (window.screen.width <= 678) {
        this.disableTooltip = false
      }
    }

    this.disableTooltip = isOpen;

  }

  ngOnInit(): void {
    this._fetchData();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if(typeof window !== 'undefined'){
    this.GetRecentSearches();
    }
    this._experiences.ListPublicExperience().subscribe((response:any) => {
      this.experiences = response.data.data;
      this.is_experience_loading = false;
    });
    let hour;
    if(moment().hours() < 10) {
      hour = '0'+moment().hours()+':00';
    } else
    {
     hour = moment().hours()+':00';
    }
    let datetime = moment().format('YYYY-MM-DD') + ' ' + hour;

    this._publications.findpublications({'timezone' : timezone, 'datetime' : datetime}).subscribe((response:any) => {
      this.Publication = response.data.data;
      let date = moment(datetime, 'Y-MM-DD H:mm:ss').format('YYYY-MM-DD');
 let hour = moment(datetime, 'Y-MM-DD H:mm:ss').format('HH:mm:ss');
  let publications_ids : any = [];
  this.Publication.forEach((publication:any) => {
    publications_ids.push(publication.publicationId);
  });
    this._reservations.GetAvailabilityMultiplePublications(publications_ids, date, hour, 2).subscribe((response:any) => {
     this.availableDates = response.body.data;
     this.availableDates.forEach((date:any) => {
        this.Publication.forEach((publication:any) => {
          if(date.mas_publication_id == publication.publicationId){
            publication.availableDates = date;
          }
        });
     });
     this.Publication.sort(function(a:any, b:any){
      return ( b.availableDates.status_availability  ===  a.availableDates.status_availability)? 0 : b.availableDates.status_availability? 1 : -1;

     });
     this.PublicationsAlaireFake = JSON.parse(JSON.stringify(this.Publication));
     this.PublicationsAlaireFake.reverse();
    });
      this.is_publication_loading = false;
    });


    this._blog.GetPosts().subscribe((response:any) => {
      this.blogPosts = response;
      this.blogPosts.forEach((element:any) => {
        this._blog.GetFeaturedImage(element.featured_media).subscribe((response:any) => {
          this.blogImages[element.id]= response.source_url;
        })

        this._blog.GetUser(element.author).subscribe((response:any) => {
          this.blogAuthors[element.id]= response;
          this.is_blog_loading = false;
        });
    });
  });





  }





  // Chat Data Fetch
  private _fetchData() {
    this.categoriesData = categoriesData;
    this.almuerzosData = publicationsData;
    this.eventsData = eventsData;
    this.newBerlinData = newBerlinData;
    this.blogData = blogData;
  }

  /**
   * Publicaitions Swiper setting
   */
   publications = {
    initialSlide: 0,
    slidesPerView: 2,
    spaceBetween: 7,
    navigation: true,
    pagination: true,
    loop: false,
    breakpoints:{
      768:{
        slidesPerView: 2,
        spaceBetween: 10,
      },
      1200:{
        slidesPerView: 5,
        spaceBetween: 0,

      }
    }
  };

  /**
   * events Swiper setting
   */
   experiencesconf = {
    initialSlide: 0,
    slidesPerView: 1,
    spaceBetween: 20,
    navigation: true,
    pagination: true,
    loop: false,
    breakpoints:{
      992:{
        slidesPerView: 4,
      }
    }
  };

  nuevoconf = {
    initialSlide: 0,
    slidesPerView: 1,
    spaceBetween: 20,
    navigation: true,
    pagination: false,
    loop: true,
    breakpoints:{
      992:{
        slidesPerView: 3,
        pagination: true,
      }
    }
  };

  /**
   * events Swiper setting
   */
   newBerlin = {
    initialSlide: 0,
    slidesPerView: 1,
    navigation: true,
    loop: true,
  };

   /**
   * Blogs Swiper setting
   */
    blogs = {
      initialSlide: 0,
      slidesPerView: 1,
      spaceBetween: 20,
      navigation: true,
      loop: true,
      breakpoints:{
        768:{
          slidesPerView: 2,
        },
        1200:{
          slidesPerView: 3,
        }
      }
    };

ngAfterViewInit(): void {
  this.breakpointObserver
  .observe([
    Breakpoints.XSmall,
    Breakpoints.Small,
    Breakpoints.Medium,
    Breakpoints.Large,
    Breakpoints.XLarge,
  ])
  .subscribe(result => {
    if (result.breakpoints[Breakpoints.XSmall]) {
      this.is_mobile = true;
    } else if (result.breakpoints[Breakpoints.Small]) {
      this.is_mobile = true;
    }
    else {
      this.is_mobile = false;
    }

  });


  if (typeof window !== 'undefined'){


    let splide = new Splide(this.splide.nativeElement, {
      type   : 'slider',
      perPage: 4,
      perMove: 1,
      gap    : '32px',
      width : '1316px',
      fixedWidth: '296px',
      padding: { left: '20px', right: '16px'},
      pagination: false,
      breakpoints: {
        1300: {
          type   : 'loop',
      perPage: 3,
      perMove: 1,
      gap    : '35px',
      width : '95vw',
      fixedWidth: '310px',
      padding: { left: '20px', right: '16px'},
        },

        1200: {
          fixedWidth: '280px',
          width : '100vw',
          arrows: false,
          perPage: 1,
          pagination: true,
          gap : '11px',
          padding: { left: '0', right: '0' },
        }
      }
    })

  this.splides.changes.subscribe(() => {

    if ( splide.state.is( Splide.STATES.IDLE ) ) {
      splide.refresh();
    } else {
      splide.mount();
    }


  }

  );

  let splidequehay = new Splide(this.splidequehay.nativeElement, {
    type   : 'slide',
    clones : 0,
    perPage: 3,
    perMove: 1,
    gap    : '36px',
    fixedWidth: '394.85px',
    width : '1295px',
    padding: { left: '16px', right: '16px', bottom: '25px' },
    breakpoints: {

      768: {
        width: '100%',
        fixedWidth: '296px',
        arrows: false,
        perPage: 1,
        gap : '20px',
        padding: { left: '20px', right: '0px' },
      }
    }
  })
  this.splideitemquehay.changes.subscribe(() => {
    if ( splidequehay.state.is( Splide.STATES.IDLE ) ) {
      splidequehay.refresh();
    } else {
      splidequehay.mount();
    }


  }
  );
  let splidedesayuno = new Splide(this.splidedesayuno.nativeElement, {
    type   : 'slide',
    clones : 0,
    rewind : true,
    perPage: 5,
    perMove: 1,
    pagination: false,
    gap    : '18px',
    drag: true,
    width : '1295px',
    fixedWidth: '241.13px',
    padding: { left: '10px', bottom: '25px'},
    breakpoints: {
      1300: {
        type   : 'slide',
        clones : 0,
        rewind : true,
        perPage: 4,
        perMove: 1,
        pagination: false,
        gap    : '18px',
        drag: true,
        width : '95vw',
        fixedWidth: '240px',
        padding: { left: '5px', bottom: '25px'},
      },
      1200: {
        type   : 'loop',
        clones : 1,
        fixedWidth: '243px',
        width : '100vw',
        drag: true,
        arrows: false,
        perPage: 1,
        pagination: true,
        gap : '1rem',
        padding: { left: '0px', right: '0px' },
      }
    }
  })

  this.splideitemdesayuno.changes.subscribe(() => {
    if ( splidedesayuno.state.is( Splide.STATES.IDLE ) ) {
      splidedesayuno.refresh();
    } else {
      splidedesayuno.mount();
    }
  }
  );



  this.splideitemalaire.changes.subscribe(() => {
    let splidealaire = new Splide(this.splidealaire.nativeElement, {
      type   : 'slide',
      clones : 0,
      rewind : true,
      perPage: 5,
      perMove: 1,
      pagination: false,
      gap    : '18px',
      drag: true,
      width : '1295px',
      fixedWidth: '241.13px',
      padding: { left: '10px', bottom: '25px'},
      breakpoints: {
        1300: {
          type   : 'slide',
          clones : 0,
          rewind : true,
          perPage: 4,
          perMove: 1,
          pagination: false,
          gap    : '18px',
          drag: true,
          width : '95vw',
          fixedWidth: '240px',
          padding: { left: '5px', bottom: '25px'},
        },
        1200: {
          type   : 'loop',
          fixedWidth: '243px',
          clones : 1,
          width : '100vw',
          drag: true,
          arrows: false,
          perPage: 1,
          pagination: true,
          gap : '1rem',
          padding: { left: '0px', right: '0px' },
        }
      }
    }).mount();
  }
  );


  this.splideitemblog.changes.subscribe(() => {

    let splideblogslider = new Splide(this.splideblogslider.nativeElement, {
      type   : 'slide',
      clones : 0,
      perPage: 3,
      perMove: 1,
      gap    : '24px',
      padding: { left: '5px'},
      pagination: false,
      breakpoints: {
        768: {
          arrows: false,
          perPage: 1,
          pagination: true,
        }
      }
    }).mount();




  })

  }



if(typeof window !== 'undefined'){

  this.calendar = new easepick.create({
    element: this.filterExperiencesCalendar.nativeElement,
    css: [
      `${this.BaseURl}/assets/css/calendar_final.css`,
      `${this.BaseURl}/assets/css/calendar_lock.css`,
    ],
    zIndex: 9999,
    format: 'DD MMM YYYY',
  });

  this.CalendarMobile  = new easepick.create({
    element: this.filterExperiencesCalendarMobile.nativeElement,
    css: [
      `${this.BaseURl}/assets/css/calendar_final.css`,
      `${this.BaseURl}/assets/css/calendar_lock.css`,
    ],
    zIndex: 9999,
    format: 'DD MMM YYYY',
  });


  if(this.calendar){
    this.calendar.on('select', (evt: any) => {
      this.experiences = [];
      let date = moment(evt.detail.date).format('YYYY-MM-DD');
     this.expQueryParams['date_start'] = date;
      this.expQueryParams['date'] = 3;

      this._experiences.ListPublicExperience(this.expQueryParams).subscribe((response:any) => {
        this.experiences = response.data.data;

      });

    });
    }

    if(this.CalendarMobile){
    this.CalendarMobile.on('select', (evt: any) => {
      this.experiences = [];
      let date = moment(evt.detail.date).format('YYYY-MM-DD');
     this.expQueryParams['date_start'] = date;
      this.expQueryParams['date'] = 3;

      this._experiences.ListPublicExperience(this.expQueryParams).subscribe((response:any) => {
        this.experiences = response.data.data;

      });

    });

    }
}




};


}

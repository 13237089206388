import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'gridtime'
})
export class GridtimePipe implements PipeTransform {

  transform(hour: string, typeHour : boolean = false): string {
    if(!hour && !moment(hour, 'HH:mm:ss Z').isValid()) {
      return '';
    }

    let GridHour = moment(hour, 'HH:mm:ss A');

    if(GridHour.format('mm') == '15' && typeHour == true || GridHour.format('mm') == '45' && typeHour == true) {
    return 'quarter';
    }

    if(GridHour.format('mm') == '30' && typeHour == true) {
      return 'half';
    }

  return GridHour.format('hh:mm A');
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { PublicationsService } from 'src/app/core/services/publications.service';
import { UserService } from 'src/app/core/services/user.service';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-geolocation',
  templateUrl: './geolocation.component.html',
  styleUrls: ['./geolocation.component.scss']
})
export class GeolocationComponent implements OnInit {
  private states : any[] = [];
  private citys: any[] = [];
  private districts : any[] = [];
  private isBrowser : boolean;
  private IPGeolocation : any;
  selectedState : any;
  selectedDistrict : any;
  selectedMunicipio : any;
  private districtList : any[] = [];
  private citiesList : any[] = [];
  private UserGeolocation : any;
  private userLocality : any = {};
  private userState : any = {};
  private userMunicipality : any = {};
  private Production  = environment.production;

  constructor(private _user : UserService , private _publications : PublicationsService, @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }


  get States() {
    return this.states;
  }

  get Citys() {
    return this.citys;
  }

  get Districts() {
    return this.districts;
  }

  public changeState(state_id : number) {
    this.states.find((item : any) => {
      if(item.id === state_id) {
        this.selectedState = item;
        this._publications.getAllCities(state_id).subscribe((resp : any) => {
          this.citys = resp.data;
        });

      }
    });
  }

  public changeCity(city_id : number) {
    this.citys.find((item : any) => {
      if(item.id === city_id) {
        this.selectedMunicipio = item;
        this._publications.getDistricts(city_id).subscribe((resp : any) => {
          this.districts = resp.data;
        });
      }
    });
  }

  public changeDistrict(district_id : number) {
    this.districts.find((item : any) => {
      if(item.id === district_id) {
        this.selectedDistrict = item;
        this._user.SetLocation(this.selectedDistrict, this.selectedMunicipio, this.selectedState);
      }
    });
  }

public GetIpLocation() {

  return;
   /*
  if(!this.Production) {
    return;
  }
 
  this._user.GeoLocationbyIP().subscribe((geolocation : any) => {
    this.IPGeolocation = geolocation;

   if(geolocation.country == 'Mexico') {
    this._user.ReverseGeoCode(geolocation.latitude +','+geolocation.longitude).subscribe((resp : any) => {
      if(resp.success) {
        this.UserGeolocation = resp.data.results;

       let Geo = new Promise((resolve) => {
         this.UserGeolocation.forEach((components : any, index : number) => {
        components.address_components.find((item : any) => {
          if(item.types[0] === 'administrative_area_level_1') {
            this.userState = item;
          }
          if(item.types[0] === 'administrative_area_level_2') {
            this.userMunicipality = item;

          }
          if(item.types[0] === 'locality') {
            this.userLocality = item;
           }

            if(index === this.UserGeolocation.length - 1) {
              resolve(true);
            }

          });
       });
      });


      Geo.then( ()=> {
       this.selectedState = this.states.find((item : any) => {
        if(item.name === this.userState.long_name || item.name === this.userState.short_name) {
          return item;
        }
      });

      this._publications.getAllCities(this.selectedState.id).subscribe((resp : any) => {
        this.citys = resp.data;
      });


        this.selectedMunicipio = this.citiesList.find((item : any) => {
          if(item.name === this.userMunicipality.long_name && item.mas_state_id === this.selectedState.id || item.name === this.userMunicipality.short_name && item.mas_state_id === this.selectedState.id) {
            return item;
          }
      });

      this._publications.getDistricts(this.selectedMunicipio.id).subscribe((resp : any) => {
        this.districts = resp.data;
      });


        this.selectedDistrict = this.districtList.find((item : any) => {
          if(item.nombre === this.userLocality.long_name && item.mas_city_id  === this.selectedMunicipio.id || item.nombre === this.userLocality.short_name  && item.mas_city_id === this.selectedMunicipio.id) {
           item['name'] = item['nombre'];
            return item;
          }
        });

        this._user.SetLocation(this.selectedDistrict, this.selectedMunicipio, this.selectedState);

      });

      }

    });
    this.GetGeoLocation();
  }

  });
*/

}
public GetGeoLocation() {
  return;
  /*
  if (this.isBrowser) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this._user.ReverseGeoCode(position.coords.latitude +','+position.coords.longitude).subscribe((resp : any) => {
          if(resp.success) {
            this.UserGeolocation = resp.data.results;

           let Geo = new Promise((resolve) => {
             this.UserGeolocation.forEach((components : any, index : number) => {
            components.address_components.find((item : any) => {
              if(item.types[0] === 'administrative_area_level_1') {

                this.userState = item;
              }
              if(item.types[0] === 'administrative_area_level_2') {
                this.userMunicipality = item;

              }
              if(item.types[0] === 'locality') {
                this.userLocality = item;
               }

                if(index === this.UserGeolocation.length - 1) {
                  resolve(true);
                }

              });
           });
          });


          Geo.then( ()=> {
           this.selectedState = this.states.find((item : any) => {
            if(item.name === this.userState.long_name || item.name === this.userState.short_name) {
              return item;
            }
          });

          this._publications.getAllCities(this.selectedState.id).subscribe((resp : any) => {
            this.citys = resp.data;
          });


            this.selectedMunicipio = this.citiesList.find((item : any) => {
              if(item.name === this.userMunicipality.long_name && item.mas_state_id === this.selectedState.id || item.name === this.userMunicipality.short_name && item.mas_state_id === this.selectedState.id) {
                return item;
              }
          });

          this._publications.getDistricts(this.selectedMunicipio.id).subscribe((resp : any) => {
            this.districts = resp.data;
          });


            this.selectedDistrict = this.districtList.find((item : any) => {
              if(item.nombre === this.userLocality.long_name && item.mas_city_id  === this.selectedMunicipio.id || item.nombre === this.userLocality.short_name  && item.mas_city_id === this.selectedMunicipio.id) {
               item['name'] = item['nombre'];
                return item;
              }
            });


        this._user.SetLocation(this.selectedDistrict, this.selectedMunicipio, this.selectedState);


          });

          }

        });


      });
    }
  }
*/
}


 /**
  * Demos Onclick
  */
 demosDroupDownClick() {
  document.querySelector('.demos')?.classList.toggle('show');
}

  ngOnInit(): void {
    this._user.GetDistrictList().subscribe((resp : any) => {
      this.districtList = resp;
    });

    this._user.GetCitiesList().subscribe((resp : any) => {
      this.citiesList = resp;
    });

    this.GetIpLocation();

    this._publications.getAllStates(1).subscribe((resp : any) => {
       this.states = resp.data;
         this.states.find((item : any) => {
           if(item.id === 23) {
             this.selectedState = item;
           }
         });
     });

     this._publications.getAllCities(23).subscribe((resp : any) => {
       this.citys = resp.data;
       this.citys.find((item : any) => {
         if(item.id === 1825) {
           this.selectedMunicipio = item;
         }
       });

     });

     this._publications.getDistricts(1825).subscribe((resp : any) => {
       this.districts = resp.data;
       this.districts.find((item : any) => {
         if(item.id === 3712) {
           this.selectedDistrict = item;
         }
       });

     });




  }

}

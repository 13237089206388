import { Injectable } from '@angular/core';




@Injectable({
  providedIn: 'root'
})

export class MessagesService {
  constructor() { }
  private _messages : any[] = [];

  public get messages() {
    return this._messages;
  }

  show(header: string, body: string, type = 'success', delay = 5000) {
    this._messages.push({header, body, type, delay});
  }

  remove(message: any) {
    this._messages = this._messages.filter(t => t != message);
  }


}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyconvert'
})
export class CurrencyPipe implements PipeTransform {

  transform(value: string | number): string {
    if(typeof value === 'number'){
      value = value.toString();
    }
    const transformValue = value.replace(/[^\d.-]+/g, '');
    const currency = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,



    });


    return currency.format(Number(transformValue))+' MXN';
  }

}

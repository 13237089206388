import { Component, OnInit, EventEmitter, Output, ViewChild, ElementRef, TemplateRef, Renderer2, HostListener, OnDestroy, Input, ViewContainerRef } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';


import { EventService } from '../../core/services/event.service';

import { Router, Event, NavigationEnd, ActivatedRoute } from '@angular/router';

import { NgbModal, NgbModalRef, NgbOffcanvas} from '@ng-bootstrap/ng-bootstrap';

import { MENU } from './menu';
import { MenuItem } from './menu.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { MessagesService } from 'src/app/core/services/messages.service';
import { TranslocoService } from '@ngneat/transloco';
import { SearchCountryField, CountryISO, PhoneNumberFormat} from 'ngx-intl-tel-input';
import { PublicationsService } from 'src/app/core/services/publications.service';
import {environment} from 'src/environments/environment';
import { ThemeService } from 'src/app/core/services/theme.service';
import { Subject, catchError, map, of, take, takeUntil } from 'rxjs';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ReservationsService } from 'src/app/core/services/reservations.service';
import { UserService } from 'src/app/core/services/user.service';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

/**
 * Header Component
 */
export class HeaderComponent implements OnInit {
  @Input() DisableSticky : boolean = false;
  dark: boolean | undefined;
  loginPassfield!: boolean;
  signupPassfield!: boolean;
  signupCPassfield!: boolean;
  menuItems: MenuItem[] = [];
  active = 1;
  is_mobile : boolean = false;
  //Phone Input
  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  //  Validation form
  loginForm!: UntypedFormGroup;
  signUpform!: UntypedFormGroup;
  forgotForm!: UntypedFormGroup;
  submit!: boolean;
  formsubmit!: boolean;
  forgotsubmit: boolean = false;
  issocialloggedin!: boolean;
  ActiveLang! : string;
  loginspinner : boolean = false;
  signupspinner : boolean = false;
  currentroute!: string;
  isChildMemberRoute = false;
  publicationID : number = 0;
  private states : any[] = [];
  private city: any[] = [];
  private localidades: any[] = [];
  selectedState: number = 0;
  selectedCity: number = 0;
  selectedLocalidad: number = 0;
  public isCollapsed = true;
  upcoming_reservations: Subject<any> = new Subject<any>();
  upcoming_reservations$ = this.upcoming_reservations.asObservable();
  userService  = this.UserService;
  notifications : any[] = [];
  storage  = environment.Storage;
  private ActiveModal : NgbModalRef | undefined;
  visibleSticky = false;
  @ViewChild('sideMenu') sideMenu!: ElementRef;
  @ViewChild('toggleFirstModal', { read: TemplateRef }) public loginmodal!:NgbModalRef;

  @ViewChild('signupsocialModal', { read: TemplateRef }) public socialmodal!:TemplateRef<any>;
  @ViewChild('header', {read: TemplateRef, static: true}) header!: TemplateRef<any>;
  @ViewChild('stickyContainer', { read: ViewContainerRef, static: true }) public stickyContainer!: ViewContainerRef;
  @HostListener('window:scroll', ['$event']) onScrollEvent($event: any){

    if(this.DisableSticky){
      return;
    }
    if(window.scrollY > 125){
     this.visibleSticky = true;
    }else{
      this.visibleSticky = false;
    }
  }
  constructor(private router : Router, private route : ActivatedRoute, private modalService: NgbModal,
    private eventService: EventService, private formBuilder: UntypedFormBuilder,
    private auth: AuthService, private messageService: MessagesService, private offcanvas: NgbOffcanvas,
    private transloco: TranslocoService, private publications : PublicationsService,
     private _theme : ThemeService, private authService: SocialAuthService,
      private breakpointObserver: BreakpointObserver, private _reservations : ReservationsService,
      private _activatedRoute : ActivatedRoute, private UserService : UserService) {

    this.ActiveLang = transloco.getActiveLang();


  }

 CheckUpcomingRservations() {
  this._reservations.getUpcomingReservations().subscribe({
    next: (response: any) => {
      if(response.status = 200){
      this.upcoming_reservations.next(response.body.data);

      }
    },
    error: (error: any) => {
      this.upcoming_reservations.next(null);
      return;
    }
  }
  )

}

  get usuario() {
    return this.auth.usuario;
  }

  get isloggedin() {
    return this.auth.isloggedin;
  }

  get States() {
    return this.states;
  }

  get Citys() {
    return this.city;
  }



/*
* Login Form Submit
*/
login(){
  this.submit = true;
if(this.loginForm.valid){
  this.loginspinner = true;
    this.auth.login(this.loginForm.value).subscribe( (response : any) => {
        if(response){
          this.modalService.dismissAll();
        }

        this.loginspinner = false;
    })
  }
}


signInWithFB(): void {
  this.auth.siginWithFacebook();
}

getLatestItems(itemsArray: any[]): any[] {
  return itemsArray.slice(itemsArray.length - 3).reverse();
}


/*
* signup Form Submit
*/

signup(){
  this.formsubmit = true;
  if(this.signUpform.valid){
    let user_data = this.signUpform.value;
  let user : any[any]=
    {
      "fullname": user_data.fullname,
      "email": user_data.email,
      "password":   user_data.password,
      "password_confirmation":  user_data.password_confirmation,
      "phoneobject": user_data.phone,
      "phone": user_data.phone.number.replaceAll(/\s/g,''),
      "phone_extension": user_data.phone.dialCode,
      "code_country": user_data.phone.countryCode,
      "terms_conditions": user_data.terms_conditions
  }

    this.signupspinner = true;
  this.auth.signup(user).subscribe({
    next : (response : any) => {
      if(response.success){
        this.modalService.dismissAll();
        this.messageService.show('Se ha registrado correctamente', "Se ha enviado un email con instrucciones para activar su cuenta", 'success');
      }
    },
    error : (error : any) => {
      if(error.error.errors.email != undefined){
        this.messageService.show('Error', "El email ya se encuentra registrado", 'danger');
    }
    this.signupspinner = false;
  }
  });







  }


}

/*
* Logout
*/
logout(){
  this.auth.logout();
  this.router.navigate(['/']);
}

forgotPasswordGetToken() {
  this.forgotsubmit = true;
  if(this.forgotForm.valid){
    this.auth.ForgotPasswordGetToken(this.forgotForm.value).subscribe( (response : any) => {
      if(response){
        this.modalService.dismissAll();
        this.messageService.show('Correo enviado', "Se ha enviado un correo para restablecer la contraseña", 'success');
      } else {
        this.messageService.show('Error', "Hubo un error al enviar el correo", 'danger');
      }
    });
  }

}



ngOnInit(): void {

 this._activatedRoute
  .data
  .subscribe((data : any) => {
    this.currentroute = data.routing;

    if(data.routing == 'members'){

    this.route.snapshot.params['id'] ? this.isChildMemberRoute = true : this.isChildMemberRoute = false;

    }


  });

  this.auth.isLoggedin$.subscribe((is_logged_in) => {
    if(is_logged_in && this.currentroute !== 'member') {
      this.CheckUpcomingRservations();
    }
  });

this.authService.authState.subscribe((user) => {
  if(this.ActiveModal != undefined){
    this.ActiveModal.close();
  }
});
  this.dark = this._theme.mode;
  this._theme.mode$.subscribe((mode) => {
    this.dark = mode;
  });


  /**
   * Load municipios and localidades
   */
this.selectedState = 23;
this.publications.getAllCities(this.selectedState).subscribe( (response : any) => {
  this.city = response.data;
});
  this.publications.getAllStates(1).subscribe( (response : any) => {
    this.states = response.data;
  });

    /**
     *Login Form
     */
     this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });

    /**
     * User data Validation
     */
     this.signUpform = this.formBuilder.group({
      fullname: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: new FormControl(),
      password: ['', [Validators.required, Validators.minLength(8)]],
      password_confirmation: ['', [Validators.required, Validators.minLength(8)]],
      terms_conditions: ['', [Validators.required]],
    });


    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });




    // Menu Items
    this.menuItems = MENU;
  }

  /**
   * Open scroll modal
   * @param toggleDataModal scroll modal data
   */
   toggleModal(staticDataModal: any)  {
 let modalsize = 'lg';
    if(this.is_mobile) {
      modalsize = 'fullscreen';
    }
  this.ActiveModal =  this.modalService.open(staticDataModal, { size: modalsize, centered: true });


  }

  secondModal(toggleSecondModal: any) {

    let modalsize = 'lg';
    if(this.is_mobile) {
      modalsize = 'fullscreen';
    }
    this.ActiveModal =  this.modalService.open(toggleSecondModal, { size: modalsize, centered: true });

  }

  toggleforgotModal(forgotModal: any) {
    let modalsize = 'md';

    if(this.is_mobile) {
      modalsize = 'fullscreen';
    }

    this.modalService.open(forgotModal, { centered: true, size: modalsize });
  }


  open(content : any) {
		this.offcanvas.open(content, { ariaLabelledBy: 'offcanvas-basic-title' })
	}

  ChangeLanguage(language : string) {
    this.transloco.setActiveLang(language);
    this.ActiveLang = this.transloco.getActiveLang();
  }

  /**
   * Password Hide/Show
   */
   toggleLoginPassField() {
    this.loginPassfield = !this.loginPassfield;
  }

  /**
   * Password Hide/Show
   */
   toggleSignUpPassField() {
    this.signupPassfield = !this.signupPassfield;
  }

  /**
   * Password Hide/Show
   */
   toggleSignUpCPassField() {
    this.signupCPassfield = !this.signupCPassfield;
  }


   /**
  * On menu click
  */
    onMenuClick(event: any) {
      const nextEl = event.target.nextElementSibling;
      if (nextEl) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove('show');
        }
        nextEl.classList.toggle('show');
      }
      return false;
    }


    ngAfterViewInit() {



      this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .subscribe(result => {
        if (result.breakpoints[Breakpoints.XSmall]) {
          this.is_mobile = true;
        } else if (result.breakpoints[Breakpoints.Small]) {
          this.is_mobile = true;
        }
        else {
          this.is_mobile = false;
        }

      });


      if (typeof window !== 'undefined'){


      if (window.screen.width <= 678) {
        this.is_mobile = true;
      }
      }
    }

    /**
    * Activates the menu
    */
    private activateMenu() {
      const resetParent = (el: any) => {
        const parent = el.parentElement;
        if (parent) {
          parent.classList.remove('active');
          const parent2 = parent.parentElement;
          this._removeAllClass('mm-active');
          this._removeAllClass('mm-show');
          if (parent2) {
            parent2.classList.remove('active');
            const parent3 = parent2.parentElement;
            if (parent3) {
              parent3.classList.remove('active');
              const parent4 = parent3.parentElement;
              if (parent4) {
                parent4.classList.remove('active');
                const parent5 = parent4.parentElement;
                if (parent5) {
                  parent5.classList.remove('active');
                  const menuelement = document.getElementById(
                    'topnav-menu-content'
                  );
                  if (menuelement !== null)
                    if (menuelement.classList.contains('show'))
                      document
                        .getElementById('topnav-menu-content')!
                        .classList.remove('show');
                }
              }
            }
          }
        }
      };

      // activate menu item based on location
      const links: any = document.getElementsByClassName('side-nav-link-ref');
      let matchingMenuItem = null;
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < links.length; i++) {
        // reset menu
        resetParent(links[i]);
      }
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < links.length; i++) {
        // tslint:disable-next-line: no-string-literal
        if (location.pathname === links[i]['pathname']) {
          matchingMenuItem = links[i];
          break;
        }
      }

      if (matchingMenuItem) {
        const parent = matchingMenuItem.parentElement;
        if (parent) {
          parent.classList.add('active');
          const parent2 = parent.parentElement;
          if (parent2) {
            parent2.classList.add('active');
            const parent3 = parent2.parentElement;
            if (parent3) {
              parent3.classList.add('active');
              const parent4 = parent3.parentElement;
              if (parent4) {
                parent4.classList.add('active');
                const parent5 = parent4.parentElement;
                if (parent5) {
                  parent5.classList.add('active');
                }
              }
            }
          }
        }
      }
    }

    /**
    * remove active and mm-active class
    */
    _removeAllClass(className: any) {
      const els = document.getElementsByClassName(className);
      while (els[0]) {
        els[0].classList.remove(className);
      }
    }

     /**
  * Returns true or false if given menu item has child or not
  * @param item menuItem
  */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }

  /**
   * On mobile toggle button clicked
   */
   toggleMobileMenu() {
    if (window.screen.width <= 1024) {
      document.getElementById('navbarNav')?.classList.toggle('show');
    }
  }

  /**
  * Bootsrap validation form submit method
  */
   validSubmit() {
    this.submit = true;
  }

  /**
 * Returns form
 */
  get form() {
    return this.loginForm.controls;
  }

  /**
   * Bootstrap tooltip form validation submit method
   */
   formSubmit() {
    this.formsubmit = true;
  }

  /**
   * returns tooltip validation form
   */
   get formData() {
    return this.signUpform.controls;
  }




  get forgotData() {
    return this.forgotForm.controls;
  }




}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private ApiURL = environment.Node_masmesa;

  constructor(private _httpClient : HttpClient) { }

/***
 * Get Publications Notifications
 * @param publication_id
 */

  GetPublicationsNotifications(publication_id : number){
    return this._httpClient.get(`${this.ApiURL}/admin/publications/${publication_id}/notifications/all`);
  }

  /**
   * Get Notifications for Header Members
   * @param PublicationID
   * @param queryParams

  */
  GetNotificationsForHeaderMembers(PublicationID : number, queryParams : any){
    return this._httpClient.get(`${this.ApiURL}/admin/publications/${PublicationID}/notifications?${queryParams}`);
  }






}

<div class="container">
<div class="border-bottom" id="dashboard-nav" style="padding-bottom: 29px;">
  <ul class="nav nav-pills flex-row justify-content-center">
    <li class="nav-item mb-md-0 me-md-2 pe-md-1" *transloco="let t; scope: 'member'"><a class="nav-link" [routerLink]="['/members/dashboard/', publication_id, 'reservations']" [routerLinkActiveOptions]="{exact: false}" routerLinkActive="active" aria-current="page"><i class="fi-calendar mt-n1 me-2 fs-base"></i>{{t('member.reservaciones')}}</a></li>
    <li class="nav-item mb-md-0 me-md-2 pe-md-1" *transloco="let t; scope: 'member'"><a class="nav-link" [routerLink]="['/members/dashboard/', publication_id, 'messages']" [routerLinkActiveOptions]="{exact: false}" routerLinkActive="active"><i class="fi-mail mt-n1 me-2 fs-base"></i>{{t('member.mensajes')}}</a></li>
    <li class="nav-item mb-md-0 me-md-2 pe-md-1" *transloco="let t; scope: 'member'"><a class="nav-link" [routerLink]="['/members/dashboard/', publication_id, 'experiences']" [routerLinkActiveOptions]="{exact: false}" routerLinkActive="active"><i class="fi-ticket mt-n1 me-2 fs-base"></i>{{t('member.experiencias')}}</a></li>
    <li class="nav-item mb-md-0 me-md-2 pe-md-1" *transloco="let t; scope: 'member'"><a class="nav-link" [routerLink]="['/members/dashboard/', publication_id, 'reviews']"[routerLinkActiveOptions]="{exact: false}"  routerLinkActive="active"><i class="fi-star mt-n1 me-2 fs-base"></i>{{t('member.reseñas')}}</a></li>
    <li class="nav-item mb-md-0 me-md-2 pe-md-1" *transloco="let t; scope: 'member'"><a class="nav-link" [routerLink]="['/members/dashboard/', publication_id, 'gallery']" [routerLinkActiveOptions]="{exact: false}" routerLinkActive="active"><i class="fi-image mt-n1 me-2 fs-base"></i>{{t('member.galeria')}}</a></li>
    <li class="nav-item mb-md-0 me-md-2 pe-md-1" *transloco="let t; scope: 'member'"><a class="nav-link" [routerLink]="['/members/dashboard/', publication_id, 'notifications']" [routerLinkActiveOptions]="{exact: false}" routerLinkActive="active"><i class="fi-bell mt-n1 me-2 fs-base"></i>{{t('member.notificaciones')}}</a></li>
    <li class="nav-item mb-md-0" *transloco="let t; scope: 'member'"><a class="nav-link" [routerLink]="['/members/dashboard/', publication_id, 'settings']" [routerLinkActiveOptions]="{exact: false}" routerLinkActive="active"><i class="fi-settings mt-n1 me-2 fs-base"></i>{{t('member.configuracion')}}</a></li>
    <li class="nav-item d-md-none">
  </ul>
</div>
</div>

import { Injectable, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgbDropdownModule, NgbAlertModule, NgbNavModule, NgbCollapseModule, NgbProgressbarModule, NgbRatingModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import { ImageCropperModule } from 'ngx-image-cropper';


// Component
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { TranslocoModule } from '@ngneat/transloco';
import { AccountHeaderComponent } from './account-header/account-header.component';
import { MessagesComponent } from './messages/messages.component';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { GooglesignupbuttonComponent } from './googlesignupbutton/googlesignupbutton.component';
import { LoaderComponent } from './loader/loader.component';
import { NgProgressModule } from 'ngx-progressbar';
import { MobilebottombarComponent } from './mobilebottombar/mobilebottombar.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { MenuMembersComponent } from './menu-members/menu.component';

// Drop Zone
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';


import { MembersHeaderComponent } from './members-header/members-header.component';
import { SimplebarAngularModule } from 'simplebar-angular';
import { TopbarComponent } from './topbar/topbar.component';
import { MenuAccountComponent } from './menu-account/menu-account.component';
import {MatIconModule} from '@angular/material/icon';
import { AddReviewComponent } from './reviews/add-review/add-review.component'
import { MatStepperModule } from '@angular/material/stepper';
import { environment } from 'src/environments/environment';
import { CheckAvailabilityVerticalComponent } from './reservations/check-availability-vertical/check-availability-vertical.component';
import { ReservationformComponent } from './reservations/reservationform/reservationform.component';
import { CountdownModule } from 'ngx-countdown';
import { ModifyReservationComponent } from './reservations/modify-reservation/modify-reservation.component';
import { CheckAvailabilityHorizontalComponent } from './reservations/check-availability-horizontal/check-availability-horizontal.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FavoritesComponent } from './favorites/favorites.component';
import { TimepickerComponent } from './timepicker/timepicker.component';
import { CoreModule } from '../core/core.module';
import { GeolocationComponent } from './geolocation/geolocation.component';
import { EditReviewComponent } from './reviews/edit-review/edit-review.component';
import { FilePondModule, registerPlugin } from "ngx-filepond";
import * as FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import * as FilePondPluginMediaPreview from 'filepond-plugin-media-preview';
import { BasicChatComponent } from './chat/basic/basic.component';
import { ReservationformSimpleComponent } from './reservations/reservationform-simple/reservationform-simple.component';
import { CheckAvailabilityExperiencesComponent } from './reservations/check-availability-experiences/check-availability-experiences.component';
import { SelectReservationTypeComponent } from './reservations/select-reservation-type/select-reservation-type.component';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { SingleReservationComponent } from './reservations/single-reservation/single-reservation.component';
import { HAMMER_GESTURE_CONFIG, HammerModule, HammerGestureConfig  } from '@angular/platform-browser';
import { CheckAvailabilityMapMobileComponent } from './reservations/check-availability-map-mobile/check-availability-horizontal.component';
import { AlertComponent } from './alert/alert.component';
declare let Hammer: any;

registerPlugin(FilePondPluginImagePreview, FilePondPluginMediaPreview);
const apiurl = environment.ApiUpload;
const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  url: `${apiurl}/publications/temp/publicationimgcontent`,
  maxFilesize: 25,
  acceptedFiles: 'image/*'
};

@Injectable() export class HammerCustomConfig extends HammerGestureConfig {
  //add new event names to support angular gestures.


override buildHammer(element: HTMLElement): any {
  const mc = new Hammer!(element, this.options);
//add custom event hammerjs


let panright2f = new Hammer.Pan({
  event: 'panright2f',
  direction: Hammer.DIRECTION_RIGHT,
  threshold: 0,
  pointers: 2,
  tap: 2,


});

let panleft2f = new Hammer.Pan({
  event: 'panleft2f',
  direction: Hammer.DIRECTION_LEFT,
  threshold: 0,
  pointers: 2,
  tap: 2,
});



mc.add(panright2f);
mc.add(panleft2f);

this.events.push('panright2f');
this.events.push('panleft2f');



  for (const eventName of Object.keys(this.overrides)) {
    mc.get(eventName).set(this.overrides[eventName]);
  }

  return mc;
}

}

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    BreadcrumbsComponent,
    AccountHeaderComponent,
    MessagesComponent,
    MenuMembersComponent,
    GooglesignupbuttonComponent,
    LoaderComponent,
    MobilebottombarComponent,
    MembersHeaderComponent,
    TopbarComponent,
    MenuAccountComponent,
    AddReviewComponent,
    CheckAvailabilityVerticalComponent,
    ReservationformComponent,
    ModifyReservationComponent,
    CheckAvailabilityHorizontalComponent,
    FavoritesComponent,
    TimepickerComponent,
    GeolocationComponent,
    EditReviewComponent,
    BasicChatComponent,
    ReservationformSimpleComponent,
    CheckAvailabilityExperiencesComponent,
    SelectReservationTypeComponent,
    SingleReservationComponent,
    CheckAvailabilityMapMobileComponent,
    AlertComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    HammerModule,
    TranslocoModule,
    CoreModule,
    NgxIntlTelInputModule,
    MatAutocompleteModule,
    ImageCropperModule,
    SimplebarAngularModule,
    MatStepperModule,
    FilePondModule,
    NgbRatingModule,
    NgProgressModule,
    NgbToastModule,
    NgbCollapseModule,
    NgbTooltipModule,
    NgbProgressbarModule,
    MatIconModule,
    CountdownModule,
    NgbAlertModule,
    NgbNavModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    ScrollToModule.forRoot(),
    DropzoneModule,
    ContentLoaderModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    BreadcrumbsComponent,
    AccountHeaderComponent,
    MessagesComponent,
    LoaderComponent,
    MobilebottombarComponent,
    MembersHeaderComponent,
    CheckAvailabilityVerticalComponent,
     MenuMembersComponent,
     TopbarComponent,
     MenuAccountComponent,
     AddReviewComponent,
     DropzoneModule,
     MatIconModule,
     ModifyReservationComponent,
     CheckAvailabilityHorizontalComponent,
     FavoritesComponent,
    TimepickerComponent,
    GeolocationComponent,
    ReservationformComponent,
    FilePondModule,
    BasicChatComponent,
    CheckAvailabilityExperiencesComponent,
    SelectReservationTypeComponent,
    SingleReservationComponent,
    GooglesignupbuttonComponent,
    CheckAvailabilityMapMobileComponent,
    AlertComponent
    ],
  providers: [
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerCustomConfig,
    }

  ]
})
export class SharedModule { }

<div id="Mb-navbar" class="fixed-bottom d-md-none">
  <div class="container-fluid">
      <ul class="nav d-flex justify-content-center">
        <li class="nav-item">
          <a class="nav-link" routerLink="/home" routerLinkActive="active">
            <i class="fi-home"></i>
            <span>Home</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/search">
            <i class="fi-search"></i>
            <span>Buscar</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/profile">
            <i class="fi-user"></i>
            <span>Mi Cuenta</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/cart">
            <i class=""></i>
            <span>Español</span>
          </a>
      </ul>
    </div>
</div>

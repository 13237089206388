<ng-container *ngIf="Experiences !== null">
<h2 class="h5 text-primary" *transloco="let t; scope:'reservations'">{{t('reservations.selecciona_tu_tipo_de_reserva')}}</h2>

<div class="experiences-options my-3">
  <div class="mb-2" *ngFor="let experience of Experiences">
    <input type="radio" class="btn-check" id="promo-{{experience.experienceId}}" name="promo" [(ngModel)]="Reservation['experience']" [value]="experience.experienceId">
    <label class="position-relative btn btn-sm btn-outline-secondary fw-normal text-start w-100" for="promo-{{experience.experienceId}}">
      <div class="position-absolute top-0 end-0 pt-1 pe-1">
        <mat-icon fontIcon="radio_button_checked" *ngIf="Reservation['experience'] == experience.experienceId"></mat-icon>
        <mat-icon fontIcon="radio_button_unchecked" *ngIf="Reservation['experience'] !== experience.experienceId"></mat-icon>
      </div>
      <strong>{{experience.title}}</strong><br/>
      <span>Obtendras un {{experience.discountrate}}% de Descuento</span><br/>
      <span>Disponible en el Horario Reservado</span>
    </label>
    </div>
    <div class="mb-2">
      <input type="radio" class="btn-check" id="sin_promo" name="promo" [(ngModel)]="Reservation['experience']" [value]="false">
      <label class="btn btn-sm btn-outline-secondary fw-normal text-start w-100" for="sin_promo">
        <div class="position-absolute top-0 end-0 pt-1 pe-1">
          <mat-icon fontIcon="radio_button_checked" *ngIf="Reservation['experience'] === false"></mat-icon>
          <mat-icon fontIcon="radio_button_unchecked" *ngIf="Reservation['experience'] !== false"></mat-icon>
        </div>
        <strong>Reservar en {{Publication.name}}</strong><br/>
        <span>Reservacion con Menú a la carta sin oferta</span><br/>
      </label>
    </div>

</div>

</ng-container>
